<div >


  <i *ngIf="status=='error'" class="error fa fa-exclamation-circle"></i>
  <i *ngIf="status=='saving'" class="saving fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
  <i *ngIf="status=='success'" class="success fa fa-check-circle-o"></i>
  <i *ngIf="status=='confirm'" class="warning fa fa-exclamation-triangle"></i>


  <div class="header">{{title}}</div>
  <div class="text">{{text}}</div>

  <div class="confirm-buttons" *ngIf="status=='confirm'">

    <button class="ui button inverted yellow" (click)="proceedClicked()">{{ proceedButtonText? proceedTet: "Yes, proceed" }}</button>
    <button class="ui button inverted grey" (click)="cancelClicked()">{{ cancelButtonText ? cancelText : "No, cancel" }}</button>
  </div>

  <div class="ok-button" *ngIf="status=='error'">
    <button class="ui button inverted grey" (click)="close('error')">OK</button>
  </div>

  <div class="ok-button" *ngIf="status=='success'">
    <button class="ui button inverted grey" (click)="close('success')">Close</button>

    

  </div>


</div>
