import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./interceptors/token.interceptor";

//Services
//import all services
import { services } from "./services";


@NgModule({
  imports: [CommonModule],
  declarations: [
    // ActivateComponent,
    // ForgotPasswordComponent,
    // PasswordResetComponent
  ],

  providers: [
    // ...services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}

//https://www.technouz.com/4644/angular-5-app-structure-multiple-modules/
//https://itnext.io/choosing-a-highly-scalable-folder-structure-in-angular-d987de65ec7
