import { Component, OnInit, Input } from "@angular/core";
import { ProgramOutcomeCategory } from "app/core/models/program-outcome-category";
import { ProgramOutcome } from "app/core/models/program-outcome";
import { Loading } from "app/core/models/loading";

@Component({
  selector: "app-course-summary-plo",
  templateUrl: "./course-summary-plo.component.html",
  styleUrls: ["./course-summary-plo.component.scss"]
})
export class CourseSummaryPloComponent implements OnInit {
  
  @Input() programOutcomeCategories: Array<ProgramOutcomeCategory>;
  @Input() programOutcomes: Array<ProgramOutcome>;

  loading: Loading = new Loading(false, "Loading..");

  constructor() {}

  ngOnInit() {

  }
}
