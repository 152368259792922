import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter
} from "@angular/core";
import { DimmerService } from "app/core/services/dimmer.service";
import { Dialog } from "app/core/models/dialog";
import { CustomError } from "app/core/models/custom-error";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"]
})
export class DialogComponent implements OnInit {
  showDialog: boolean = true;

  @Input() dialog: Dialog;
  @Input() updating: boolean;
  @Input() actionError: CustomError;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() submitDialog: EventEmitter<any> = new EventEmitter();

  constructor(private dimmerService: DimmerService) {}

  ngOnInit() {}

  cancelClicked() {
    this.closeDialog.emit();
  }

  submitClicked() {

    this.submitDialog.emit();
  }
}
