import { Component, OnInit, Input } from '@angular/core';
import { CustomError } from "app/core/models/custom-error";


@Component({
  selector: 'app-load-error',
  templateUrl: './load-error.component.html',
  styleUrls: ['./load-error.component.scss']
})
export class LoadErrorComponent implements OnInit {

  @Input()
  loadError: CustomError;

  constructor() { }

  ngOnInit() {
    if (!this.loadError.title) {
      this.loadError.title =
        "Error";
    }
  }

}
