export class QuestionTextAnswer {
  private _id: number;
  private _textAnswer: string;
  private _courseId: number;
  private _outcomeId: number;
  private _questionId: number;

  constructor(
    id: number,
    textAnswer: string,
    courseId: number,
    outcomeId: number,
    questionId: number
  ) {
    this._id = id;
    this._textAnswer = textAnswer;
    this._courseId = courseId;
    this._outcomeId = outcomeId;
    this._questionId = questionId;
  }

  /**
   * Getter id
   * @return {number}
   */
  public get id(): number {
    return this._id;
  }

  /**
   * Setter id
   * @param {number} value
   */
  public set id(value: number) {
    this._id = value;
  }
  /**
   * Getter textAnswer
   * @return {string}
   */
  public get textAnswer(): string {
    return this._textAnswer;
  }

  /**
   * Setter textAnswer
   * @param {string} value
   */
  public set textAnswer(value: string) {
    this._textAnswer = value;
  }


    /**
     * Getter courseId
     * @return {number}
     */
	public get courseId(): number {
		return this._courseId;
	}

    /**
     * Setter courseId
     * @param {number} value
     */
	public set courseId(value: number) {
		this._courseId = value;
	}


    /**
     * Getter outcomeId
     * @return {number}
     */
	public get outcomeId(): number {
		return this._outcomeId;
	}

    /**
     * Setter outcomeId
     * @param {number} value
     */
	public set outcomeId(value: number) {
		this._outcomeId = value;
	}

    /**
     * Getter questionId
     * @return {number}
     */
	public get questionId(): number {
		return this._questionId;
	}

    /**
     * Setter questionId
     * @param {number} value
     */
	public set questionId(value: number) {
		this._questionId = value;
	}
  

  
}
