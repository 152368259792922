<div *ngIf="loading.isLoading;else loaded">
  <app-loading [loadingText]="loading.message"></app-loading>
</div>


<ng-template #loaded>

  <app-load-error *ngIf="loadError; else noErrors" [loadError]="loadError">
  </app-load-error>


  <ng-template #noErrors>


    <div *ngFor="let courseOutcome of courseOutcomes; let i = index;" class="course-outcome-segment ">
      <div class="ui top attached segment course-outcome-title">
        <div>{{ i+1 }}. {{courseOutcome.description}}</div>
      </div>



      <div class="outcome-questions ui segment attached answer-section" *ngIf="courseOutcome.questions.length > 0">


        <div class="instruction">Please answer the following questions about this course outcome.</div>

        <div class="ui form question-container"
          *ngFor="let question of courseOutcome.questions; let questionIndex = index;">


          <div class="question-text">{{questionIndex + 1}}. {{question.question}}</div>


          <div *ngIf="question.format == 'text'" class="answer-text">

            <div>
              {{answersArray[courseOutcome.id][question.id].textAnswer}}
            </div>
            <div *ngIf="!answersArray[courseOutcome.id][question.id].textAnswer">You did not answer this question.</div>

          </div>



          <div *ngIf="question.format == 'checkbox' || question.format == 'radio' ">

            <ul class="answer-option-list">
              <li *ngFor="let option of question.options"
                [ngClass]="{'not-selected': !answersArray[courseOutcome.id][question.id].options[option.id].isSelected, 'selected': answersArray[courseOutcome.id][question.id].options[option.id].isSelected}">
                <i class="check icon"
                  *ngIf="answersArray[courseOutcome.id][question.id].options[option.id].isSelected"></i>
                <i class="times icon"
                  *ngIf="!answersArray[courseOutcome.id][question.id].options[option.id].isSelected"></i>
                {{option.description}}

                <span *ngIf="answersArray[courseOutcome.id][question.id].options[option.id].isSelected">
                  (Selected)</span>
                <span *ngIf="!answersArray[courseOutcome.id][question.id].options[option.id].isSelected"> (Not
                  Selected)</span>
                <span
                  *ngIf="answersArray[courseOutcome.id][question.id].options[option.id].isSelected && option.otherTextRequired">:&nbsp;{{answersArray[courseOutcome.id][question.id].options[option.id].otherText}}</span>
              </li>
            </ul>

          </div>



          <!-- <div *ngIf="question.format == 'checkbox' || question.format == 'radio'" class="outcome-question">
          <div class="field">



            <div class="grouped fields" *ngIf="question.format == 'checkbox'">

              <div class="field" *ngFor="let option of question.options">
                <div class="ui checkbox">

                </div>

                <div class="ui input other-text-input" *ngIf="option.otherTextRequired && option.isSelected">

                </div>

              </div>
            </div>

            <div class="grouped fields" *ngIf="question.format == 'radio'">
              <div class="field" *ngFor="let option of question.options">
                <div class="ui radio checkbox">

                </div>

                <div class="ui input other-text-input" *ngIf="option.otherTextRequired && option.isSelected">

                </div>

              </div>
            </div>

          </div>

        </div> -->


        </div>
      </div>



      <!-- <div class="ui bottom attached segment" *ngIf="!assessmentFormat.requiredByCourseOutcome && !tlaFormat.requiredByCourseOutcome && courseOutcome.questions.length">


      <div class="ui message">
        No additional information about this course outcome is required.
      </div>

    </div> -->
    </div>

  </ng-template>

</ng-template>