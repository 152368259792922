import { Component, OnInit, Input } from "@angular/core";

import { AssessmentFormat } from "app/core/models/assessment-format";
import { TlaFormat } from "app/core/models/tla-format";
import { CourseOutcomeService } from "app/core/services/course-outcome.service";
import { CourseOutcome } from "app/core/models/course-outcome";
import { AssessmentSelectedMethod } from "app/core/models/assessment-selected-method";
import { TlaSelectedOption } from "app/core/models/tla-selected-option";
import { Question } from "app/core/models/question";
import { QuestionAnswerOption } from "app/core/models/question-answer-option";
import { QuestionSelectedOption } from "app/core/models/question-selected-option";
import { CustomError } from "app/core/models/custom-error";

import { AssessmentService } from "app/core/services/assessment.service";
import { TlaService } from "app/core/services/tla.service";
import { QuestionService } from "app/core/services/question.service";

import { Loading } from "app/core/models/loading";

@Component({
  selector: "app-course-outcome-details",
  templateUrl: "./course-outcome-details.component.html",
  styleUrls: ["./course-outcome-details.component.scss"]
})
export class CourseOutcomeDetailsComponent implements OnInit {
  courseOutcomes: Array<CourseOutcome> = [];
  loading: Loading = new Loading(false, "Loading coruse outcomes...");

  @Input() courseId: number;
  @Input() reviewId: number;
  @Input() questions: Array<Question>;

  answersArray: Array<any> = []; //format: questionsArray[outcomeId][questionId] = Question Object

  loadError: CustomError;

  constructor(
    private courseOutcomeService: CourseOutcomeService,

    private questionService: QuestionService
  ) { }

  async ngOnInit() {

    this.loading.isLoading = true;

    try {
      let courseOutcomes = await this.courseOutcomeService.getCourseOutcomes(
        this.courseId
      );

      for (let question of this.questions) {
        if (question.format == "checkbox" || question.format == "radio") {
          let options: Array<
            QuestionAnswerOption
          > = await this.questionService.getAnswerOptionsByCourseId(
            this.courseId,
            question.id
          );
          question.options = options;
        }
      }

      // if (this.questions.length > 0) {
      courseOutcomes.forEach(courseOutcome => {
        courseOutcome.questions = this.questions;
      });
      // }

      ///////////////////////////// get current answers for all questions ///////////////////////////////

      let answersArray: Array<any> = []; //format: questionsArray[outcomeId][questionId] = Question Object

      //initialize
      courseOutcomes.forEach(courseOutcome => {
        answersArray[courseOutcome.id] = [];

        this.questions.forEach(question => {
          let options = [];

          answersArray[courseOutcome.id][question.id] = {
            textAnswer: null,
            options: []
          };

          if (question.format == "checkbox" || question.format == "radio") {
            question.options.forEach(option => {
              answersArray[courseOutcome.id][question.id].options[option.id] = {
                isSelected: false,
                otherText: null
              };
            });
          }
        });
      });

      if (this.questions.length > 0) {
        let allTextAnswers = await this.questionService.getAllTextAnswersForCourse(
          this.courseId
        );

        allTextAnswers.forEach(textAnswer => {
          answersArray[textAnswer.outcomeId][textAnswer.questionId].textAnswer =
            textAnswer.textAnswer;
        });

        //get all list option questions
        // //format: allListAnswers[outcomeId][questionId] = QuestionAnswerOption
        let allListAnswers: Array<
          QuestionSelectedOption
        > = await this.questionService.getAllListAnswersForCourse(
          this.courseId
        );
        // console.log(allListAnswers);
        allListAnswers.forEach((listAnswer: QuestionSelectedOption) => {
          answersArray[listAnswer.outcomeId][listAnswer.questionId].options[
            listAnswer.selectedOptionId
          ] = { isSelected: true, otherText: listAnswer.selectedOptionText };
        });
        // console.log(answersArray);
        this.answersArray = answersArray;
      }

      this.courseOutcomes = courseOutcomes;
    } catch (error) {

      this.loadError = error;
    } finally {
      this.loading.isLoading = false;
    }
  }
}
