import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  Input
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";

import { User } from "app/core/models/user";
import { MenuItem } from "app/core/models/menu-item";

import { UserService } from "app/core/services/user.service";
import { CurrentUserService } from "app/core/services/current-user.service";
import { NavBarService } from "app/core/services/nav-bar.service";
import { AuthService } from "app/core/services/auth.service";
import { SystemService } from "app/core/services/system.service";

import { ApplicationInfo } from "app/core/models/application-info";

import { Config } from "../../../config";
import { environment } from "environments/environment";

declare const jQuery;

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"]
})
export class NavBarComponent implements OnInit, AfterViewInit {
  @Input() checkUser: boolean;

  currentUserSubscription: Subscription;

  title: string;
  token: string;
  menuItems: Array<any>;
  user: User;
  appInfo: ApplicationInfo;
  appVersion: string;

  numCourses: number;
  shouldShowMenuItems: boolean = false;
  isHomeEnabled: boolean = environment.IS_HOME_ENABLED;
  logoUrl: string = Config.LOGO_URL;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    // private authenticationService: AuthenticationService,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    private navBarService: NavBarService,
    private authService: AuthService,
    private systemService: SystemService
  ) {
    this.menuItems = [];
    this.menuItems.push(new MenuItem("My Curriculum Projects", "/admin/curriculum-reviews"));
    this.menuItems.push(new MenuItem("My Courses", "/user"));

    //initialize appInfo
    this.appInfo = new ApplicationInfo();
  }

  ngOnInit() {

    // this.title = Config.APP_NAME;

    this.shouldShowMenuItems = false;

    this.systemService.appInfo.subscribe(appInfo => {

      this.appInfo = appInfo;

    });

    this.currentUserSubscription = this.currentUserService.currentUser.subscribe(currentUser => {
      this.user = currentUser;
    });
  }

  ngAfterViewInit() {
    // jQuery(this.elementRef.nativeElement).find('.ui.dropdown').dropdown();
  }

  showMenuItems() {
    this.shouldShowMenuItems = this.shouldShowMenuItems ? false : true;
    // console.log(this.shouldShowMenuItems);
  }

  login() { }

  logout() {

    //logout user
    // this.authenticationService.logout();

    //clear numCourse
    // this.navBarService.setNumCourses(0);


    // this.authService.logout();
    this.user = null;

    this.router.navigate(["/auth/login"], { queryParams: { loggedout: true } });


  }

  isLoggedIn() {
    if (!this.user) {
      return false;
    } else if (this.user.id == 0) {
      //System admin
      return true;
    } else if (!this.user.id || this.user.id == 1) {
      return false;
    }
  }
}
