import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

import { DimmerComponent } from "./components/dimmer/dimmer.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { SavingStatusComponent } from "./components/saving-status/saving-status.component";

import { SortablejsModule } from "ngx-sortablejs";

import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { AlertComponent } from "./components/alert/alert.component";
import { GlobalErrorComponent } from "./components/global-error/global-error.component";

import { PloListComponent } from "./components/plo-list/plo-list.component";
import { MappingScaleComponent } from "./components/mapping-scale/mapping-scale.component";
import { UserStepSummaryAssessmentComponent } from "../user/components/user-step-summary-assessment/user-step-summary-assessment.component";
import { UserStepSummaryTlaComponent } from "../user/components/user-step-summary-tla/user-step-summary-tla.component";
import { UserStepSummaryOutcomeMapsComponent } from "../user/components/user-step-summary-outcome-maps/user-step-summary-outcome-maps.component";
import { CourseSummaryPloComponent } from "../user/components/course-summary-plo/course-summary-plo.component";

import { DialogComponent } from "./components/dialog/dialog.component";
import { AdditionalQuestionsComponent } from "./components/additional-questions/additional-questions.component";
import { CourseOutcomeListComponent } from "./components/course-outcome-list/course-outcome-list.component";
import { CourseOutcomeDetailsComponent } from "./components/course-outcome-details/course-outcome-details.component";
import { HighchartVerticalBarComponent } from "./components/highchart-vertical-bar/highchart-vertical-bar.component";
import { HighchartHorizontalBarComponent } from "./components/highchart-horizontal-bar/highchart-horizontal-bar.component";
import { LoadErrorComponent } from './components/load-error/load-error.component';
import { ModalActionErrorComponent } from './components/modal-action-error/modal-action-error.component';
import { ReportPloListComponent } from './components/report-plo-list/report-plo-list.component';
import { AllCourseListWithOutcomesComponent } from './components/all-course-list-with-outcomes/all-course-list-with-outcomes.component';


//Pipes
import { SafeHtmlPipe } from "../core/pipes/safe-html.pipe";
import { GroupByPipe } from "../core/pipes/group-by.pipe";
import { FilterChartsPipe } from "../core/pipes/filter-charts.pipe";
import { SafePipe } from "../core/pipes/safe.pipe";



@NgModule({
  imports: [CommonModule, RouterModule, SortablejsModule],
  declarations: [
    DimmerComponent,
    LoadingComponent,
    SavingStatusComponent,
    PloListComponent,
    MappingScaleComponent,
    DialogComponent,
    AdditionalQuestionsComponent,
    CourseOutcomeListComponent,
    CourseOutcomeDetailsComponent,
    HighchartVerticalBarComponent,
    UserStepSummaryAssessmentComponent,
    UserStepSummaryTlaComponent,
    UserStepSummaryOutcomeMapsComponent,
    CourseSummaryPloComponent,
    NavBarComponent,
    AlertComponent,
    GlobalErrorComponent,
    SafeHtmlPipe,
    GroupByPipe,
    FilterChartsPipe,
    SafePipe,
    HighchartHorizontalBarComponent,
    ModalActionErrorComponent,
    LoadErrorComponent,
    ReportPloListComponent,
    AllCourseListWithOutcomesComponent
  ],

  exports: [
    LoadingComponent,
    SavingStatusComponent,
    PloListComponent,
    MappingScaleComponent,
    AdditionalQuestionsComponent,
    DialogComponent,
    LoadErrorComponent,
    CourseOutcomeListComponent,
    CourseOutcomeDetailsComponent,
    HighchartVerticalBarComponent,
    HighchartHorizontalBarComponent,
    UserStepSummaryAssessmentComponent,
    UserStepSummaryTlaComponent,
    UserStepSummaryOutcomeMapsComponent,
    CourseSummaryPloComponent,
    ModalActionErrorComponent,
    NavBarComponent,
    AlertComponent,
    GlobalErrorComponent,
    ReportPloListComponent,
    SafeHtmlPipe,
    GroupByPipe,
    FilterChartsPipe,
    AllCourseListWithOutcomesComponent,
    SafePipe
  ],
  providers: [
    //  UtilService, AuthService
  ]
})
export class SharedModule { }
