import { Injectable } from '@angular/core';

import { User } from "app/core/models/user";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {


  //this needs to be BehaviorSubject so nav-bar can get updated user object after logged in
  private _currentUser = new BehaviorSubject(new User(null, null, null, false));
  currentUser = this._currentUser.asObservable();

  constructor() { }

  setCurrentUser(user: User) {
    // console.log("Setting current user...");
    this._currentUser.next(user);
  }

  logoutUser() {
    this._currentUser.next(new User(null, null, null, false));
  }

}
