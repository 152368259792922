import { Component, OnInit, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

declare const jQuery: any;

@Component({
  selector: 'app-dimmer',
  templateUrl: './dimmer.component.html',
  styleUrls: ['./dimmer.component.scss']
})
export class DimmerComponent implements OnInit, OnChanges {

  @Input()
  show: boolean;

  @Input()
  message: string;


  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {


    if (changes['show']) {

      if (changes['show'].currentValue) {
        jQuery(this.elementRef.nativeElement).find(".page").dimmer('show');
      }
      else {
        jQuery(this.elementRef.nativeElement).find(".page").dimmer('hide');
      }
    }


  }



}
