import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ProgramOutcomeCategory } from "app/core/models/program-outcome-category";
import { ProgramOutcome } from "app/core/models/program-outcome";
import { Level } from "app/core/models/level";
import { CustomError } from "../models/custom-error";
import { HttpErrorResponse } from "@angular/common/http";
import { Config } from "app/config";
import { map, catchError, finalize } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Course } from "app/core/models/course";

import * as html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import FileSaver from 'file-saver';


import { environment } from 'environments/environment';

//Services

@Injectable({
  providedIn: "root"
})
export class UtilService {
  constructor(private httpClient: HttpClient) { }

  getColor(colorCode: string) {
    return "rgb(" + colorCode + ")";
  }

  getHexColor(colorCode: string) {
    if (!colorCode) {
      return "#000000";
    } else {
      return "#" + colorCode;
    }
  }

  getFontColor(rgbValue: string) {
    if (rgbValue) {
      let rgbValues = rgbValue.split(",");

      if (rgbValues.length == 3) {
        let a =
          1 -
          (0.299 * +rgbValues[0] +
            0.587 * +rgbValues[1] +
            0.114 * +rgbValues[2]) /
          255;
        if (a < 0.5) {
          return "#000000";
        } else {
          return "#ffffff";
        }
      }
    }
    return "#000000";
  }

  getFontHexColor(hexValue: string) {
    if (!hexValue) {
      hexValue = "000000";
    }

    const rgbColorCode = this.hexToRgbString(hexValue);
    return this.getFontColor(rgbColorCode);
  }

  hexToRgb(hex) {
    if (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      const values = hex.split("");

      let rgbArray = [];

      if (values.length == 6) {
        rgbArray[0] = parseInt(values[0] + values[1], 16);
        rgbArray[1] = parseInt(values[2] + values[3], 16);
        rgbArray[2] = parseInt(values[4] + values[5], 16);

        return rgbArray;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  hexToRgbString(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    const values = hex.split("");

    if (values.length == 6) {
      return (
        parseInt(values[0] + values[1], 16) +
        "," +
        parseInt(values[2] + values[3], 16) +
        "," +
        parseInt(values[4] + values[5], 16)
      );
    } else {
      return null;
    }
  }

  getLevel(levels: Array<Level>, levelId: number) {
    for (let level of levels) {
      if (level.id == levelId) {
        return level;
      }
    }
    return null;
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const genreA = a.genre.toUpperCase();
    const genreB = b.genre.toUpperCase();

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  truncate(text: string, length: number) {
    let truncated: string = "";

    truncated = text.substring(0, length);

    truncated += "...";

    return truncated;
  }

  groupByCategory(programLevelOutcomes: Array<ProgramOutcome>) {
    let programLevelOutcomeCategories: Array<ProgramOutcomeCategory> = [];
    let currentCategory = null;
    let index = 1;

    programLevelOutcomes.forEach(programOutcome => {
      if (!currentCategory || programOutcome.categoryId != currentCategory.id) {
        currentCategory = new ProgramOutcomeCategory(
          programOutcome.categoryId,
          programOutcome.categoryName
        );
        programLevelOutcomeCategories.push(currentCategory); //push new category to the array
      } else {
        //currentCategory should already exist
      }

      // programOutcome.index = index++; //assign index
      currentCategory.programOutcomes.push(programOutcome);
    });

    return programLevelOutcomeCategories;
  }

  groupPlosByCategory(
    programOutcomes: Array<ProgramOutcome>,
    programOutcomeCategories: Array<ProgramOutcomeCategory>,
    callback
  ) {
    let programOutcomesByCategory: Array<any> = [];
    let programOutcomesWithoutCategory: Array<any> = [];
    let categoryIds: Array<number> = [];
    let index = 1;

    programOutcomeCategories.forEach(category => {
      //go through outcomes and select ones in this category

      categoryIds.push(category.id);

      let outcomesInThisCategory: Array<ProgramOutcome> = [];

      programOutcomes.forEach(programOutcome => {
        if (programOutcome.categoryId == category.id) {
          programOutcome.index = index++;
          outcomesInThisCategory.push(programOutcome);
        }
      });

      programOutcomesByCategory.push({
        category: category,
        outcomes: outcomesInThisCategory
      });
    });

    //select outcomes not belonging to any category
    programOutcomes.forEach(outcome => {
      //if the outcome has no category ID or the category ID does not belong to any categories
      if (
        !outcome.categoryId ||
        categoryIds.indexOf(outcome.categoryId) == -1
      ) {
        outcome.index = index++;
        programOutcomesWithoutCategory.push(outcome);
      }
    });

    callback(programOutcomesByCategory, programOutcomesWithoutCategory);
  }

  groupPlosByCategoryWithReturnValue(
    programOutcomes: Array<ProgramOutcome>,
    programOutcomeCategories: Array<ProgramOutcomeCategory>
  ) {
    let programOutcomesByCategory: Array<any> = [];
    let programOutcomesWithoutCategory: Array<any> = [];
    let categoryIds: Array<number> = [];
    let index = 1;

    programOutcomeCategories.forEach(category => {
      //go through outcomes and select ones in this category

      categoryIds.push(category.id);

      let outcomesInThisCategory: Array<ProgramOutcome> = [];

      programOutcomes.forEach(programOutcome => {
        if (programOutcome.categoryId == category.id) {
          programOutcome.index = index++;
          outcomesInThisCategory.push(programOutcome);
        }
      });

      programOutcomesByCategory.push({
        category: category,
        outcomes: outcomesInThisCategory
      });
    });

    //select outcomes not belonging to any category
    programOutcomes.forEach(outcome => {
      //if the outcome has no category ID or the category ID does not belong to any categories
      if (
        !outcome.categoryId ||
        categoryIds.indexOf(outcome.categoryId) == -1
      ) {
        outcome.index = index++;
        programOutcomesWithoutCategory.push(outcome);
      }
    });

    // callback(programOutcomesByCategory, programOutcomesWithoutCategory);

    return {
      programOutcomesByCategory: programOutcomesByCategory,
      programOutcomesWithoutCategory: programOutcomesWithoutCategory
    };
  }

  handleApiError(error) {
    //see below for the class variables

    Config.DEBUG && console.log(error);

    if (error.status == 0) {
      return new CustomError(
        error.status,
        "Error",
        "API server cannot be reached.",
        error.url
      );
    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return new CustomError(null, "Error", error.error.message, null);
    } else if (error instanceof TypeError) {
      return new CustomError(null, error.name, error.message, null);
    } else {
      // The backend returned an unsuccessful response code.

      let description = "";

      switch (error.status) {

        case 401:
          description = "You need to login to access this page.";
          break;
        case 403:
          description = "You are not allowed to access this page.";
          break;
        case 404:
          description = "There was an error while loading the information. Please try again later.";
          break;
        default:
          if (error.error) {
            description = error.error.error;
          } else {
            description = "There was an error while loading the information. Please try again later.";
          }
          break;

      }

      return new CustomError(
        error.status,
        error.statusText,
        description,
        error.url
      );
    }
  }

  /*****************************************
   * UPDATED: Get color palette
   *******************************************/
  getColorPalettes(reviewId: number) {

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/color-palettes",
        httpOptions
      )
      .pipe(
        map((colorPalettesArray: any) => {
          let colorPalettes = [];

          //convert this array to the array format we need
          Object.keys(colorPalettesArray).forEach(key => {
            let groupedColorPalettes: Array<Level> = [];

            colorPalettesArray[key].forEach(colors => {
              groupedColorPalettes.push(colors);
            });

            colorPalettes.push(groupedColorPalettes);
          });

          return colorPalettes;
        }),
        catchError((error: any) => {
          return throwError(this.handleApiError(error));
        })
      )
      .toPromise();
  }

  /************************************
   * For course outcome bar chart
   * @param selectedCourses
   * @param programLevelOutcomes
   * @param levels
   * @param outcomeMaps
   * @param callback
   *************************************/

  generateChartDataForOutcomesBar(
    selectedCourses: Array<Course>,
    programLevelOutcomes: Array<ProgramOutcome>,
    programLevelOutcomeCategories: Array<ProgramOutcomeCategory>,
    levels: Array<Level>,
    outcomeMaps: Array<any>,
    coursesToInclude: Array<Course>
  ) {
    // let chartData: any = {
    //   title: "Number of Course Outcomes ",
    //   subtitle: "per Program Level Learning Outcome",
    //   xAxisLabel: "Program Level Learning Outcomes",
    //   xAxisData: [],
    //   yAxisLabel: "# of Outcomes",
    //   series: []
    // }; //format chartData[ploId][levelId] = # outcomes

    //define x-axis
    let xAxis = [];

    programLevelOutcomes.forEach((plo, index) => {
      xAxis.push(
        plo.title.length > 30
          ? index + 1 + ". " + plo.title.substring(0, 28) + "..."
          : index + 1 + ". " + plo.title
      );
    });

    //get num outcomes

    let numOutcomes: Array<any> = [];

    programLevelOutcomes.forEach(plo => {
      if (!numOutcomes[plo.id]) {
        numOutcomes[plo.id] = [];
      }

      levels.forEach(level => {
        numOutcomes[plo.id][level.id] = 0;
      });

      for (let course of selectedCourses) {
        //only submitted courses
        if (course.status == "Submitted") {
          for (let courseOutcome of course.outcomes) {
            const level = outcomeMaps[courseOutcome.id][plo.id];

            if (level) {
              numOutcomes[plo.id][level.id]++;
            }
          }
        }
      }
    });

    // add total # to numOutcomes array

    numOutcomes.forEach(ploNum => {
      let total = 0;
      ploNum.forEach(num => {
        total += num;
      });

      ploNum["total"] = total;
    });

    // this.numOutcomes = numOutcomes;

    //colors:
    let levelColors = [];
    levels.forEach(level => {
      levelColors.push("#" + level.colorCode);
    });

    //based on numOutcomes, construct data for the chart

    let series: Array<any> = [];

    levels.forEach(level => {
      //initialize
      let data = [];

      programLevelOutcomes.forEach(plo => {
        // data.push({
        //   y: numOutcomes[plo.id][level.id],
        //   color: "#" + level.colorCode
        // });

        data.push(numOutcomes[plo.id][level.id]);
      });

      series.push({
        name: level.name,
        data: data
      });
    });

    Config.DEBUG && console.log(series);

    const chartOptions: Highcharts.Options = {
      chart: {
        type: "column",
        height: 500
      },
      colors: levelColors,
      title: {
        text: "Number of Course Outcomes",
        style: {}
      },
      subtitle: {
        text: "per Program-level Learning Outcome",
        style: {}
      },
      xAxis: {
        categories: xAxis,
        title: {
          text: "Program Level Learning Outcomes",
          margin: 30
        },
        labels: {
          rotation: 300
        }
      },
      yAxis: {
        title: {
          text: "# of Outcomes"
        },
        allowDecimals: false
      },
      plotOptions: {
        // column: {
        //   // colorByPoint: true,
        //   stacking: "stacked"
        //   // dataLabels: {
        //   //     enabled: true,
        //   //     color:  'white'
        //   // }
        // },
        series: {
          animation: {
            duration: 1000
          }
        }
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: "#FFFFFF",
        shadow: false,
        symbolRadius: 0,
        itemStyle: {
          fontWeight: "normal",
          fontSize: "10px"
        }
      },
      credits: {
        enabled: true
      },

      series: series
    };

    //table data
    const tableData = this.createTableDataForOutcomesBarChart(
      numOutcomes,
      programLevelOutcomes,
      programLevelOutcomeCategories,
      coursesToInclude
    );

    Config.DEBUG && console.log(tableData);

    // return callback(chartOptions, tableData);

    return {
      numOutcomes: numOutcomes,
      chartOptions: chartOptions,
      tableData: tableData
    };
  }

  createTableDataForOutcomesBarChart(
    numOutcomes,
    programOutcomes,
    programOutcomesByCategories,
    includedCourses
  ) {
    let tableData: any = {
      barChartData: [],
      includedCourses: []
    };

    Config.DEBUG && console.log(programOutcomes);
    Config.DEBUG && console.log(programOutcomesByCategories);

    programOutcomesByCategories.forEach(ploCategory => {
      tableData.barChartData.push({
        ploIndex: "category",
        ploTitle: ploCategory.category.name,
        ploDescription: null,
        total: null
      });

      ploCategory.outcomes.forEach(plo => {
        Config.DEBUG && console.log(plo);

        tableData.barChartData.push({
          ploIndex: plo.index,
          ploTitle: plo.title,
          ploDescription: plo.description,
          total: numOutcomes[plo.id]["total"]
        });
      });
    });

    includedCourses.forEach(course => {
      tableData.includedCourses.push({
        id: course.id,
        code: course.code,
        number: course.number,
        section: course.section,
        name: course.name,
        year: course.year,
        semester: course.semester,
        required: course.required
      });
    });

    programOutcomes.forEach((plo, index) => {
      let total = 0;

      numOutcomes[plo.id].forEach(num => {
        total += num;
      });

      tableData.barChartData.push({
        ploIndex: index + 1,
        ploTitle: plo.title,
        ploDescription: plo.description,
        total: total
      });
    });

    console.log(tableData);

    return tableData;
  }

  convertToKeyValueArray(array: Array<any>, keyName: string = null) {
    let keyValueArray = {};

    for (let item of array) {
      if (keyName) {
        keyValueArray[item[keyName]] = item;
      } else {
        keyValueArray[item.id] = item;
      }
    }

    return keyValueArray;
  }



  createBase64Image(elementIdName: string, type: string, callback) {


    if (type == "chart") {

      Config.DEBUG && console.log(elementIdName);

      let canvas = <HTMLCanvasElement>document.getElementById('canvas');


      Config.DEBUG && console.log('canvas');

      let ctx = canvas.getContext('2d');
      var DOMURL = window.URL || (window as any).webkitURL || window;

      let svg = document.querySelector('#' + elementIdName + ' svg'); //<--- CHANGE

      console.log('#' + elementIdName + ' svg');

      const rect = svg.getBoundingClientRect();
      const height = rect.height;
      const width = rect.width;


      ctx.canvas.width = width;
      ctx.canvas.height = height;

      var svgString = (new XMLSerializer()).serializeToString(svg);

      var img = new Image();
      // var svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      var svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
      var url = DOMURL.createObjectURL(svgBlob);

  
      img.onload = function () {

        ctx.drawImage(img, 0, 0);
        var base64image = canvas.toDataURL("image/png");


        // document.querySelector('#png-container').innerHTML = '<img src="' + base64image + '"/>';
        DOMURL.revokeObjectURL(base64image);


        return callback(base64image);


      };

      img.onerror = function (error) {
        console.log(error);
      }

      img.src = '';
      img.src = url;

    }
    else if (type == "table") {

      let element = document.getElementById("chart-table");

      html2canvas(element, {
        width: element.offsetWidth,
        height: element.offsetHeight
      }).then(canvas => {

        Config.DEBUG && console.log(canvas);

        return callback(canvas.toDataURL("image/png", 1.0));

        // canvas.toBlob(blob => {


        //   const a = document.createElement("a");
        //   a.href = window.URL.createObjectURL(blob);

        //   let fileName = "course_outcomes_table.png";
        //   a.download = fileName;


        //   //this works with Firefox
        //   a.dispatchEvent(
        //     new MouseEvent("click", { bubbles: false, cancelable: false })
        //   );


      });

    }

  }


  downloadBase64Image(base64image: string, fileName: string) {

    const a = document.createElement("a");
    a.href = base64image;
    a.download = fileName;

    //this works with Firefox
    a.dispatchEvent(
      new MouseEvent("click", { bubbles: false, cancelable: false })
    );

  }

  downloadTableImage(elementIdName: string, fileName: string) {

    Config.DEBUG && console.log(elementIdName);


    let tableElement: HTMLElement = document.querySelector('#' + elementIdName);

    Config.DEBUG && console.log(tableElement);

    domtoimage.toBlob(tableElement)
      .then(blob => {
        FileSaver.saveAs(blob, fileName);
      });

  }


  klog(...params) {

    if (!environment.production) {
      params.map(param => {
        console.log(param);
      })

    }

  }

}
