//https://ryanchenkie.com/angular-authentication-using-the-http-client-and-http-interceptors

import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { filter, take, switchMap, catchError } from "rxjs/operators";
import { AuthService } from "app/core/services/auth.service";
import { Config } from "../../config";
import { CustomError } from "../models/custom-error";
import { UtilService } from "app/core/services/util.service";

// import "rxjs/add/operator/do";

//https://ryanchenkie.com/angular-authentication-using-the-http-client-and-http-interceptors

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private utilService: UtilService
  ) {}

  // handle400Error(error) {
  //   if (
  //     error &&
  //     error.status === 400 &&
  //     error.error &&
  //     error.error.error === "invalid_grant"
  //   ) {
  //     // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.

  //     console.log("Logging out..");
  //     this.logout(error);
  //   }

  //   // return Observable.throw(error);
  //   return throwError(error);
  // }

  // handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   console.log("Logging out...");
  //   this.logout(null);

  //   return throwError(new CustomError());

  //   // return;

  //   // console.log(this.authService.getLocalToken() );

  //   // const refreshToken = this.authService.getRefreshToken();
  //   // console.log(request);
  //   // console.log(next);

  //   //if path is not guest...

  //   // if (refreshToken == null || refreshToken == "null") {

  //   //   console.log("Removing token...");

  //   //   this.authService.removeToken();
  //   //   return this.logout(null);
  //   // }

  //   // //token is not currently beign refreshed
  //   // else if (!this.authService.isRefreshingToken) {
  //   //   return this.authService.refreshToken().pipe(
  //   //     switchMap(() => {
  //   //       console.log("Token Refreshed!");
  //   //       console.log("Resending request..");
  //   //       //console.log(request);

  //   //       //resent request with new token
  //   //       return next.handle(
  //   //         (request = request.clone({
  //   //           setHeaders: {
  //   //             Authorization: `Bearer ${this.authService.getLocalToken()}`
  //   //           }
  //   //         }))
  //   //       );
  //   //     }),
  //   //     catchError(error => {
  //   //       //refreshing token failed. force logout user.
  //   //       console.log("Could not refresh token :(");
  //   //       console.log(error);
  //   //       this.authService.isRefreshingToken = false;
  //   //       return this.logout(error);
  //   //     })
  //   //   );
  //   // } else {
  //   //   console.log("Token is currently refreshed..");

  //   //   //token is currently being refreshed. wait until new token is avail by using filter
  //   //   return (
  //   //     this.authService.tokenRefreshed
  //   //       .pipe(filter(tokenRefreshed => tokenRefreshed != false))
  //   //       .pipe(take(1))
  //   //       //emit only first value emitted by the observable
  //   //       .pipe(
  //   //         switchMap(token => {
  //   //           console.log(
  //   //             "Token refreshing ended.. processing pending request.."
  //   //           );

  //   //           //resend request with new token
  //   //           return next.handle(
  //   //             (request = request.clone({
  //   //               setHeaders: {
  //   //                 Authorization: `Bearer ${this.authService.getLocalToken()}`
  //   //               }
  //   //             }))
  //   //           );
  //   //         })
  //   //       )
  //   //   );
  //   // }
  // }

  logout(error) {
    // console.log("Logging out..");
    this.authService.logout();

    // console.log("Redirecting to login...");

    // window.location.href = "/auth/login";

    return throwError(error);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log("Intercepted the following request");
    // console.log(request);

    //if refresh request, ignore this interceptor
    if (request.url == Config.BASE_API_URL_V1 + "/login") {
      // //console.log("Ignoring the request...");

      return next.handle(request);
    } else {
      //add token to request
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getLocalToken()}`,
          GuestToken: this.authService.getGuestToken()
        }
      });

      // //console.log(request);

      return next.handle(request).pipe(
        catchError((error: any) => {
          //console.log("API Error");
          //console.log(request.method + "@" + request.url);
          console.log(error);

          // this.utilService.handleApiError(error);

          return throwError(error);

          // if (error instanceof HttpErrorResponse) {
          //   switch ((<HttpErrorResponse>error).status) {
          //     case 400:
          //       // return Observable.throw(error);
          //       return this.handle400Error(error);
          //     case 401:
          //       return this.handle401Error(request, next);
          //     default:
          //       //e.g. API server is down
          //       // return Observable.throw(error);
          //       // return throwError(error);
          //       //console.log(error);
          //       return throwError(error);
          //   }
          // } else {
          //   return throwError(error);
          // }
        })
      );
    }
  }
}
