export class TlaFormat {
  private _id: number;
  private _isEnabled: boolean;
  private _format: string;
  private _isExtentRequired: boolean;
  private _extentFormat: string; //checkbox or text
  private _requiredByCourseOutcome: boolean;
  private _chartColor: string;
  private _extentChartColor: string;

  constructor(
    id: number = null,
    isEnabled: boolean = false,
    format: string = "text",
    isExtentRequired: boolean = false,
    extentFormat: string = "text",
    requiredByCourseOutcome: boolean = false,
    chartColor: string = "00695C",
    extentChartColor: string = "00695C"
  ) {
    this._id = id;
    this._isEnabled = isEnabled;
    this._format = format;
    this._isExtentRequired = isExtentRequired;
    this._extentFormat = extentFormat;
    this._requiredByCourseOutcome = requiredByCourseOutcome;
    this._chartColor = chartColor ? chartColor : "00695C";
    this._extentChartColor = extentChartColor ? extentChartColor : "00695C";
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._isEnabled = value;
  }

  public get format(): string {
    return this._format;
  }

  public set format(value: string) {
    this._format = value;
  }

  public get isExtentRequired(): boolean {
    return this._isExtentRequired;
  }

  public set isExtentRequired(value: boolean) {
    this._isExtentRequired = value;
  }

  public get extentFormat(): string {
    return this._extentFormat;
  }

  public set extentFormat(value: string) {
    this._extentFormat = value;
  }

  public get requiredByCourseOutcome(): boolean {
    return this._requiredByCourseOutcome;
  }

  public set requiredByCourseOutcome(value: boolean) {
    this._requiredByCourseOutcome = value;
  }

  /**
   * Getter chartColor
   * @return {string}
   */
  public get chartColor(): string {
    return this._chartColor;
  }

  /**
   * Setter chartColor
   * @param {string} value
   */
  public set chartColor(value: string) {
    this._chartColor = value;
  }

  /**
   * Getter extentChartColor
   * @return {string}
   */
  public get extentChartColor(): string {
    return this._extentChartColor;
  }

  /**
   * Setter extentChartColor
   * @param {string} value
   */
  public set extentChartColor(value: string) {
    this._extentChartColor = value;
  }
}
