export class AssessmentOption {
  private _id: number;
  private _description: string;
  private _isOtherTextRequired: boolean;
  private _flag: string; //used when adding/deleting options
  private _weight: number;
  private _additionalDescription: string;

  constructor(
    id: number = null,
    description: string = null,
    isOtherTextRequired: boolean = false,
    flag: string = null,
    weight: number = null,
    additionalDescription: string = null
  ) {
    this._id = id;
    this._description = description;
    this._isOtherTextRequired = isOtherTextRequired;
    this._flag = null;
    this._weight = weight;
    this._additionalDescription = additionalDescription;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get isOtherTextRequired(): boolean {
    return this._isOtherTextRequired;
  }

  public set isOtherTextRequired(value: boolean) {
    this._isOtherTextRequired = value;
  }

  public get flag(): string {
    return this._flag;
  }

  public set flag(value: string) {
    this._flag = value;
  }

  /**
   * Getter weight
   * @return {number}
   */
  public get weight(): number {
    return this._weight;
  }

  /**
   * Setter weight
   * @param {number} value
   */
  public set weight(value: number) {
    this._weight = value;
  }

  /**
   * Getter additionalDescription
   * @return {string}
   */
  public get additionalDescription(): string {
    return this._additionalDescription;
  }

  /**
   * Setter additionalDescription
   * @param {string} value
   */
  public set additionalDescription(value: string) {
    this._additionalDescription = value;
  }
}
