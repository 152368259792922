export class AssessmentTimingOption {
  private _id: number;
  private _description: string;
  private _isOtherTextRequired: boolean;
  private _flag: string; //used when adding/deleting options
  private _isSelected: boolean;
  private _otherText: string;

  constructor(
    id: number,
    description: string,
    isOtherTextRequired: boolean,
    flag: string,
    otherText: string,
    isSelected: boolean
  ) {
    this._id = id;
    this._description = description;
    this._isOtherTextRequired = isOtherTextRequired;
    this._flag = null;
    this._isSelected = isSelected;
    this._otherText = otherText;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get isOtherTextRequired(): boolean {
    return this._isOtherTextRequired;
  }

  public set isOtherTextRequired(value: boolean) {
    this._isOtherTextRequired = value;
  }

  public get flag(): string {
    return this._flag;
  }

  public set flag(value: string) {
    this._flag = value;
  }

  /**
   * Getter isSelected
   * @return {boolean}
   */
  public get isSelected(): boolean {
    return this._isSelected;
  }

  /**
   * Setter isSelected
   * @param {boolean} value
   */
  public set isSelected(value: boolean) {
    this._isSelected = value;
  }

  /**
   * Getter otherText
   * @return {string}
   */
  public get otherText(): string {
    return this._otherText;
  }

  /**
   * Setter otherText
   * @param {string} value
   */
  public set otherText(value: string) {
    this._otherText = value;
  }
}
