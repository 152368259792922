// import { descending } from "d3";

export class CurriculumReview {
  private _id: number;
  private _name: string;
  private _description: string;
  private _status: string;
  private _program: string;
  private _faculty: string;


  constructor(
    id: number = null,
    name: string = null,
    description: string = null,
    status: string = null,
    program: string = null,
    faculty: string = null,

  ) {
    this._id = id;
    this._name = name;
    this._description = description;
    this._status = status;
    this._program = program;
    this._faculty = faculty;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get status(): string {
    return this._status;
  }

  public set status(value: string) {
    this._status = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get program(): string {
    return this._program;
  }

  public set program(value: string) {
    this._program = value;
  }

  public get faculty(): string {
    return this._faculty;
  }

  public set faculty(value: string) {
    this._faculty = value;
  }

}
