import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _modalShown= new Subject<boolean>();
  modalShown = this._modalShown.asObservable();

  constructor() {
    this._modalShown.next(true);
  }

  showModal(modalShown: boolean) {
    this._modalShown.next(modalShown);
  }


}
