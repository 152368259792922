import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "../../../environments/environment";

//Models
import { Course } from "app/core/models/course";
import { CourseUser } from "app/core/models/course-user";
import { UserCourse } from "app/core/models/user-course";
import { AssessmentFormat } from "app/core/models/assessment-format";
import { CustomError } from "../models/custom-error";
import { CurriculumReview } from "app/core/models/curriculum-review";

//Services
import { UtilService } from "./util.service";

//Libraries
import { Observable, throwError } from "rxjs";
import { map, catchError, finalize } from "rxjs/operators";
import { Config } from "../../config";

@Injectable({
  providedIn: "root"
})
export class CourseService {
  constructor(
    private utilService: UtilService,
    private httpClient: HttpClient
  ) { }

  /*************************************************
   * Get courses for this review
   * Error Checked
   **************************************************/
  getCourses(reviewId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 + "/curriculum-reviews/" + reviewId + "/courses"
      )
      .pipe(
        map((coursesArray: Array<any>) => {

          let courses: Array<Course> = [];

          coursesArray.forEach(course => {
            let newCourse = new Course(
              course.id,
              course.code,
              course.number,
              course.section,
              course.name,
              course.year,
              course.semester,
              course.reviewId,
              course.required == 1 ? true : false,
              [],
              course.status
            );

            if (course.deleted) {
              newCourse.isDeleted = true;
            } else if (course.archived) {
              newCourse.isArchived = true;
            }

            courses.push(newCourse);
          });

          return courses;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }





  notifyInstructors(reviewId: number, courseId: number, callbackUrl: string) {
    // console.log(callbackUrl);

    callbackUrl += "/auth/guest";

    const body = {
      callbackUrl: callbackUrl,
      clientUrl: environment.APP_ROOT
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId +
        "/notify",
        body
      )
      .pipe(
        map(
          result => {
            return true;
          },
          catchError(error => {
            console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
      )
      .toPromise();
  }

  /**
   * Add a new course to Curriculum Project
   * @param reviewId
   * @param course
   */
  addCourse(reviewId: number, course: Course) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    const body = {
      code: course.code,
      number: course.number,
      section: course.section,
      semester: course.semester,
      name: course.name,
      year: course.year,
      required: course.required ? 1 : 0,
      status: course.status
    };

    //console.log(body);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 + "/curriculum-reviews/" + reviewId + "/courses",
        body,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          //console.log(result);
          return new Course(
            result.id,
            result.code,
            result.number,
            result.section,
            result.name,
            result.year,
            result.semester,
            result.reviewId,
            result.required,
            [],
            result.status
          );
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  deleteCourse(reviewId: number, courseToDelete: Course) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };
    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseToDelete.id,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          return true;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  updateCourse(reviewId: number, course: Course) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    //TODO:
    if (!reviewId || !course || !course.id) {
      //ERROR
    }

    const courseId = course.id;

    const body = {
      code: course.code,
      number: course.number,
      section: course.section,
      semester: course.semester,
      name: course.name,
      year: course.year,
      required: course.required ? 1 : 0
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId,
        body,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          //console.log(result);
          return new Course(
            result.id,
            result.code,
            result.number,
            result.section,
            result.name,
            result.year,
            result.semester,
            result.reviewId,
            result.required,
            [],
            result.status
          );
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  updateCourseOrder(
    reviewId: number,
    courseOrder: Array<{ courseId: number; courseOrder: number }>
  ) {
    const body = {
      order: courseOrder
    };

    //console.log(courseOrder);

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/reorder",
        body
      )
      .pipe(
        map((result: any) => {
          return;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  getAssignedUsers(reviewId: number, courseId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId +
        "/users"
      )
      .pipe(
        map((userArray: Array<any>) => {
          //return array of user emails

          let users = [];

          userArray.forEach(user => {
            // users.push({new CourseUser(user.id, user.userEmail)});
            users.push({
              id: user.id,
              email: user.userEmail,
              notified: user.notified ? true : false
            });
          });

          return users;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  addAssignedUser(reviewId: number, courseId: number, assignedUser: string) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    const body = {
      userEmail: assignedUser
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId +
        "/users",
        body,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          //console.log(result);
          return result;
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  deleteAssignedUser(
    reviewId: number,
    courseId: number,
    assignedUserToDelete: CourseUser
  ) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId +
        "/users/" +
        assignedUserToDelete.id,

        httpOptions
      )
      .pipe(
        map((result: any) => {
          return true;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*************************************************
   * Get courses assigned to the logged in user
   **************************************************/
  getUserCourses() {
    //how to get user from token??

    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/user/courses")
      .pipe(
        map((userCoursesData: Array<any>) => {
          // console.log(userCoursesData);

          let courses: Array<UserCourse> = [];

          userCoursesData.forEach(course => {
            let newCourse = new UserCourse(
              course.id,
              course.code,
              course.number,
              course.section,
              course.name,
              course.year,
              course.semester,
              course.reviewId,
              course.required == 1 ? true : false,
              [],
              course.status
            );

            if (course.deleted) {
              newCourse.isDeleted = true;
            } else if (course.archived) {
              newCourse.isArchived = true;
            }

            newCourse.review = course.reviewName;
            newCourse.program = course.program;
            newCourse.faculty = course.faculty;

            courses.push(newCourse);
          });

          return courses;
        }),
        catchError((error: any) => {
          console.log(error);
          error.customErrorDescription = "Could not retrieve list of courses.";
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*************************************************
   * Get selected course details
   **************************************************/
  getUserCourse(courseId) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/user/courses/" + courseId, httpOptions)
      .pipe(
        map((course: any) => {
          // console.log(course);

          let userCourse = new UserCourse(
            course.id,
            course.code,
            course.number,
            course.section,
            course.name,
            course.year,
            course.semester,
            course.reviewId,
            course.required,
            [],
            course.status
          );

          if (course.deleted) {
            userCourse.isDeleted = true;
          } else if (course.archived) {
            userCourse.isArchived = true;
          }

          userCourse.review = course.reviewName;
          userCourse.program = course.program;
          userCourse.faculty = course.faculty;

          return userCourse;
        }),
        catchError((error: any) => {
          console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   *
   * @param courseId
   */
  getReview(courseId: number) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 + "/user/courses/" + courseId + "/review",
        httpOptions
      )
      .pipe(
        map((review: any) => {
          // console.log(review);

          let status: string;

          if (review.deleted) {
            status = "Deleted";
          } else if (review.archived) {
            status = "Archived";
          } else {
            status = review.status;
          }

          return new CurriculumReview(
            review.id,
            review.name,
            review.description,
            status,
            review.program,
            review.faculty
          );
        }),
        catchError((error: any) => {
          console.log(error);

          let customError: CustomError = this.utilService.handleApiError(error);

          if (customError.status == "403") {
            customError.message = "You do not have access to this page.";
          }

          return throwError(customError);
        })
      )
      .toPromise();
  }

  /**********************
   * Course Status Change
   **********************/

  submitCourse(courseId: number) {
    //change course status to submitted.

    const body = { status: "Submitted" };

    return this.httpClient
      .put(Config.BASE_API_URL_V1 + "/courses/" + courseId + "/status", body)
      .pipe(
        map(result => {
          //console.log(result);
        }),
        catchError((error: any) => {
          //console.log(error);
          error.customErrorDescription = "Could not submit your course.";
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**********************
   * Update Course Status
   **********************/

  updateCourseStatus(reviewId: number, courseId: number, status: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    //change course status to submitted.

    const body = { status: status };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 + "/courses/" + courseId + "/status",
        body,
        httpOptions
      )
      .pipe(
        map(affectedRows => {
          return affectedRows;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  downloadCourseSummary(courseId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/generate-course-summary-pdf-object"
      )
      .pipe(
        map((data: any) => {
          // console.log(data);

          return data;

          //window.open(result.path);

          // return atob(result.pdf);

          // return result.url;
        }),
        catchError((error: any) => {
          console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();

    // return this.httpClient
    //   .get(
    //     Config.BASE_API_URL_V1 +
    //       "/courses/" +
    //       courseId +
    //       "/generate-course-summary-pdf"
    //   )
    //   .pipe(
    //     map((result: any) => {

    //       console.log(result);

    //       return result.path;

    //       //window.open(result.path);

    //       // return atob(result.pdf);

    //       // return result.url;
    //     }),
    //     catchError((error: any) => {
    //       console.log(error);
    //       return throwError(this.utilService.handleApiError(error));
    //     })
    //   )
    //   .toPromise();
  }

  /***
   * Check if the user is course instructor
   */
  // isInstructor(userId: number, courseId: number, token: string) {
  //   return true;
  // }
}
