<div class="plo-section">



  <div *ngIf="programOutcomesByCategory.length > 0  " class="ui celled grid">

    <div class="row header-row">
      <div class="one wide column"></div>
      <div class="four wide column">Title</div>
      <ng-container *ngIf="editable">
        <div class="eight wide column">Description</div>
        <div class="three wide column button">
        </div>
      </ng-container>
      <ng-container *ngIf="!editable">
        <div class="nine wide column">Description</div>
      </ng-container>

    </div>

    <ng-container *ngFor="let groupedOutcomes of programOutcomesByCategory">

      <div class="row category-row">
        <div class="thirteen wide column">{{groupedOutcomes.category.name}}</div>
      </div>

      <div class="row" *ngFor="let outcome of groupedOutcomes.outcomes">
        <div class="one wide column">{{outcome.index}}</div>
        <div class="four wide column">{{outcome.title}}</div>
        <ng-container *ngIf="editable">
          <div class="eight wide column">{{outcome.description}}</div>
          <div class="three wide column button">

          </div>
        </ng-container>
        <ng-container *ngIf="!editable">
          <div class="nine wide column">{{outcome.description}}</div>
        </ng-container>
      </div>

    </ng-container>


    <ng-container *ngIf="programOutcomesByCategory.length > 0 && programOutcomesWithoutCategory.length > 0">


      <div class="row category-row">
        <div class="sixteen wide column">No Category</div>
      </div>

      <div class="row" *ngFor="let outcome of programOutcomesWithoutCategory" [sortablejs]="programOutcomesWithoutCategory">
        <div class="one wide column">{{outcome.index}}</div>
        <div class="four wide column">{{outcome.title}}</div>

        <ng-container *ngIf="editable">
          <div class="eight wide column">{{outcome.description}}</div>
          <div class="three wide column button">

            <!-- <button class="ui button mini basic " (click)="editOutcomeClicked(outcome.id)">Edit</button>
            <button class="ui button mini basic delete-button" (click)="deleteOutcomeClicked(outcome)">Delete</button> -->

          </div>
        </ng-container>


        <ng-container *ngIf="!editable">
          <div class="nine wide column">{{outcome.description}}</div>
        </ng-container>

      </div>


    </ng-container>


  </div>




  <div *ngIf="programOutcomesByCategory.length == 0 && programOutcomesWithoutCategory.length > 0">

    <table class="ui table program-outcome-table celled">
      <tr class="header-row">
        <td></td>
        <td>Title</td>
        <td>Description</td>
      </tr>
      <tr class="outcome-row" *ngFor="let outcome of programOutcomesWithoutCategory">
        <td class="index-col">{{outcome.index}}</td>
        <td class="title-col">{{outcome.title}}</td>
        <td>{{ outcome.description }}</td>
        <!-- <td class="button-col" *ngIf="editable">
          <div>
            <button class="ui button mini basic " (click)="editOutcomeClicked(outcome.id)">Edit</button>
            <button class="ui button mini basic delete-button" (click)="deleteOutcomeClicked(outcome)">Delete</button>
          </div>


        </td> -->
      </tr>
    </table>

  </div>
</div>
