export class TlaExtentOption {
  private _id: number;
  private _description: string;
  private _isTextRequired: boolean;
  private _otherText: string;
  private _flag: string;
  private _isSelected: boolean;

  constructor(
    id: number = null,
    description: string = null,
    isTextRequired: boolean = false,
    otherText: string = null,
    flag: string = null,
    isSelected: boolean = false
  ) {
    this._id = id;
    this._description = description;
    this._isTextRequired = isTextRequired;
    this._otherText = otherText;
    this._flag = flag;
    this._isSelected = isSelected;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get isTextRequired(): boolean {
    return this._isTextRequired;
  }

  public set isTextRequired(value: boolean) {
    this._isTextRequired = value;
  }

  /**
   * Getter otherText
   * @return {string}
   */
  public get otherText(): string {
    return this._otherText;
  }

  /**
   * Setter otherText
   * @param {string} value
   */
  public set otherText(value: string) {
    this._otherText = value;
  }

  public get flag(): string {
    return this._flag;
  }

  public set flag(value: string) {
    this._flag = value;
  }

  /**
   * Getter isSelected
   * @return {boolean}
   */
  public get isSelected(): boolean {
    return this._isSelected;
  }

  /**
   * Setter isSelected
   * @param {boolean} value
   */
  public set isSelected(value: boolean) {
    this._isSelected = value;
  }
}
