import { QuestionAnswerOption } from "app/core/models/question-answer-option";
import { QuestionTextAnswer } from "app/core/models/question-text-answer";

export class Question {
  private _id: number;
  private _format: string;
  private _question: string;
  private _relatingTo: string;
  private _orderNum: number;
  // private _textAnswer: QuestionTextAnswer;

  private _textAnswer: QuestionTextAnswer;

  private _options: Array<QuestionAnswerOption> = [];

  // private _courseId: number;
  // private _outcomeId: number;

  constructor(
    id: number = null,
    format: string = "text",
    question: string = null,
    relatingTo: string = null,
    orderNum: number = null,
    options: Array<QuestionAnswerOption> = [],
    // textAnswer: QuestionTextAnswer = null
    textAnswer: QuestionTextAnswer = new QuestionTextAnswer(null, null, null, null, null)
  ) {
    this._id = id;
    this._format = format;
    this._question = question;
    this._relatingTo = relatingTo;
    this._orderNum = orderNum;
    this._options = options;
    this._textAnswer = textAnswer;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get format(): string {
    return this._format;
  }

  public set format(value: string) {
    this._format = value;
  }

  public get question(): string {
    return this._question;
  }

  public set question(value: string) {
    this._question = value;
  }

  public get relatingTo(): string {
    return this._relatingTo;
  }

  public set relatingTo(value: string) {
    this._relatingTo = value;
  }

  public get orderNum(): number {
    return this._orderNum;
  }

  public set orderNum(value: number) {
    this._orderNum = value;
  }

  public get options(): Array<QuestionAnswerOption> {
    return this._options;
  }

  public set options(value: Array<QuestionAnswerOption>) {
    this._options = value;
  }

  /**
   * Getter textAnswer
   * @return {QuestionTextAnswer}
   */
  public get textAnswer(): QuestionTextAnswer {
    return this._textAnswer;
  }

  /**
   * Setter textAnswer
   * @param {QuestionTextAnswer} value
   */
  public set textAnswer(value: QuestionTextAnswer) {
    this._textAnswer = value;
  }
}
