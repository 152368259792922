import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
  pure: false
})
export class GroupByPipe implements PipeTransform {

  transform(array: Array<any>, field: string): Array<any> {

    if (array) {

      const groupedObj = array.length == 0 ? [] : array.reduce((grouped, item) => {

        const key = item[field];
        grouped[key] = grouped[key] || [];
        grouped[key].push(item);
        return grouped;

      }, {});

      const groupedArray = Object.keys(groupedObj).map(key => {
        return { key, value: groupedObj[key] }
      });

      return groupedArray;
    }
    else {
      return [];
    }

  }

}
