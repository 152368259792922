import { Component, OnInit, Input } from '@angular/core';

import { Level } from 'app/core/models/level';
import { Loading } from "app/core/models/loading";


@Component({
  selector: 'app-mapping-scale',
  templateUrl: './mapping-scale.component.html',
  styleUrls: ['./mapping-scale.component.scss']
})
export class MappingScaleComponent implements OnInit {

  @Input()
  levels: Array<Level> = [];

  constructor() { }

  ngOnInit() {

  }

}
