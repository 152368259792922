import { Component, OnInit, Input } from "@angular/core";

import { CustomError } from "app/core/models/custom-error";

@Component({
  selector: "app-global-error",
  templateUrl: "./global-error.component.html",
  styleUrls: ["./global-error.component.scss"]
})
export class GlobalErrorComponent implements OnInit {
  @Input() error: CustomError;

  constructor() {}

  ngOnInit() {}
}
