import { ProgramOutcome } from "./program-outcome";

export class OutcomeMapValue {
  private _programOutcomeId: number;
  private _levelId: number;

  constructor(programOutcomeId: number, levelId: number) {
    this._programOutcomeId = programOutcomeId;
    this._levelId = levelId;
  }

//   /**
//    * Getter programOutcome
//    * @return {ProgramOutcome}
//    */
//   public get programOutcome(): ProgramOutcome {
//     return this._programOutcome;
//   }

//   /**
//    * Setter programOutcome
//    * @param {ProgramOutcome} value
//    */
//   public set programOutcome(value: ProgramOutcome) {
//     this._programOutcome = value;
//   }

  public get levelId(): number {
    return this._levelId;
  }

  public set levelId(value: number) {
    this._levelId = value;
  }


    /**
     * Getter programOutcomeId
     * @return {number}
     */
	public get programOutcomeId(): number {
		return this._programOutcomeId;
	}

    /**
     * Setter programOutcomeId
     * @param {number} value
     */
	public set programOutcomeId(value: number) {
		this._programOutcomeId = value;
	}


}
