import { Component, OnInit, Input } from "@angular/core";
import { AlertService } from "app/core/services/alert.service";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"]
})
export class AlertComponent implements OnInit {
  alert: any;
  shouldShowAlert: boolean;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService.alert.subscribe(alert => {
      this.alert = alert;
      //console.log(alert);
    });
    this.alertService.shouldShowAlert.subscribe(shouldShowAlert => {
      this.shouldShowAlert = shouldShowAlert;
    });


    
  }

  closeAlert() {
    this.alertService.hideAlert();
  }
}
