<div class="load-error" *ngIf="loadError">

  <div class="ui icon negative message">

    <i class="exclamation circle icon"></i>

    <div class="content">

      <div class="header">
        Error: <span *ngIf="loadError.status">{{loadError.status}} </span>{{loadError.title}}
      </div>
      <p [innerHTML]="loadError.message">
      </p>

    </div>

  </div>

</div>
