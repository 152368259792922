<div class="ui page dimmer">

  <div class="content">
    <div class="center">
      <div class="ui inverted icon">
        <div class="ui text loader">{{message}}</div>
      </div>
    </div>
  </div>

</div>
