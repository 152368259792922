import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { CustomError } from "app/core/models/custom-error";

@Injectable({
  providedIn: 'root'
})
export class ActionErrorService {


  private _actionError = new Subject<any>();
  actionError = this._actionError.asObservable();

  private _shouldShowActionError = new Subject<boolean>();
  shouldShowActionError = this._shouldShowActionError.asObservable();

  constructor() { 

    this._actionError.next(null);

  }

  showActionError(actionError: CustomError){

    this._actionError.next(actionError);
    this._shouldShowActionError.next(true);

  }

  hideActionError() {
    this._actionError.next([]);
    this._shouldShowActionError.next(false);
  }

}
