import { Injectable } from "@angular/core";
// import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//Models
import { SelectedAssessmentLevelText } from "../models/selected-assessment-level-text";
import { AssessmentLevelOptionSelected } from "../models/assessment-level-option-selected";
import { CourseOutcome } from "app/core/models/course-outcome";
import { AssessmentMethodOption } from "app/core/models/assessment-method-option";
import { AssessmentSelectedMethod } from "app/core/models/assessment-selected-method";
import { AssessmentOption } from "app/core/models/assessment-option";
import { AssessmentTimingOption } from "app/core/models/assessment-timing-option";
import { AssessmentTimingOptionSelected } from "app/core/models/assessment-timing-option-selected";
import { AssessmentLevelOption } from "app/core/models/assessment-level-option";
import { AssessmentFormat } from "../models/assessment-format";

//Service
import { UtilService } from "./util.service";

//Libraries
import { Observable, throwError } from "rxjs";
import { Config } from "../../config";
import { map, catchError, finalize } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AssessmentService {
  constructor(
    private utilService: UtilService,
    private httpClient: HttpClient
  ) { }

  ////////////////////////// Assessment Format - //////////////////////////

  /**
   * Get assessment format for the selected curriculum review
   *
   */
  getAssessmentFormatByReviewId(reviewId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment"
      )
      .pipe(
        map((result: any) => {
          let assessmentFormat: AssessmentFormat = null;

          if (result) {
            assessmentFormat = new AssessmentFormat(
              result.id,
              result.isEnabled ? true : false,
              result.format,
              result.isWeightRequired ? true : false,
              result.isTimingRequired ? true : false,
              result.timingFormat,
              result.isLevelRequired ? true : false,
              result.levelFormat,
              result.requiredByCourseOutcome,
              result.chartColor ? result.chartColor : null,
              result.levelChartColor ? result.levelChartColor : null,
              result.timingChartColor ? result.timingChartColor : null
            );
          }

          return assessmentFormat;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param courseId 
   */
  getAssessmentFormatByCourseId(courseId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 + "/user/courses/" + courseId + "/assessment"
      )
      .pipe(
        map((format: any) => {

          return new AssessmentFormat(
            format.id,
            format.isEnabled ? true : false,
            format.format,
            format.isWeightRequired ? true : false,
            format.isTimingRequired ? true : false,
            format.timingFormat,
            format.isLevelRequired ? true : false,
            format.levelFormat,
            format.requiredByCourseOutcome ? true : false,
            format.chartColor ? format.chartColor : "1E88E5",
            format.levelChartColor ? format.levelChartColor : "EF6C00",
            format.timingChartColor ? format.timingChartColor : "6A1B9A"
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Post Assessment Format
   */
  postAssessmentFormat(
    reviewId: number,
    newAssessmentFormat: AssessmentFormat
  ) {
    const body = {
      isEnabled: newAssessmentFormat.isEnabled ? 1 : 0,
      reviewId: reviewId,
      format: newAssessmentFormat.format,
      isWeightRequired: newAssessmentFormat.isWeightRequired ? 1 : 0,
      isTimingRequired: newAssessmentFormat.isTimingRequired ? 1 : 0,
      timingFormat: newAssessmentFormat.timingFormat,
      isLevelRequired: newAssessmentFormat.isLevelRequired ? 1 : 0,
      levelFormat: newAssessmentFormat.levelFormat,
      requiredByCourseOutcome: newAssessmentFormat.requiredByCourseOutcome
        ? 1
        : 0,
      chartColor: newAssessmentFormat.chartColor,
      levelChartColor: newAssessmentFormat.levelChartColor,
      timingChartColor: newAssessmentFormat.timingChartColor
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment",
        body
      )
      .pipe(
        map((result: any) => {
          return new AssessmentFormat(
            result.id,
            result.isEnabled,
            result.format,
            result.isOtherOptionEnabled,
            result.isTimingRequired,
            result.timingFormat,
            result.isLevelRequired,
            result.levelFormat,
            result.requiredByCourseOutcome,
            result.chartColor,
            result.levelChartColor,
            result.timingChartColor
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param reviewId 
   * @param newAssessmentFormat 
   */
  updateAssessmentFormat(
    reviewId: number,
    newAssessmentFormat: AssessmentFormat
  ) {
    const assessmentId = newAssessmentFormat.id;

    const body = {
      isEnabled: newAssessmentFormat.isEnabled,
      reviewId: reviewId,
      format: newAssessmentFormat.format,
      isWeightRequired: newAssessmentFormat.isWeightRequired,
      isTimingRequired: newAssessmentFormat.isTimingRequired,
      timingFormat: newAssessmentFormat.timingFormat,
      isLevelRequired: newAssessmentFormat.isLevelRequired,
      levelFormat: newAssessmentFormat.levelFormat,
      requiredByCourseOutcome: newAssessmentFormat.requiredByCourseOutcome,
      chartColor: newAssessmentFormat.chartColor,
      levelChartColor: newAssessmentFormat.levelChartColor,
      timingChartColor: newAssessmentFormat.timingChartColor
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId,
        body
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);

          return new AssessmentFormat(
            result.id,
            result.isEnabled,
            result.format,
            result.isWeightRequired,
            result.isTimingRequired,
            result.timingFormat,
            result.isLevelRequired,
            result.levelFormat,
            result.requiredByCourseOutcome,
            result.chartColor,
            result.levelChartColor,
            result.timingChartColor
          );
        }),
        catchError(error => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  ////////////////////////// Assessment Method Options - ADMIN //////////////////////////

  /**
   * Get assessment method options without values
   * @param reviewId 
   * @param assessmentFormatId 
   */
  getAssessmentMethodOptionsByReviewId(
    reviewId: number,
    assessmentFormatId: number
  ) {


    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentFormatId +
        "/options"
      )
      .pipe(
        map((data: any) => {
          let assessmentOptions: Array<AssessmentOption> = [];

          data.forEach(option => {
            assessmentOptions.push(
              new AssessmentOption(
                option.id,
                option.option,
                option.otherTextRequired ? true : false,
                null,
                null
              )
            );
          });

          return assessmentOptions;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  /**
   * Post Assessment method option
   * @param reviewId 
   * @param assessmentId 
   * @param newOption 
   */
  postAssessmentMethodOption(
    reviewId: number,
    assessmentId: number,
    newOption: AssessmentOption
  ) {

    const body = {
      option: newOption.description,
      otherTextRequired: newOption.isOtherTextRequired ? 1 : 0
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/options",
        body
      )
      .pipe(
        map((result: any) => {
          return new AssessmentOption(result.id, result.option);
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  /**
   * Delete Assessment Method Option
   * @param reviewId 
   * @param assessmentId 
   * @param optionId 
   */
  deleteAssessmentMethodOption(
    reviewId: number,
    assessmentId: number,
    optionId: number
  ) {

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/options/" +
        optionId
      )
      .pipe(
        map((result: any) => {
          return true;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }



  ////////////////////////// Assessment Timing Options - ADMIN //////////////////////////

  /**
   * Get assessment timing options without values
   * @param reviewId 
   * @param assessmentFormatId 
   */
  getAssessmentTimingOptionsByReviewId(
    reviewId: number,
    assessmentFormatId: number
  ) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentFormatId +
        "/timing-options"
      )
      .pipe(
        map((data: any) => {
          let assessmentTimingOptionValues: Array<AssessmentTimingOption> = [];

          data.forEach(option => {
            assessmentTimingOptionValues.push(
              new AssessmentTimingOption(
                option.id,
                option.description,
                option.otherTextRequired,
                null,
                null,
                false
              )
            );
          });

          return assessmentTimingOptionValues;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  /**
   * Post a Timing Option
   * @param reviewId 
   * @param assessmentId 
   * @param newTimingOption 
   */
  postAssessmentTimingOption(
    reviewId: number,
    assessmentId: number,
    newTimingOption: AssessmentTimingOption
  ) {

    const body = {
      description: newTimingOption.description,
      otherTextRequired: newTimingOption.isOtherTextRequired
    };

    ////console.log(body);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/timing-options",
        body
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return new AssessmentOption(result.id, result.option);
        }),
        catchError(error => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************************************
   * DONE: Delete Assessment Method Option
   * Error: Done
   *************************************************/
  deleteAssessmentTimingOption(
    reviewId: number,
    assessmentId: number,
    optionId: number
  ) {

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/timing-options/" +
        optionId
      )
      .pipe(
        map((result: any) => {
          return true;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }



  ////////////////////////// Assessment Level Options - ADMIN //////////////////////////

  /***************************************************
   * DONE: Get assessment level options without values
   * Error: Done
   ***************************************************/
  getAssessmentLevelOptionsByReviewId(
    reviewId: number,
    assessmentFormatId: number
  ) {


    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentFormatId +
        "/level-options"
      )
      .pipe(
        map((data: any) => {
          //console.log(data);

          let assessmentLevelOptionValues: Array<AssessmentLevelOption> = [];

          data.forEach(option => {
            assessmentLevelOptionValues.push(
              new AssessmentLevelOption(
                option.id,
                option.description,
                option.otherTextRequired,
                null,
                null,
                false
              )
            );
          });

          return assessmentLevelOptionValues;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************************************
   * DONE: Post a Level Option
   * Error: Done
   *************************************************/
  postAssessmentLevelOption(
    reviewId: number,
    assessmentId: number,
    newLevelOption: AssessmentLevelOption
  ) {

    const body = {
      description: newLevelOption.description,
      otherTextRequired: newLevelOption.isOtherTextRequired
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/level-options",
        body
      )
      .pipe(
        map((result: any) => {
          return new AssessmentOption(result.id, result.option);
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************************************
   * DONE: Delete Assessment Method Option
   * Error: Done
   *************************************************/
  deleteAssessmentLevelOption(
    reviewId: number,
    assessmentId: number,
    optionId: number
  ) {

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/level-options/" +
        optionId
      )
      .pipe(
        map(result => {
          return true;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /************************************************
   * DONE: Get assessment level option examples
   * Error: Done
   ***************************************************/
  getAssessmentLevelOptionExamples(reviewId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/level-examples"
      )
      .pipe(
        map((data: any) => {
          let assessmentLevelExamples = [];

          data.forEach(example => {
            //comma separate
            const levels = example.levels.split(",");

            let assessmentLevelSet: Array<AssessmentLevelOption> = [];

            levels.forEach(level => {
              assessmentLevelSet.push(
                new AssessmentLevelOption(
                  null,
                  level.trim(), //trim whitespace
                  false,
                  null,
                  null,
                  false
                )
              );
            });

            assessmentLevelExamples.push(assessmentLevelSet);
          });

          return assessmentLevelExamples;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  ////////////////////////////////////// User Assessment Method ////////////////////////////////////

  /***********************************************************************
   * Get a list of assessment methods used by the course
   * Error: Done
   ***********************************************************************/
  getSelectedAssessmentMethods(courseId: number, assessmentFormatId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentFormatId +
        "/options/values"
      )
      .pipe(
        map((data: any) => {
          ////console.log(data);

          let assessmentSelectedMethods: Array<AssessmentSelectedMethod> = [];

          data.forEach(methodValue => {
            assessmentSelectedMethods.push(
              new AssessmentSelectedMethod(
                methodValue.id,
                methodValue.methodId,
                methodValue.option,
                methodValue.additionalDescription,
                methodValue.weight,
                methodValue.timingText,
                methodValue.levelText
              )
            );
          });

          return assessmentSelectedMethods;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Error: Done
   */
  addSelectedAssessmentMethodOption(
    courseId: number,
    assessmentId: number,
    selectedAssessmentMethod: AssessmentSelectedMethod
  ) {


    let body = {
      methodId: +selectedAssessmentMethod.methodId,
      additionalDescription: selectedAssessmentMethod.description,
      weight: +selectedAssessmentMethod.weight,
      levelText: selectedAssessmentMethod.levelText,
      timingText: selectedAssessmentMethod.timingText
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/options/values",
        body
      )
      .pipe(
        map((methodValue: any) => {
          ////console.log(methodValue);

          return new AssessmentSelectedMethod(
            methodValue.id,
            methodValue.methodId,
            methodValue.option,
            methodValue.additionalDescription,
            methodValue.weight,
            methodValue.timingText,
            methodValue.levelText
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /******************************************************************************
   * Update assessment method value (only method option, not levels/timings)
   * Error: Done
   *******************************************************************************/
  updateSelectedAssessmentMethodOption(
    courseId: number,
    assessmentId: number,
    selectedAssessmentMethod: AssessmentSelectedMethod
  ) {


    let body = {
      methodId: selectedAssessmentMethod.methodId,
      additionalDescription: selectedAssessmentMethod.description,
      weight: selectedAssessmentMethod.weight,
      levelText: selectedAssessmentMethod.levelText,
      timingText: selectedAssessmentMethod.timingText
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedAssessmentMethod.id,
        body
      )
      .pipe(
        map((methodValue: any) => {
          ////console.log(methodValue);
          return new AssessmentSelectedMethod(
            methodValue.id,
            methodValue.methodId,
            methodValue.option,
            methodValue.additionalDescription,
            methodValue.weight,
            methodValue.timingText,
            methodValue.levelText
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /************************************************
   * Get assessment method options without values
   * Error: Done
   **********************************************/

  getAssessmentMethodOptionsByCourseId(
    courseId: number,
    assessmentFormatId: number
  ) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessment/" +
        assessmentFormatId +
        "/options"
      )
      .pipe(
        map((data: any) => {
          let assessmentOptions: Array<AssessmentOption> = [];

          data.forEach(option => {
            assessmentOptions.push(
              new AssessmentOption(
                option.id,
                option.option,
                option.otherTextRequired ? true : false
              )
            );
          });

          return assessmentOptions;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  ////////////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  ////////////////////////////////////// User Assessment Timings ////////////////////////////////////

  /************************************************
   * DONE: Get assessment timing options without values
   * Error: Done
   ***************************************************/
  getAssessmentTimingOptionsByCourseId(
    courseId: number,
    assessmentFormatId: number
  ) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessment/" +
        assessmentFormatId +
        "/timing-options"
      )
      .pipe(
        map((data: any) => {
          let assessmentTimingOptionValues: Array<AssessmentTimingOption> = [];

          data.forEach(option => {
            assessmentTimingOptionValues.push(
              new AssessmentTimingOption(
                option.id,
                option.description,
                option.otherTextRequired,
                null,
                null,
                false
              )
            );
          });

          return assessmentTimingOptionValues;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************************************************
   * Get selected timing options for a selected assessment method
   * Error: Done
   *****************************************************************/
  getSelectedAssessmentTimings(
    courseId: number,
    assessmentId: number,
    selectedMethodId: number
  ) {


    //API returns array of timings - selectedTimingId is null if not selected, id number is selected.
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/timing-options"
      )
      .pipe(
        map((selectedTimings: Array<any>) => {
          ////console.log(selectedTimings);

          let selectedTimingOptions: Array<AssessmentTimingOptionSelected> = [];

          selectedTimings.forEach(selectedTiming => {
            selectedTimingOptions.push(
              new AssessmentTimingOptionSelected(
                selectedTiming.id,
                selectedTiming.timingOptionId,
                selectedTiming.otherText
              )
            );
          });

          return selectedTimings;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************************************************
   * Get all selected timing options
   * Error: Done
   *****************************************************************/
  getAssessmentTimingsForCourse(
    reviewId: number,
    assessmentId: number,
    courseId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/timings"
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************************************************
   * Add timing values
   * Error: Done
   *****************************************************************/
  addSelectedAssessmentTimingValues(
    courseId: number,
    assessmentId: number,
    selectedMethodId: number,
    selectedTimingValues: Array<AssessmentTimingOption>
  ) {


    let timingOptions = [];

    selectedTimingValues.forEach(timingValue => {
      timingOptions.push({
        selectedMethodId: selectedMethodId,
        timingOptionId: timingValue.id,
        otherText: timingValue.otherText
      });
    });

    ////console.log(timingOptions);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/timing-options",
        timingOptions
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return result;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  //delete all selected timing values associated with the method
  deleteSelectedAssessmentTimingValues(
    courseId: number,
    assessmentId: number,
    selectedMethodId: number
  ) {

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/timing-options"
      )
      .pipe(
        map(result => {
          return true;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************************************************
   * Add level values
   * Error: Done
   *****************************************************************/
  addSelectedAssessmentLevelOptions(
    courseId: number,
    assessmentId: number,
    selectedMethodId: number,
    levelOptions: Array<AssessmentLevelOption>
  ) {


    let levelOptionsArray = [];

    levelOptions.forEach(levelValue => {
      levelOptionsArray.push({
        selectedMethodId: selectedMethodId,
        levelOptionId: levelValue.id,
        otherText: levelValue.otherText
      });
    });

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/level-options",
        levelOptionsArray
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return result;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  ////////////////////////////////////// User Assessment Levels ////////////////////////////////////

  /************************************************
   * Get assessment method options without values
   * Error: Done
   **********************************************/

  getAssessmentLevelOptionsByCourseId(
    courseId: number,
    assessmentFormatId: number
  ) {

    //TODO: API Should Check if this user is authorized to access this data

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessment/" +
        assessmentFormatId +
        "/level-options"
      )
      .pipe(
        map((data: any) => {
          //console.log(data);

          let assessmentLevelOptions: Array<AssessmentLevelOption> = [];

          data.forEach(option => {
            assessmentLevelOptions.push(
              new AssessmentLevelOption(
                option.id,
                option.description,
                option.otherTextRequired,
                null,
                null,
                false
              )
            );
          });

          return assessmentLevelOptions;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************************************
   * Get levels array with selected/not selected value
   * Error: Done
   ***************************************************/
  getSelectedAssessmentLevels(
    courseId: number,
    assessmentId: number,
    selectedMethodId: number
  ) {


    //API returns array of levels - selectedLevelId is null, if not selected id number is selected.
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/level-options"
      )
      .pipe(
        map((selectedLevels: Array<any>) => {
          ////console.log(selectedLevels);

          let selectedLevelOptions: Array<AssessmentLevelOptionSelected> = [];

          selectedLevels.forEach(selectedLevel => {
            selectedLevelOptions.push(
              new AssessmentLevelOptionSelected(
                selectedLevel.id,
                selectedLevel.levelOptionId,
                selectedLevel.otherText
              )
            );
          });

          return selectedLevelOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************************************
   * Get levels array with selected/not selected value
   * Error: Done
   ***************************************************/
  getSelectedAssessmentLevelText(
    courseId: number,
    assessmentId,
    selectedMethodId
  ) {

    //API returns array of levels - selectedLevelId is null, if not selected id number is selected.
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/levels-text"
      )
      .pipe(
        map((selectedLevelTextData: any) => {
          let selectedLevelsText: Array<SelectedAssessmentLevelText> = [];

          return selectedLevelTextData
            ? new SelectedAssessmentLevelText(
              selectedLevelTextData.id,
              selectedLevelTextData.text
            )
            : null;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************************
   * Delete all assessment level values method
   * Error: Done
   ***************************************/
  deleteSelectedAssessmentLevelValues(
    courseId: number,
    assessmentId: number,
    selectedMethodId: number
  ) {


    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        selectedMethodId +
        "/level-options"
      )
      .pipe(
        map(result => {
          return true;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }
  /****************************
   * Delete assessment method
   * Error: Done
   *****************************/
  deleteAssessmentMethodValue(
    courseId: number,
    assessmentId: number,
    assessmentMethodToDelete: AssessmentSelectedMethod
  ) {

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/methods/" +
        assessmentMethodToDelete.id
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  /////////////// Assessment methods for course TEXT //////////////////////

  /******************************
   * Get assessment Methods Text
   *****************************/
  getAssessmentMethodsTextForCourse(
    courseId: number,
    assessmentFormatId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentFormatId +
        "/text-values"
      )
      .pipe(
        map((data: any) => {
          //console.log(data);

          let assessmentSelectedMethods: Array<AssessmentSelectedMethod> = [];

          data.forEach(methodValue => {
            assessmentSelectedMethods.push(
              new AssessmentSelectedMethod(
                methodValue.id,
                // methodValue.methodId,
                null,
                // methodValue.option,
                methodValue.assessmentName,
                methodValue.additionalDescription,
                methodValue.weight,
                methodValue.timingText,
                methodValue.levelText
              )
            );
          });

          //console.log(assessmentSelectedMethods);

          return assessmentSelectedMethods;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************
   * Add assessment Text Method
   *****************************/
  addAssessmentMethodText(
    courseId: number,
    assessmentId: number,
    selectedAssessmentMethod: AssessmentSelectedMethod
  ) {
    let body = {
      methodId: +selectedAssessmentMethod.methodId,
      assessmentName: selectedAssessmentMethod.methodName,
      additionalDescription: selectedAssessmentMethod.description,
      weight: +selectedAssessmentMethod.weight,
      levelText: selectedAssessmentMethod.levelText,
      timingText: selectedAssessmentMethod.timingText
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/text-values",
        body
      )
      .pipe(
        map((methodValue: any) => {
          ////console.log(methodValue);

          return new AssessmentSelectedMethod(
            methodValue.id,
            null,
            methodValue.assessmentName,
            methodValue.additionalDescription,
            methodValue.weight,
            methodValue.timingText,
            methodValue.levelText
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************
   * Update assessment Text Method
   *****************************/
  updateSelectedAssessmentMethodText(
    courseId: number,
    assessmentId: number,
    selectedAssessmentMethod: AssessmentSelectedMethod
  ) {
    let body = {
      methodId: selectedAssessmentMethod.methodId,
      assessmentName: selectedAssessmentMethod.methodName,
      additionalDescription: selectedAssessmentMethod.description,
      weight: selectedAssessmentMethod.weight,
      levelText: selectedAssessmentMethod.levelText,
      timingText: selectedAssessmentMethod.timingText
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/text-values/" +
        selectedAssessmentMethod.id,
        body
      )
      .pipe(
        map((methodValue: any) => {
          ////console.log(methodValue);
          return new AssessmentSelectedMethod(
            methodValue.id,
            null,
            methodValue.assessmentName,
            methodValue.additionalDescription,
            methodValue.weight,
            methodValue.timingText,
            methodValue.levelText
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************
   * Delete assessment Text Method
   *****************************/
  deleteAssessmentMethodTextValue(
    courseId: number,
    assessmentId: number,
    assessmentMethodToDelete: AssessmentSelectedMethod
  ) {


    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/text-values/" +
        assessmentMethodToDelete.id
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  ////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  /////////////// Assessment methods for course outcome //////////////////////

  getAssessmentMethodIdsSelectedByCourseOutcome(
    courseId: number,
    courseOutcomeId: number,
    type: string
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcomes/" +
        courseOutcomeId +
        "/assessment-methods"
      )
      .pipe(
        map((selectedAssessments: Array<any>) => {
          //console.log(selectedAssessments);

          let selectedAssessmentIds = [];

          if (type == "list") {
            selectedAssessments.forEach(selectedAssessment => {
              selectedAssessmentIds.push(
                selectedAssessment.selectedAssessmentId
              );
            });
          } else if (type == "text") {
            selectedAssessments.forEach(selectedAssessment => {
              selectedAssessmentIds.push(
                selectedAssessment.selectedAssessmentTextId
              );
            });
          }

          //console.log(selectedAssessmentIds);

          return selectedAssessmentIds;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************************************************
   * Get all assessment method ids selected by all course outcomes in the course
   * Error: Done
   *********************************************************************************/
  getAllSelectedAssessmentMethodOptionsByCourseOutcomes(
    courseId: number,
    type: string
  ) {
    // Route::get('{courseId}/course-outcome-assessment-method', 'CourseOutcomeController@getCourseOutcomeAssessmentMethodIds');

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcome-assessment-methods"
      )
      .pipe(
        map(
          (selectedAssessmentIds: Array<any>) => {
            //console.log(selectedAssessmentIds);

            // let selectedAssessmentIdsArray: Array<any> = [];

            return selectedAssessmentIds;
          },
          catchError((error: any) => {
            //console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
      )
      .toPromise();
  }

  /********************************************************************************
   * Update assessment method ids selected by course outcome
   *********************************************************************************/
  updateSelectedAssessmentMethodOptionByCourseOutcome(
    courseId: number,
    selectedAssessmentMethodIds: Array<any>,
    type: string // text or list
  ) {
    let assessmentMethodIds = [];

    if (type == "list") {
      selectedAssessmentMethodIds.forEach(selectedAssessmentMethod => {
        assessmentMethodIds.push({
          courseId: courseId,
          courseOutcomeId: selectedAssessmentMethod.courseOutcomeId,
          selectedAssessmentId: selectedAssessmentMethod.selectedAssessmentId
        });
      });
    } else if (type == "text") {
      selectedAssessmentMethodIds.forEach(selectedAssessmentMethod => {
        assessmentMethodIds.push({
          courseId: courseId,
          courseOutcomeId: selectedAssessmentMethod.courseOutcomeId,
          selectedAssessmentTextId:
            selectedAssessmentMethod.selectedAssessmentId
        });
      });
    }

    ////console.log(assessmentMethodIds);

    if (type == "list") {
      return this.httpClient
        .put(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/course-outcome-assessment-methods",
          assessmentMethodIds
        )
        .pipe()
        .toPromise();
    } else if (type == "text") {
      return this.httpClient
        .put(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/course-outcome-assessment-methods",
          assessmentMethodIds
        )
        .pipe()
        .toPromise();
    }
  }

  //Util functions - no API calls

  /***************************************************
   * Returns selectedAssessmentMethodIdsArray[courseOutcomeId][selectedAssessmentMethodId] = true/false
   ***************************************************/
  getAllSelectedAssessmentIdsByCourseOutcomes(
    courseOutcomes: Array<CourseOutcome>,
    assessmentMethodsForCourse: Array<AssessmentSelectedMethod>,
    selectedAssessmentIdsData: Array<any>, //[{id, courseOutcomeId, selectedAssessmentId, selectedAssessmentTextId, courseId}]
    assessmentFormat: string
  ) {
    // //format assessmentMethodsForCourseArray[AssessmentSelectedMethodId] = AssessmentSelectedMethod;
    // let assessmentMethodsForCourseArray: Array<any> = [];

    // //convetr to the format above
    // assessmentMethodsForCourse.forEach(assessmentMethodForCourse => {
    //   assessmentMethodsForCourseArray[
    //     assessmentMethodForCourse.id
    //   ] = assessmentMethodForCourse;
    // });

    //create 2D array and initialize with selectedAssessmentIdsArray[courseOutcomeId][selectedAssessmentMethodId] = false
    let selectedAssessmentIdsArray: Array<any> = [];
    courseOutcomes.forEach(courseOutcome => {
      selectedAssessmentIdsArray[courseOutcome.id] = [];

      assessmentMethodsForCourse.forEach(assessmentMethodForCourse => {
        selectedAssessmentIdsArray[courseOutcome.id][
          assessmentMethodForCourse.id
        ] = false;
      });
    });

    //get selected assessment IDs
    let selectedAssessmentIds: Array<{
      courseOutcomeId: number;
      selectedAssessmentId: number;
    }> = [];

    selectedAssessmentIdsData.forEach(selectedAssessmentIdData => {
      if (assessmentFormat == "text") {
        selectedAssessmentIds.push({
          courseOutcomeId: selectedAssessmentIdData.courseOutcomeId,
          selectedAssessmentId:
            selectedAssessmentIdData.selectedAssessmentTextId
        });
      } else if (assessmentFormat == "list") {
        selectedAssessmentIds.push({
          courseOutcomeId: selectedAssessmentIdData.courseOutcomeId,
          selectedAssessmentId: selectedAssessmentIdData.selectedAssessmentId
        });
      }
    });

    //convert to selectedAssessmentMethodIdsArray[courseOutcomeId][selectedAssessmentMethodId] = true
    selectedAssessmentIds.forEach(selectedAssessmentId => {
      if (selectedAssessmentIdsArray[selectedAssessmentId.courseOutcomeId]) {
        if (
          selectedAssessmentIdsArray[selectedAssessmentId.courseOutcomeId][
          selectedAssessmentId.selectedAssessmentId
          ] != undefined
        ) {
          selectedAssessmentIdsArray[selectedAssessmentId.courseOutcomeId][
            selectedAssessmentId.selectedAssessmentId
          ] = true;
        }
      }
    });

    return selectedAssessmentIdsArray;
  }

  /********************************************************
   * Assessment count for each PLO
   *********************************************************/
  getAssessmentOutcomeCountForPLO(
    reviewId: number,
    assessmentId: number,
    ploId: number,
    courseIds: Array<number>
  ) {
    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/outcome-frequency/plos/" +
        ploId,
        courseIds
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();

    //{tlaId}/frequency/plos/{ploId}
  }

  /***************************************************
   * Get selected assessment levels for charts
   ***************************************************/

  getSelectedAssessmentLevelCountForReview(
    reviewId: number,
    assessmentId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/assessment/" +
        assessmentId +
        "/selected-level-options"
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  getAssessmentLevelsForCourse(
    reviewId: number,
    assessmentId: number,
    courseId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/" +
        courseId +
        "/assessments/" +
        assessmentId +
        "/levels"
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }
}
