import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from "../../config";

import { Observable, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";

//service
import { UtilService } from "../services/util.service";

//models
import { User } from "app/core/models/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private utilService: UtilService
  ) {}

  /********************************************************************************
   * Submit username and password and get access token back from API server
   *********************************************************************************/
  login(username: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json"
      })
    };

    let body = {
      username: username,
      password: password
    };

    return this.httpClient
      .post(Config.BASE_API_URL_V1 + "/login", body, httpOptions)
      .pipe(
        map(accessToken => {
          //console.log(accessToken);
          return accessToken;
        }),
        catchError(error => {
          // //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      );
  }

  /********************************************************************************
   * Get user object from token
   *********************************************************************************/
  getUser() {
    //access token is sent by interceptor

    const httpOptions = {
      headers: new HttpHeaders({
        // 'Authorization': 'Bearer ' + accessToken,
        "Content-Type": "application/json",
        Accept: "application/json"
      })
    };


    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/user", httpOptions)
      .pipe(
        map((user: any) => {
          // console.log(user);

          return new User(user.id, user.name, user.email, user.email_verified_at);
        }),
        catchError(error => {
          console.log(error);

          //altenatively refreshToken here??

          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  // refreshToken(){

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     })
  //   };

  //   //console.log(localStorage.getItem('refresh_token'));

  //   let body = {
  //     'refresh_token': localStorage.getItem('refresh_token')
  //   }

  //   return this.httpClient
  //     .post(Config.BASE_API_URL_V1 + "/login/refresh", body, httpOptions)
  //     .map(accessToken => {
  //       //console.log(accessToken);
  //       return accessToken;
  //     })
  //     .catch(error => {
  //       //console.log(error);
  //       return throwError(this.utilService.handleApiError(error));
  //     });

  // }

  // getAccessToken() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     })
  //   };

  //   let postData = {
  //     grant_type: "password",
  //     client_id: 2,
  //     client_secret: "8QYlDTvt7xlOhRg6wLCraS008XagZvxlIZsndAyQ",
  //     username: "kmsaito@ucalgary.ca",
  //     password: "password",
  //     scope: ""
  //   };

  //   //console.log(JSON.stringify(postData));

  //   return this.httpClient
  //     .post(
  //       Config.BASE_API_URL_ROOT + "/oauth/token",
  //       JSON.stringify(postData),
  //       httpOptions
  //     )
  //     .map(accessToken => {
  //       return accessToken;
  //     })
  //     .catch(error => {
  //       //console.log(error);
  //       return throwError(this.utilService.handleApiError(error));
  //     });
  // }

  // login() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     })
  //   };

  //   let loginData = {
  //     username: "kmsaito@ucalgary.ca",
  //     password: "password"
  //   };

  //   return this.httpClient
  //     .post(
  //       Config.BASE_API_URL_ROOT + "/login",
  //       JSON.stringify(loginData),
  //       httpOptions
  //     )
  //     .map(() => {});
  // }

  getUsers(accessToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        // Authorization: "Bearer " + accessToken,
        Accept: "application/json"
      })
    };

    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/users", httpOptions)
      .pipe(
        map(users => {
          return users;
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      );
  }


  isSystemAdmin(userId: number){
    return userId == 1 ? true: false;
  }

  // setUser(user: User) {
  //   //console.log("Setting user");
  //   //console.log(user);
  //   this._currentUser.next(user);
  // }
}
