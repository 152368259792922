export class CustomError extends Error {
  private _title: string;
  private _status: string;
  private _url: string;

  constructor(status: string, title: string, description: string, url: string) {
    super(description);

    //restore prototype chain
    //see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype);

    this._status = status;
    this._title = title;
    this._url = url;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  // public get description(): string {
  //   return this._description;
  // }

  // public set description(value: string) {
  //   this._description = value;
  // }

  /**
   * Getter status
   * @return {string}
   */
  public get status(): string {
    return this._status;
  }

  /**
   * Setter status
   * @param {string} value
   */
  public set status(value: string) {
    this._status = value;
  }

  /**
   * Getter url
   * @return {string}
   */
  public get url(): string {
    return this._url;
  }

  /**
   * Setter url
   * @param {string} value
   */
  public set url(value: string) {
    this._url = value;
  }
}
