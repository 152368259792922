import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DimmerService {

  private _showDimmer = new Subject<boolean>();
  showDimmer = this._showDimmer.asObservable();

  constructor() {
    this._showDimmer.next(true);
  }

  dimWindow(showDimmer: boolean) {
    this._showDimmer.next(showDimmer);
  }



}
