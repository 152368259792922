import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

//Services
import { QuestionService } from "app/core/services/question.service";
import { DimmerService } from "app/core/services/dimmer.service";

//Models
import { Question } from "app/core/models/question";
import { QuestionAnswerOption } from "app/core/models/question-answer-option";
import { QuestionTextAnswer } from "app/core/models/question-text-answer";
import { QuestionSelectedOption } from "app/core/models/question-selected-option";
import { Loading } from "app/core/models/loading";
import { CustomError } from "app/core/models/custom-error";

//Libraries
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-additional-questions",
  templateUrl: "./additional-questions.component.html",
  styleUrls: ["./additional-questions.component.scss"]
})
export class AdditionalQuestionsComponent implements OnInit {
  // @Input() questions: Array<Question>;
  @Input()
  reviewId: number;
  @Input()
  relatingTo: string;
  @Input()
  courseId: number;
  @Input()
  outcomeId: number;
  @Input()
  editable: boolean;
  @Input()
  questions: Array<Question>;

  // @Output()
  // updateQuestions: EventEmitter<Array<Question>> = new EventEmitter<
  //   Array<Question>
  // >();

  // questions: Array<Question> = [];

  //flags
  loading: Loading;
  shouldShowAnswerForm: boolean = false;

  //for form
  questionForForm: Question;

  loadError: CustomError;

  constructor(
    private questionService: QuestionService,
    private dimmerService: DimmerService
  ) { }

  getQuestions() {
    return this.questionService
      .getQuestionsByCourseId(this.courseId, this.relatingTo)
      .then(
        (questions: Array<Question>) => questions,
        (error: CustomError) => {
          throw error;
        }
      );
  }

  getTextAnswerForCourse(
    reviewId: number,
    courseId: number,
    questionId: number
  ) {
    return this.questionService
      .getTextAnswerForCourse(courseId, questionId)
      .then(
        (textAnswer: QuestionTextAnswer) => textAnswer,
        error => {
          throw error;
        }
      );
  }

  // getTextAnswerForOutcome(
  //   courseId: number,
  //   outcomeId: number,
  //   questionId: number
  // ) {
  //   return this.questionService
  //     .getTextAnswerForOutcome(courseId, outcomeId, questionId)
  //     .then(
  //       (textAnswer: QuestionTextAnswer) => textAnswer,
  //       error => {
  //         throw error;
  //       }
  //     );
  // }

  getAnswerOptions(questionId: number) {
    return this.questionService
      .getAnswerOptionsByCourseId(this.courseId, questionId)
      .then(
        (answerOptions: Array<QuestionAnswerOption>) => answerOptions,
        error => {
          throw error;
        }
      );
  }

  getSelectedAnswerOptionsForCourse(courseId: number, questionId: number) {
    return this.questionService
      .getSelectedAnswerOptionsForCourseByCourseId(courseId, questionId)
      .then(
        (selectedAnswerOptions: Array<QuestionSelectedOption>) => {
          console.log(selectedAnswerOptions);
          return selectedAnswerOptions;
        },
        error => {
          throw error;
        }
      );
  }

  getSelectedAnswerOptionsForOutcome(outcomeId: number, questionId: number) {
    return this.questionService
      .getSelectedAnswerOptionsForOutcome(this.reviewId, outcomeId, questionId)
      .then(
        (selectedAnswerOptions: Array<QuestionSelectedOption>) =>
          selectedAnswerOptions,
        error => {
          throw error;
        }
      );
  }

  async ngOnInit() {

    let questions: Array<Question> = [];

    this.loading = new Loading(true, "Loading...");

    try {

      for (const question of this.questions) {
        if (question.format == "text") {
          if (this.relatingTo == "course") {
            //console.log(this.reviewId, this.courseId, question.id);

            question.textAnswer = await this.getTextAnswerForCourse(
              this.reviewId,
              this.courseId,
              question.id
            );
          } else if (this.relatingTo == "outcome") {
            // question.textAnswer = await this.getTextAnswerForOutcome(
            //   this.courseId,
            //   this.outcomeId,
            //   question.id
            // );
          }

          //console.log(question.textAnswer);
        } else if (
          question.format == "checkbox" ||
          question.format == "radio"
        ) {
          question.options = await this.getAnswerOptions(question.id);

          let selectedOptions: Array<QuestionSelectedOption> = [];

          if (this.relatingTo == "course") {
            //get currently selected options

            selectedOptions = await this.getSelectedAnswerOptionsForCourse(
              this.courseId,
              question.id
            );

            // console.log(selectedOptions);
          } else if (this.relatingTo == "outcome") {
            //get currently selected options

            selectedOptions = await this.getSelectedAnswerOptionsForOutcome(
              this.outcomeId,
              question.id
            );
          }

          //console.log(selectedOptions);

          //convert to array
          let selectedOptionsArray: Array<QuestionSelectedOption> = [];

          selectedOptions.forEach(selectedOption => {
            //console.log(selectedOption);

            selectedOptionsArray[
              selectedOption.selectedOptionId
            ] = selectedOption;
          });

          // console.log(selectedOptionsArray);

          //set isSelected
          question.options.forEach(option => {
            if (selectedOptionsArray[option.id]) {
              option.isSelected = true;
              if (option.otherTextRequired) {
                option.otherText =
                  selectedOptionsArray[option.id].selectedOptionText;
              }
            } else {
              option.isSelected = false;
            }
          });
        }
      }

      // this.questions = questions;

      // this.updateQuestions.emit(questions);
    } catch (error) {
      console.log(error);

      this.loadError = error;

    } finally {
      this.loading.isLoading = false;
    }
  }
}
