import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-saving-status',
  templateUrl: './saving-status.component.html',
  styleUrls: ['./saving-status.component.scss']
})
export class SavingStatusComponent implements OnInit {

  @Input()
  status: string;

  @Input()
  title: string;

  @Input()
  text: string;

  @Input()
  proceedButtonText: string;

  @Input()
  canceButtonText: string;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  closeSavingStatus: EventEmitter<any> = new EventEmitter();

  @Output()
  proceed: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }


  cancelClicked() {
    this.cancel.emit();
  }

  proceedClicked() {
    this.proceed.emit();
  }

  close(result: string) {
    if (result == 'success') {
      this.closeSavingStatus.emit('success');
    }
    else if (result == 'error') {
      this.closeSavingStatus.emit('error');
    }
  }


}
