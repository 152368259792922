import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { AssessmentFormat } from "app/core/models/assessment-format";
import { AssessmentTimingOption } from "app/core/models/assessment-timing-option";
import { AssessmentTimingOptionSelected } from "app/core/models/assessment-timing-option-selected";
import { AssessmentLevelOption } from "app/core/models/assessment-level-option";
import { AssessmentSelectedMethod } from "app/core/models/assessment-selected-method";
import { CustomError } from "app/core/models/custom-error";

//Service
import { AssessmentService } from "app/core/services/assessment.service";


import { finalize } from "rxjs/operators";
import { Loading } from "app/core/models/loading";


@Component({
  selector: "app-user-step-summary-assessment",
  templateUrl: "./user-step-summary-assessment.component.html",
  styleUrls: ["./user-step-summary-assessment.component.scss"]
})
export class UserStepSummaryAssessmentComponent implements OnInit {
  @Input()
  assessmentFormat: AssessmentFormat;
  @Input()
  reviewId: number;
  @Input()
  courseId: number;

  // @Input()
  // selectedAssessmentMethods: Array<any>;

  // @Input()
  // totalWeight: number;

  @Output()
  sectionLoaded: EventEmitter<any> = new EventEmitter();

  selectedAssessmentMethods: Array<AssessmentSelectedMethod> = [];
  totalWeight: number = 0;
  loading: Loading;


  loadError: CustomError;

  constructor(private assessmentService: AssessmentService) {}

  async ngOnInit() {
    //console.log(this.assessmentFormat);

    this.loading = new Loading(true, "Loading student assessment methods...");

    try {
      if (this.assessmentFormat.isEnabled) {
        if (this.assessmentFormat.format == "list") {
          //get selected assessment methods
          this.selectedAssessmentMethods = await this.assessmentService.getSelectedAssessmentMethods(
            this.courseId,
            this.assessmentFormat.id
          );
        } else if (this.assessmentFormat.format == "text") {
          this.selectedAssessmentMethods = await this.assessmentService.getAssessmentMethodsTextForCourse(
            this.courseId,
            this.assessmentFormat.id
          );
        }
      }

      // console.log(this.selectedAssessmentMethods);

      if (this.assessmentFormat.isWeightRequired) {
        this.selectedAssessmentMethods.forEach(
          (assessmentMethod: AssessmentSelectedMethod) => {
            this.totalWeight += assessmentMethod.weight;
          }
        );
      }

      //if time is required, first load all options and then load selected options
      if (this.assessmentFormat.isTimingRequired) {
        let timingOptions = await this.assessmentService.getAssessmentTimingOptionsByCourseId(
          this.courseId,
          this.assessmentFormat.id
        );

        let timingOptionsArray: Array<AssessmentTimingOption> = [];

        timingOptions.forEach(timingOption => {
          timingOptionsArray[timingOption.id] = timingOption;
        });

        // this.selectedAssessmentMethods.forEach(

        for (let selectedMethod of this.selectedAssessmentMethods) {
          let selectedTimings: Array<
            AssessmentTimingOptionSelected
          > = await this.assessmentService.getSelectedAssessmentTimings(
            this.courseId,
            this.assessmentFormat.id,
            selectedMethod.id
          );

          let selectedTimingArray: Array<AssessmentTimingOptionSelected> = [];

          selectedTimings.forEach(selectedTiming => {
            let selectedTimingOption = new AssessmentTimingOption(
              timingOptionsArray[selectedTiming.timingOptionId].id,
              timingOptionsArray[selectedTiming.timingOptionId].description,
              timingOptionsArray[
                selectedTiming.timingOptionId
              ].isOtherTextRequired,
              null,
              selectedTiming.otherText,
              null
            );

            selectedMethod.timings.push(
              // timingOptionsArray[selectedTiming.timingOptionId]
              selectedTimingOption
            );
          });
        }
      }

      //if level is required, load them
      if (this.assessmentFormat.isLevelRequired) {
        if (this.assessmentFormat.levelFormat == "text") {
        } else if (this.assessmentFormat.levelFormat == "checkbox") {
          let levelOptions = await this.assessmentService.getAssessmentLevelOptionsByCourseId(
            this.courseId,
            this.assessmentFormat.id
          );

          // console.log(levelOptions);

          let levelOptionsArray: Array<AssessmentLevelOption> = [];

          levelOptions.forEach(levelOption => {
            levelOptionsArray[levelOption.id] = levelOption;
          });

          // console.log(levelOptionsArray);
// 
          for (let selectedMethod of this.selectedAssessmentMethods) {
            let selectedLevels = await this.assessmentService.getSelectedAssessmentLevels(
              this.courseId,
              this.assessmentFormat.id,
              selectedMethod.id
            );

            selectedLevels.forEach(selectedLevel => {
              // console.log(selectedLevel);

              if (levelOptionsArray[selectedLevel.levelOptionId]) {
                let selectedLevelOption: AssessmentLevelOption = new AssessmentLevelOption(
                  levelOptionsArray[selectedLevel.levelOptionId].id,
                  levelOptionsArray[selectedLevel.levelOptionId].description,
                  levelOptionsArray[
                    selectedLevel.levelOptionId
                  ].isOtherTextRequired,
                  null,
                  selectedLevel.otherText,
                  false
                );
                selectedMethod.levels.push(
                  // levelOptionsArray[selectedLevel.levelOptionId]
                  selectedLevelOption
                );
              }
            });
          }
        }
      }

      // this.sectionLoaded.emit();
    } catch (error) {
      console.log(error);
      this.loadError = error;
    } finally {
      this.loading.isLoading = false;
    }
  }
}
