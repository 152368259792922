export class Loading {
  private _isLoading: boolean;
  private _message: string;

  constructor(isLoading: boolean = false, message: string = null) {
    this._isLoading = isLoading;
    this._message = message;
  }

  /**
   * Getter isLoading
   * @return {boolean}
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Setter isLoading
   * @param {boolean} value
   */
  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  /**
   * Getter message
   * @return {string}
   */
  public get message(): string {
    return this._message;
  }

  /**
   * Setter message
   * @param {string} value
   */
  public set message(value: string) {
    this._message = value;
  }
}
