<div *ngIf="loading.isLoading;else loaded">
  <app-loading [loadingText]="loading.message"></app-loading>
</div>


<ng-template #loaded>

  <app-load-error *ngIf="loadError; else noErrors" [loadError]="loadError">
  </app-load-error>


  <ng-template #noErrors>

    <table class="ui table celled course-outcome-table" *ngIf="courseOutcomes && courseOutcomes.length > 0">


      <tr class="header-row">
        <td></td>
        <td>Course Outcome</td>
        <td *ngIf="assessmentFormat.isEnabled && assessmentFormat.requiredByCourseOutcome"
          [ngClass]="{'assessment-enabled': assessmentFormat.requiredByCourseOutcome, 'tla-enabled': tlaFormat.requiredByCourseOutcome}">
          Student Assessment Methods</td>
        <td *ngIf="tlaFormat.isEnabled && tlaFormat.requiredByCourseOutcome"
          [ngClass]="{'assessment-enabled': assessmentFormat.requiredByCourseOutcome, 'tla-enabled': tlaFormat.requiredByCourseOutcome}">
          Teaching and Learning Activities</td>
      </tr>

      <ng-container *ngFor="let courseOutcome of courseOutcomes; let i = index;">

        <tr class="course-outcome" id="outcome-{{courseOutcome.id}}">
          <td class="course-outcome-index">
            {{i+1}}
          </td>
          <td>
            <div class="course-outcome-title">{{courseOutcome.description}}</div>
          </td>

          <td *ngIf="assessmentFormat.isEnabled && assessmentFormat.requiredByCourseOutcome">
            <div *ngFor="let assessmentMethodForCourse of assessmentMethodsForCourse">
              <div class="assessment-entry"
                *ngIf="selectedAssessmentIdsArray[courseOutcome.id] && selectedAssessmentIdsArray[courseOutcome.id][assessmentMethodForCourse.id]">
                &bull; {{assessmentMethodForCourse.methodName}}<span *ngIf="assessmentMethodForCourse.description">:
                  {{assessmentMethodForCourse.description}}</span>
              </div>
            </div>
          </td>


          <td *ngIf="tlaFormat.isEnabled && tlaFormat.requiredByCourseOutcome">
            <div *ngFor="let  tlaOptionForCourse of  tlaOptionsForCourse">
              <div class="tla-entry"
                *ngIf="selectedTlaIdsArray[courseOutcome.id] && selectedTlaIdsArray[courseOutcome.id][tlaOptionForCourse.selectionId]  ">
                &bull; {{tlaOptionForCourse.description}}<span *ngIf="tlaOptionForCourse.additionalText">:
                  {{tlaOptionForCourse.additionalText}}</span>
              </div>
            </div>
          </td>



          <td class="button-col" *ngIf="editable">
            <button class="ui button basic mini" (click)="editCourseOutcomeForm(courseOutcome)">Edit</button>
            <button class="ui button basic mini delete-button"
              (click)="deleteCourseOutcome(courseOutcome)">Delete</button>
          </td>

        </tr>
      </ng-container>

    </table>

  </ng-template>

</ng-template>