export class QuestionSelectedOption {
  private _selectedOptionId: number;
  private _selectedOptionText: string;
  private _courseId: number;
  private _outcomeId: number;
  private _questionId: number;

  constructor(
    selectedOptionId: number,
    selectedOptionText: string,
    courseId: number,
    outcomeId: number,
    questionId: number
  ) {
    this._selectedOptionId = selectedOptionId;
    this._selectedOptionText = selectedOptionText;
    this._courseId = courseId;
    this._outcomeId = outcomeId;
    this._questionId = questionId;
  }

  /**
   * Getter selectedOptionId
   * @return {number}
   */
  public get selectedOptionId(): number {
    return this._selectedOptionId;
  }

  /**
   * Setter selectedOptionId
   * @param {number} value
   */
  public set selectedOptionId(value: number) {
    this._selectedOptionId = value;
  }

  /**
   * Getter selectedOptionText
   * @return {string}
   */
  public get selectedOptionText(): string {
    return this._selectedOptionText;
  }

  /**
   * Setter selectedOptionText
   * @param {string} value
   */
  public set selectedOptionText(value: string) {
    this._selectedOptionText = value;
  }

  /**
   * Getter courseId
   * @return {number}
   */
  public get courseId(): number {
    return this._courseId;
  }

  /**
   * Setter courseId
   * @param {number} value
   */
  public set courseId(value: number) {
    this._courseId = value;
  }

  /**
   * Getter outcomeId
   * @return {number}
   */
  public get outcomeId(): number {
    return this._outcomeId;
  }

  /**
   * Setter outcomeId
   * @param {number} value
   */
  public set outcomeId(value: number) {
    this._outcomeId = value;
  }

  /**
   * Getter questionId
   * @return {number}
   */
  public get questionId(): number {
    return this._questionId;
  }

  /**
   * Setter questionId
   * @param {number} value
   */
  public set questionId(value: number) {
    this._questionId = value;
  }
}
