export class AnalysisQuestion {

    private _id: number;
    private _question: string;
    private _deleted: boolean;
    private _selected: boolean;

    constructor(
        id: number,
        question: string
    ){
        this._id = id;
        this._question = question;
        this._deleted = false;
        this._selected = false;
    }


    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Getter question
     * @return {string}
     */
	public get question(): string {
		return this._question;
	}

    /**
     * Setter question
     * @param {string} value
     */
	public set question(value: string) {
		this._question = value;
	}


    



    /**
     * Getter deleted
     * @return {boolean}
     */
	public get deleted(): boolean {
		return this._deleted;
	}

    /**
     * Setter deleted
     * @param {boolean} value
     */
	public set deleted(value: boolean) {
		this._deleted = value;
	}



    /**
     * Getter selected
     * @return {boolean}
     */
	public get selected(): boolean {
		return this._selected;
	}

    /**
     * Setter selected
     * @param {boolean} value
     */
	public set selected(value: boolean) {
		this._selected = value;
	}


}
