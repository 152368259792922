<div *ngIf="loading.isLoading;else loaded">
  <app-loading [loadingText]="loading.message"></app-loading>
</div>


<ng-template #loaded>

  <app-load-error *ngIf="loadError; else noErrors" [loadError]="loadError">
  </app-load-error>

  <ng-template #noErrors>
    <div class="subsection" *ngIf="mappedValueLoaded">

      <div class="table-container">

        <div class="inner-div">

          <table *ngIf="programOutcomeCategories.length > 0" class="outcome-map-table">

            <!--- PLO row -->
            <tr>

              <td class="course-outcome-cell" rowspan="3">
                <div class="header-cell">Course outcomes</div>
              </td>

              <td [colSpan]="programOutcomes.length" class="plo-title-cell">Program-level learning outcomes</td>

            </tr>

            <!-- category row -->
            <tr>
              <ng-container *ngFor="let category of programOutcomesByCategory">
                <td *ngIf="category.outcomes.length > 0" [colSpan]="category.outcomes.length" class="plo-title-cell">
                  {{category.category.name}}
                </td>
              </ng-container>
              <td *ngIf="programOutcomesWithoutCategory.length > 0" [colSpan]="programOutcomesWithoutCategory.length"
                class="plo-title-cell">
                No Category
              </td>
            </tr>

            <!-- program outcome category row -->

            <tr>

              <ng-container *ngFor="let category of programOutcomesByCategory">
                <td *ngFor="let plo of category.outcomes" class="plo-title-cell">
                  {{plo.index}}.<span *ngIf="programOutcomes.length < 9">{{ plo.title }}</span>
                </td>
              </ng-container>

              <td *ngFor="let plo of programOutcomesWithoutCategory" class="plo-title-cell">
                {{plo.index}}.<span *ngIf="programOutcomes.length < 9">{{ plo.title }}</span>
              </td>

            </tr>

            <!-- course outcomes row -->

            <tr *ngFor="let courseOutcome of courseOutcomes; let i = index">
              <td class="course-outcome-cell">{{i+1}}. {{ courseOutcome.description }}</td>

              <ng-container *ngFor="let category of programOutcomesByCategory">

                <ng-container *ngFor="let programOutcome of category.outcomes">
                  <td *ngIf="mappedValue[courseOutcome.id][programOutcome.id].id"
                    [ngStyle]="{'background-color': mappedValue[courseOutcome.id][programOutcome.id].bgColor, 'color': mappedValue[courseOutcome.id][programOutcome.id].fontColor}">
                    <div class="tooltip level" *ngIf="mappedValue[courseOutcome.id][programOutcome.id].id != 0">
                      {{mappedValue[courseOutcome.id][programOutcome.id].abbreviation}}
                      <span class="tooltip-text top">{{ mappedValue[courseOutcome.id][programOutcome.id].name }}</span>
                    </div>
                  </td>
                  <td *ngIf="!mappedValue[courseOutcome.id][programOutcome.id].id"></td>
                </ng-container>

              </ng-container>

              <ng-container *ngFor="let programOutcome of programOutcomesWithoutCategory">
                <td *ngIf="mappedValue[courseOutcome.id][programOutcome.id].id" class="plo-title-cell"
                  [ngStyle]="{'background-color': mappedValue[courseOutcome.id][programOutcome.id].bgColor, 'color': mappedValue[courseOutcome.id][programOutcome.id].fontColor}">
                  <div class="tooltip level" *ngIf="mappedValue[courseOutcome.id][programOutcome.id].id != 0">
                    {{mappedValue[courseOutcome.id][programOutcome.id].abbreviation}}
                    <span class="tooltip-text top">{{ mappedValue[courseOutcome.id][programOutcome.id].name }}</span>
                  </div>
                </td>
                <td *ngIf="!mappedValue[courseOutcome.id][programOutcome.id].id"></td>
              </ng-container>

            </tr>

          </table>

          <!-- No Category -->

          <table *ngIf="programOutcomeCategories.length == 0" class="outcome-map-table">

            <!--- PLO row -->

            <tr>
              <td class="course-outcome-cell" rowspan="2">
                <div class="header-cell">Course outcomes</div>
              </td>

              <td [colSpan]="programOutcomes.length" class="plo-title-cell">Program-level learning outcomes</td>

            </tr>

            <!-- program outcome category row -->

            <!-- <tr *ngIf="programOutcomeCategories && programOutcomeCategories.length > 0">
          
                      <td *ngFor="let programOutcome of programOutcomes; let i = index;" class="plo-title-cell">
                        {{ i+1 }}. {{ programOutcome.title }}
                      </td>
                    </tr> -->

            <!-- -->

            <!-- no plo category -->

            <tr *ngIf="!programOutcomeCategories || programOutcomeCategories.length == 0">
              <td *ngFor="let programOutcome of programOutcomes; let i = index;" class="plo-title-cell">
                <div class="plo-index">{{ i+1 }}</div><span *ngIf="programOutcomes.length < 9">{{ programOutcome.title
                  }}</span>
              </td>
            </tr>

            <!-- END no plo category -->

            <!-- course outcomes row -->

            <tr *ngFor="let courseOutcome of courseOutcomes; let i = index">
              <td class="course-outcome-cell">{{i+1}}. {{ courseOutcome.description }}</td>

              <ng-container *ngFor="let programOutcome of programOutcomes">
                <td *ngIf="mappedValue[courseOutcome.id][programOutcome.id].id"
                  [ngStyle]="{'background-color': mappedValue[courseOutcome.id][programOutcome.id].bgColor, 'color': mappedValue[courseOutcome.id][programOutcome.id].fontColor}">
                  <div class="tooltip level" *ngIf="mappedValue[courseOutcome.id][programOutcome.id].id != 0">
                    {{mappedValue[courseOutcome.id][programOutcome.id].abbreviation}}
                    <span class="tooltip-text top">{{ mappedValue[courseOutcome.id][programOutcome.id].name }}</span>
                  </div>
                </td>
                <td *ngIf="!mappedValue[courseOutcome.id][programOutcome.id].id"></td>
              </ng-container>

            </tr>

          </table>

        </div>
      </div>
    </div>
  </ng-template>




</ng-template>