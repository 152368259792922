import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

//Models
import { ProgramOutcome } from "app/core/models/program-outcome";
import { ProgramOutcomeCategory } from "app/core/models/program-outcome-category";
import { Level } from "app/core/models/level";
import { AssessmentFormat } from "app/core/models/assessment-format";
import { AssessmentMethodOption } from "app/core/models/assessment-method-option";
import { AssessmentTimingOption } from "app/core/models/assessment-timing-option";
import { AssessmentLevelOption } from "app/core/models/assessment-level-option";
import { CurriculumReview } from "app/core/models/curriculum-review";
import { TlaFormat } from "app/core/models/tla-format";
import { CourseOutcome } from "app/core/models/course-outcome";
import { CustomError } from "app/core/models/custom-error";
import { Loading } from "app/core/models/loading";

//Services
import { MappingService } from "app/core/services/mapping.service";
import { CourseOutcomeService } from "app/core/services/course-outcome.service";
import { UtilService } from "app/core/services/util.service";

//Libraries
import { Observable } from "rxjs";


@Component({
  selector: "app-user-step-summary-outcome-maps",
  templateUrl: "./user-step-summary-outcome-maps.component.html",
  styleUrls: ["./user-step-summary-outcome-maps.component.scss"]
})
export class UserStepSummaryOutcomeMapsComponent implements OnInit {

  @Input() courseId: number;
  @Input() programOutcomes: Array<ProgramOutcome>;
  @Input() programOutcomeCategories: Array<ProgramOutcomeCategory>;
  @Input() assessmentFormat: AssessmentFormat;

  @Input() courseOutcomes: Array<CourseOutcome>;
  @Input() reviewId: number;


  @Input() tlaFormat: TlaFormat;


  //grouped PLOs
  programOutcomesByCategory: Array<any> = [];
  programOutcomesWithoutCategory: Array<ProgramOutcome> = [];

  mappedValue: Array<any> = [];

  levels: Array<Level> = [];
  levelsArray: Array<Level> = [];

  outcomeMapTableWidth: number = 1000; //set defualt table width

  //flags
  mappedValueLoaded: boolean = false;

  //errors
  loadError: CustomError;
  loading: Loading = new Loading(false, "Loading outcome maps...");



  constructor(
    private mappingService: MappingService,
    private courseOutcomeService: CourseOutcomeService,
    private utilService: UtilService
  ) { }

  async ngOnInit() {
    this.loading.isLoading = true;

    try {
      //group plos by category
      this.utilService.groupPlosByCategory(
        this.programOutcomes,
        this.programOutcomeCategories,
        (programOutcomesByCategory, programOutcomesWithoutCategory) => {
          this.programOutcomesByCategory = programOutcomesByCategory;
          this.programOutcomesWithoutCategory = programOutcomesWithoutCategory;
        }
      );

      //initialize mapped value

      //set table width
      this.outcomeMapTableWidth = this.programOutcomes.length * 135 + 200 * 2;

      //console.log(this.outcomeMapTableWidth);

      this.courseOutcomes.forEach(courseOutcome => {
        this.mappedValue[courseOutcome.id] = [];

        this.programOutcomes.forEach(programOutcome => {
          this.mappedValue[courseOutcome.id][programOutcome.id] = new Level();
        });
      });

      this.mappedValueLoaded = true;

      //load levels
      let levels: Array<Level> = await this.mappingService.getScaleLevelsForCourse(
        this.courseId
      );

      levels.forEach(level => {
        //convert to index array
        this.levelsArray[level.id] = new Level(
          level.id,
          level.name,
          level.description,
          level.abbreviation,
          level.colorCode,
          level.level,
          level.bgColor,
          level.fontColor
        );
      });

      for (let courseOutcome of this.courseOutcomes) {
        let outcomeMapValues = await this.mappingService.getOutcomeMapValues(
          this.courseId,
          courseOutcome.id
        );

        outcomeMapValues.forEach(outcomeMapValue => {
          //only if the selected level exists in the levels array
          if (this.levelsArray[outcomeMapValue.levelId]) {
            this.mappedValue[courseOutcome.id][
              outcomeMapValue.programOutcomeId
            ] = this.levelsArray[outcomeMapValue.levelId];
          }
        });
      }

    } catch (error) {

      this.loadError = error;
    } finally {
      this.loading.isLoading = false;
    }
  }

  /******************************************************************
   * Load assessment IDs selected by the specified course outcome
   *****************************************************************/
  loadSelectedAssessmentIDs(
    courseId: number,
    courseOutcomeId: number,
    callback
  ) {
    // this.courseOutcomeService.getSelectedAssessmentIDs(courseId, courseOutcomeId)
    //   .subscribe((assessmentIDs: Array<number>) => {
    //     return callback(assessmentIDs, null);
    //   },
    //   error => {
    //     return callback(null, error);
    //   });
  }

  // /*****************************************************************
  //  * Reload outcome maps
  //  *****************************************************************/
  // reloadMappedValue() {

  //   let transactions = [];

  //   this.courseOutcomes.forEach((courseOutcome: CourseOutcomeValue) => {

  //     //store the status of each load
  //     let loadedValue = { "map": false, "assessment": false, "tla": false };

  //     //get mapped values
  //     this.mappedValue[courseOutcome.id] = [];

  //     //should this be loaded from parent?? YES it should..

  //     this.mappingService.getOutcomeMapValues(this.courseId, courseOutcome.id)
  //       .subscribe((data) => {

  //         //data format is {'noMapping': boolean, 'outcomeMaps': Array<{ 'levelId': number, 'programOutcomeId': number }>}

  //         data.outcomeMaps.forEach(object => {
  //           //assign level object
  //           this.mappedValue[courseOutcome.id][object.programOutcomeId] = this.getLevel(object.levelId);
  //         });

  //         loadedValue.map = true;

  //         //if all three loads are complete, push course outcome id to transactions
  //         if (loadedValue.map && loadedValue.assessment && loadedValue.tla) {
  //           transactions.push(courseOutcome.id);

  //           if (transactions.length == this.courseOutcomes.length) {

  //             this.loading = false;
  //             this.sectionLoaded.emit();

  //           }
  //         }

  //       },
  //       error => {
  //         //TODO
  //       });

  //     // Step 3. Get selected assessment IDs for this course outcome

  //     //convert selected assessment methods to array: selectedAssessments[assessmentId] = assessmentObject
  //     // this.selectedAssessmentMethods.forEach((selectedAssessment: AssessmentMethodValue) => {
  //     //   this.courseAssessments[selectedAssessment.selectedId] = selectedAssessment;
  //     // });

  //     this.loadSelectedAssessmentIDs(this.courseId, courseOutcome.id, (assessmentIds, error) => {

  //       loadedValue.assessment = true;

  //       if (error) {
  //         //TODO
  //       }
  //       else {

  //         this.courseOutcomeAssessments[courseOutcome.id] = [];

  //         assessmentIds.forEach((assessmentId: number) => {

  //           //check if the assessment exists (in case the assessment method was deleted after mapping)
  //           if (this.courseAssessments[assessmentId]) {
  //             this.courseOutcomeAssessments[courseOutcome.id].push(this.courseAssessments[assessmentId]);
  //           }

  //         });

  //         //if all three loads are complete, push course outcome id to transactions
  //         if (loadedValue.map && loadedValue.assessment && loadedValue.tla) {
  //           transactions.push(courseOutcome.id);

  //           if (transactions.length == this.courseOutcomes.length) {

  //             this.loading = false;
  //             this.sectionLoaded.emit();

  //           }
  //         }

  //       }

  //     });

  //     //Step 4. get selected TLA Ids for this course outcome

  //     this.selectedTlas.forEach((selectedTla: TlaOptionValue) => {
  //       this.courseTlas[selectedTla.selectedId] = selectedTla;
  //     });

  //     this.loadSelectedTlaIDs(this.courseId, courseOutcome.id, (selectedTlaIDs, error) => {

  //       loadedValue.tla = true;

  //       if (error) {
  //         //TODO
  //       }
  //       else {

  //         this.courseOutcomeTlas[courseOutcome.id] = [];

  //         selectedTlaIDs.forEach((selectedTlaId: number) => {
  //           if (this.courseOutcomeTlas[courseOutcome.id]) {
  //             this.courseOutcomeTlas[courseOutcome.id].push(this.courseTlas[selectedTlaId]);
  //           }

  //         });

  //         if (loadedValue.map && loadedValue.assessment && loadedValue.tla) {
  //           transactions.push(courseOutcome.id);

  //           if (transactions.length == this.courseOutcomes.length) {
  //             this.loading = false;
  //             this.sectionLoaded.emit();
  //           }
  //         }
  //       }

  //     });

  //   }); //end for each

  // }

  // /******************************************************************
  //  * Load TLA IDs selected by the specified course outcome
  //  *****************************************************************/
  // loadSelectedTlaIDs(courseId: number, courseOutcomeId: number, callback) {

  //   // this.courseOutcomeService.getSelectedTlaIDs(courseId, courseOutcomeId)
  //   //   .subscribe((selectedOutcomeTlaIDs: Array<number>) => {
  //   //     return callback(selectedOutcomeTlaIDs);
  //   //   },
  //   //   error => {
  //   //     return callback(null, error);
  //   //   });

  // }

  // /*******************************
  //  * Utility functions
  //  *******************************/
  // truncate(string) {

  //   if (string.length > 50) {
  //     return string.substring(0, 48) + "...";
  //   }
  //   return string;
  // }

  // getLevel(levelId: number) {

  //   for (let level of this.levels) {

  //     if (level.id == levelId) {
  //       return level;
  //     }

  //   }
  //   return null;

  // }

  // getFontColor(rgbValue) {

  //   if (rgbValue) {

  //     let rgbValues = rgbValue.split(",");

  //     if (rgbValues.length == 3) {
  //       let a = 1 - (0.299 * rgbValues[0] + 0.587 * rgbValues[1] + 0.114 * rgbValues[2]) / 255;
  //       if (a < 0.5) {
  //         return '#000';
  //       }
  //       else {
  //         return '#fff';
  //       }
  //     }

  //   }
  //   return '#000';

  // }

  // togglePloTable(event) {
  //   event.stopPropagation();
  //   this.showPloTable = this.showPloTable ? false : true;
  // }
}
