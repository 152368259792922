
    <table class="ui table co-table">

      <ng-container *ngFor="let course of courseList">

        <ng-container *ngIf="!includedCourseIds || (includedCourseIds && includedCourseIds.indexOf(course.id) > -1) ">

          <tr class="course-row header">
            <td class="course-cell">{{course.code}} {{ course.number }} {{ course.year }} {{ course.semester }}
              {{ course.section }} {{ course.name }} </td>
          </tr>

          <tr class="outcome-row">
            <td>

              <ng-container *ngIf="course.status !== 'Submitted'">
                No Data
              </ng-container>
              
              <ul *ngIf="course.status === 'Submitted'">
                <li *ngFor="let outcome of course.outcomes">{{ outcome.description }}</li>
              </ul>



            </td>
          </tr>

        </ng-container>

      </ng-container>

    </table>