import { Injectable } from "@angular/core";
// import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Config } from "app/config";

//Models
import { CourseOutcome } from "app/core/models/course-outcome";
import { AssessmentMethodOption } from "app/core/models/assessment-method-option";
import { CourseOutcomeSelectedAssessmentMethod } from "app/core/models/course-outcome-selected-assessment-method";
import { CourseOutcomeSelectedTlaOption } from "app/core/models/course-outcome-selected-tla-option";

//Services
import { UtilService } from "app/core/services/util.service";
import { UserCourseOutcome } from "app/core/models/user-course-outcome";

//Libraries
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CourseOutcomeService {
  constructor(
    private httpClient: HttpClient,
    private utilService: UtilService
  ) { }

  /*****************************
   * Get course outcomes
   * Error Checked
   *****************************/

   /**
    * 
    * @param courseId 
    */
  getCourseOutcomes(courseId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 + "/courses/" + courseId + "/course-outcomes"
      )
      .pipe(
        map((courseOutcomesData: Array<any>) => {
          //console.log(courseOutcomesData);

          let courseOutcomes: Array<CourseOutcome> = [];

          courseOutcomesData.forEach(courseOutcomeData => {
            courseOutcomes.push(
              new CourseOutcome(
                courseOutcomeData.id,
                courseOutcomeData.description
              )
            );
          });

          return courseOutcomes;
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }



  /*****************************
   * Add Course Outcome
   * Error Checked
   *****************************/
  /**
   * 
   * @param courseId 
   * @param courseOutcome 
   */
  addCourseOutcome(courseId: number, courseOutcome: CourseOutcome) {
    const body = { description: courseOutcome.description };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 + "/courses/" + courseId + "/course-outcomes",
        body
      )
      .pipe(
        map((outcomeData: any) => {
          //return new course outcome id
          //console.log(outcomeData);
          return new CourseOutcome(outcomeData.id, outcomeData.description);
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*****************************
   * Update Course Outcome
   * Error Checked
   *****************************/
  /**
   * 
   * @param courseId 
   * @param courseOutcome 
   */
  updateCourseOutcome(courseId: number, courseOutcome: CourseOutcome) {
    const body = { description: courseOutcome.description };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcomes/" +
        courseOutcome.id,
        body
      )
      .pipe(
        map((outcomeData: any) => {
          //console.log(outcomeData);
          return new CourseOutcome(outcomeData.id, outcomeData.description);
        }),
        catchError(error => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param courseId 
   * @param courseOutcomeId 
   */
  deleteCourseOutcome(courseId: number, courseOutcomeId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcomes/" +
        courseOutcomeId,
        httpOptions
      )
      .pipe(
        map(() => { }),
        catchError(error => {
          console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  //////////////////////// Course Outcome Assessments //////////////////////////////////////////////////////////////////////////////

  /*******************************************************
   * Get selected assessment methods for a course outcome
   ********************************************************/

  // getSelectedAssessmentMethods(courseId: number, courseOutcomeId: number) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //       // "x-access-token": token
  //     })
  //   };

  //   return this.httpClient
  //     .get(
  //       Config.BASE_API_URL_V1 +
  //         "/courses/" +
  //         courseId +
  //         "/course-outcomes/" +
  //         courseOutcomeId +
  //         "/assessment-methods",
  //       httpOptions
  //     )
  //     .pipe(
  //       map((assessmentMethodsData: Array<any>) => {
  //         //console.log(assessmentMethodsData);

  //         let assessmentMethodIds: Array<
  //           CourseOutcomeSelectedAssessmentMethod
  //         > = [];

  //         assessmentMethodsData.forEach(assessmentMethodData => {
  //           assessmentMethodIds.push(
  //             new CourseOutcomeSelectedAssessmentMethod(
  //               assessmentMethodData.id,
  //               assessmentMethodData.selectedAssessmentId
  //             )
  //           );
  //         });

  //         return assessmentMethodIds;
  //       }),
  //       catchError(error => {
  //         //console.log(error);
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     );
  // }

  /**************************************
   * Add Selected Assessment Methods to DB
  //  ****************************************/
  // addSelectedAssessmentMethods(
  //   courseId: number,
  //   courseOutcomeId: number,
  //   assessmentIds: Array<number>
  // ) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //       // "x-access-token": token
  //     })
  //   };

  //   // levelOptions.forEach(levelValue => {
  //   //   levelOptionsArray.push({
  //   //     selectedMethodId: selectedMethodId,
  //   //     levelOptionId: levelValue.id,
  //   //     otherText: levelValue.otherText
  //   //   });
  //   // });

  //   let assessmentIdArray = [];

  //   //console.log(assessmentIds);

  //   assessmentIds.forEach(assessmentId => {
  //     assessmentIdArray.push({
  //       courseOutcomeId: courseOutcomeId,
  //       selectedAssessmentId: assessmentId
  //     });
  //   });

  //   //console.log(assessmentIdArray);

  //   return this.httpClient
  //     .post(
  //       Config.BASE_API_URL_V1 +
  //         "/courses/" +
  //         courseId +
  //         "/course-outcomes/" +
  //         courseOutcomeId +
  //         "/assessment-methods",
  //       assessmentIdArray,
  //       httpOptions
  //     )
  //     .pipe(
  //       map(result => {
  //         //return new course outcome id
  //         return result;
  //       }),
  //       catchError(error => {
  //         //console.log(error);
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();
  // }

  ////////////////////////// Course Outcome TLA //////////////////////////////////////////////////

  /**************************************
   * Add Selected Tla Options to DB
   ****************************************/
  // addSelectedTlaOptions(
  //   courseId: number,
  //   courseOutcomeId: number,
  //   tlaIds: Array<number>
  // ) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //       // "x-access-token": token
  //     })
  //   };

  //   let tlaIdArray = [];

  //   tlaIds.forEach(tlaId => {
  //     tlaIdArray.push({
  //       courseOutcomeId: courseOutcomeId,
  //       selectedTlaId: tlaId
  //     });
  //   });

  //   return this.httpClient
  //     .post(
  //       Config.BASE_API_URL_V1 +
  //       "/courses/" +
  //       courseId +
  //       "/course-outcomes/" +
  //       courseOutcomeId +
  //       "/tla-options",
  //       tlaIdArray,
  //       httpOptions
  //     )
  //     .pipe(
  //       map(result => {
  //         //return new course outcome id
  //         return result;
  //       }),
  //       catchError(error => {
  //         //console.log(error);
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     );
  // }
}
