<div *ngIf="!isIE; else notSupported">


  <div *ngIf="!loaded ; else appLoaded" class="app-loading">



    <div class="ui indicating progress" *ngIf="!apiUnavailable">
      <div class="bar" id="progress-bar">
        <div class="progress"></div>
      </div>
      <div class="label">Loading Curriculum Links...</div>
    </div>

    <div class="ui negative message" *ngIf="apiUnavailable">
      <div class="header">
        Curriculum Links is temporarily unavailable.
      </div>
      <p>Please try again later.</p>
    </div>







  </div>




  <ng-template #appLoaded>


    <div *ngIf="appInfo.isUnderMaintenance; else appLive" class="maintenance  ui segment">

      <div class="maintenance-title">
        We'll be back shortly...
      </div>
      <div>
        <p>
          Sorry for the inconvenience but we are currently perfmorming some maintenance.
        </p>
        <p>
          - Curriculum Links
        </p>
      </div>


    </div>

    <ng-template #appLive>

      <app-nav-bar [checkUser]="checkUser"></app-nav-bar>

      <app-global-error *ngIf="globalError" [error]="globalError"></app-global-error>

      <div class="app">
        <div class="ui root container">

          <router-outlet></router-outlet>

          <div class="footer-area">

          </div>

        </div>



      </div>

      <div class="overlay" [ngClass]="{'show':showDimmer, 'hide': !showDimmer}">
      </div>

      <app-alert class="alert" [ngClass]="{'transition-show': shouldShowAlert, 'transition-hide': !shouldShowAlert}">
      </app-alert>

    </ng-template>






  </ng-template>


</div>



<ng-template #notSupported>


  <div class="not-supported ui segment">

    <div class="not-supported-title">Sorry, but Internet Explorer is not supported.</div>
    <div class="not-supported-description">
      To use Curriculum Links, please use one of the following browsers.


      <div class="ui grid browsers">
        <div class="four wide column">
          <div> <i class="chrome icon"></i></div>
          <div>Chrome</div>
        </div>
        <div class="four wide column">
          <div><i class="firefox icon"></i></div>
          <div>Firefox</div>
        </div>
        <div class="four wide column">
          <div> <i class="safari icon"></i></div>
          <div>Firefox</div>
        </div>
        <div class="four wide column">
          <div><i class="edge icon"></i></div>
          <div>Edge</div>
        </div>
      </div>


    </div>


  </div>


</ng-template>