<div>
  <div class="loader-segment">
    <!-- <div><i class="fas fa-circle-notch fa-spin fa-3x"></i></div> -->


    <div class="cl-loader">
      <!-- <img src="assets/images/CL_Logo_UR_Grayscale.png"> -->
      <!-- <img src="assets/images/CL_Logo_UR_Faded_100.png"> -->
      <img src="assets/images/CL_Logo_UR_35.png">
    </div>

    <div class="loading-text">{{loadingText}}</div>

  </div>
</div>