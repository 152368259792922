<div *ngIf="loading.isLoading; else loaded">
  <app-loading [loadingText]="loading.message"></app-loading>
</div>


<ng-template #loaded>

  <app-load-error *ngIf="loadError; else noErrors" [loadError]="loadError">
  </app-load-error>



  <table *ngIf="selectedAssessmentMethods && selectedAssessmentMethods.length != 0" class="ui table assessment-table">

    <tr class='header-row'>
      <td>Assessment method</td>
      <td *ngIf="assessmentFormat.isWeightRequired">Weight (%)
        <!-- <div class="tooltip">
          <i class="question circle icon"></i>
          <span class="tooltip-text top">Percentage of the final grade the assessment is worth.</span>
        </div> -->

        <span class="tooltip-wrapper" data-tooltip="Percentage of the final grade the assessment is worth"
          data-inverted="">
          <i class="question circle icon"></i>
        </span>

      </td>
      <td *ngIf="assessmentFormat.isTimingRequired">At what point during the term
        <span class="tooltip-wrapper" data-tooltip="At what point during the term the assessment occurs."
          data-inverted="">
          <i class="question circle icon"></i>
        </span>
        <!-- <div class="tooltip">
          <i class="question circle icon"></i>
          <span class="tooltip-text top">At what point during the term does the assessment occur?</span>
        </div> -->
      </td>
      <td *ngIf="assessmentFormat.isLevelRequired">
        Level of understanding
        <span class="tooltip-wrapper" data-tooltip="TODO: Level of understanding description" data-inverted="">
          <i class="question circle icon"></i>
        </span>

      </td>
    </tr>

    <ng-container *ngFor="let assessment of selectedAssessmentMethods">
      <tr>
        <td class="assessment-description-cell">{{assessment.methodName}}
          <span *ngIf="assessment.description"> :</span>
          <div *ngIf="assessment.description">
            {{assessment.description}}
          </div>
        </td>
        <td *ngIf="assessmentFormat.isWeightRequired">{{assessment.weight}} %</td>
        <td *ngIf="assessmentFormat.isTimingRequired">

          <div *ngIf="assessmentFormat.timingFormat == 'text'">
            {{assessment.timingText}}
          </div>

          <div *ngIf="assessmentFormat.timingFormat == 'checkbox'">
            <div *ngFor="let timing of assessment.timings">
              {{timing.description}}
              <span *ngIf="timing.otherText">: {{timing.otherText}} </span>
            </div>
          </div>

        </td>
        <td *ngIf="assessmentFormat.isLevelRequired">

          <div *ngIf="assessmentFormat.levelFormat == 'text'">
            {{assessment.levelText}}
          </div>

          <div *ngIf="assessmentFormat.levelFormat == 'checkbox'">

            <div *ngFor="let level of assessment.levels">
              {{level.description}}
              <span *ngIf="level.isOtherTextRequired">: {{level.otherText}} </span>
            </div>

          </div>

        </td>
      </tr>
    </ng-container>

    <tr class="weight-row" *ngIf="assessmentFormat.isWeightRequired">
      <td>Total Weight</td>
      <td>
        {{ totalWeight }} %
      </td>
      <td *ngIf="assessmentFormat.isTimingRequired"></td>
      <td *ngIf="assessmentFormat.isLevelRequired"></td>
    </tr>


  </table>
</ng-template>