<div class="bottom-alert " *ngIf="alert && shouldShowAlert">

  <!-- <div class="ui icon message" [ngClass]="{'success': alert.success, 'negative': !alert.success}">


    <i class="close icon" (click)="closeAlert()"></i>
    <i *ngIf="alert.success" class="check circle outline icon"></i>
    <i *ngIf="!alert.success" class="warning circle icon"></i>



    <div class="content">{{alert.message}} (This alert will be deprecated because it's using the old Error object model.)
      <div *ngIf="alert.errors">
        <div *ngFor="let error of alert.errors">
          <span class="error-title">({{error.status}}) {{error.title}} -</span> {{error.message}}</div>
      </div>
    </div>
  </div> -->


  <!-- for ApiError -->

  <div class="ui icon message" [ngClass]="{'success': alert.success, 'negative': !alert.success}">

    <i class="close icon" (click)="closeAlert()"></i>
    <i *ngIf="alert.success" class="check circle outline icon"></i>
    <i *ngIf="!alert.success" class="warning circle icon"></i>

    <div class="content">{{alert.message}}
      <div *ngIf="alert.errors">
        <div *ngFor="let error of alert.errors">
          <div>{{error.title}} -
            <span *ngIf="error.description">{{error.description}}</span>
            <span *ngIf="error.url"> at {{error.url}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>