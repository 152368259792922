import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { Observable } from "rxjs";
import { Config } from "app/config";
import { map, catchError, finalize } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UtilService } from "app/core/services/util.service";
import { ApplicationInfo } from "app/core/models/application-info";

@Injectable({
  providedIn: 'root'
})
export class NavBarService {
  private _numCourses: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  // numCourses = this._numCourses.asObservable();

  constructor(
    private httpClient: HttpClient,
    private utilService: UtilService
  ) {
    this._numCourses.next(0);
  }

  setNumCourses(numCourses: number) {
    this._numCourses.next(numCourses);
  }

  getNumCourses(): Observable<number> {
    return this._numCourses;
  }

  decreaseNumCourses() {
    this._numCourses.next(this._numCourses.getValue() - 1);
  }

  getCurrentVersion() {
    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/version")
      .pipe(
        map((version: any) => {

          return new ApplicationInfo(version.currentVersion);

        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }
}
