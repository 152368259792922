<div class="ui celled grid plo-table">

  <div class="row header-row">
    <div class="one wide column"></div>
    <div class="four wide column">Title</div>
    <div class="eight wide column">Description</div>
  </div>

    <ng-container *ngFor="let ploGroup of chartData.ploGroups">

      <div class="row category-row" *ngIf="chartData.programOutcomeCategories.length > 0">
        <div class="thirteen wide column">{{ploGroup.category.categoryName}}</div>
      </div>

      <div class="row" *ngFor="let plo of ploGroup.plos">
        <div class="one wide column index">{{plo.index}}</div>
        <div class="four wide column">{{plo.title}}</div>
        <div class="nine wide column">{{plo.description}}</div>
      </div>

    </ng-container>


</div>
