export class AssessmentFormat {
  private _id: number;
  private _isEnabled: boolean;
  private _format: string;
  private _isTimingRequired: boolean;
  private _timingFormat: string;
  private _isLevelRequired: boolean;
  private _levelFormat: string;
  private _isWeightRequired: boolean;
  private _requiredByCourseOutcome: boolean;
  private _chartColor: string;
  private _levelChartColor: string;
  private _timingChartColor: string;

  constructor(
    id: number = null,
    isEnabled: boolean = false,
    format: string = "text",
    isWeightRequired = false,
    isTimingRequired = false,
    timingFormat = "text",
    isLevelRequired = false,
    levelFormat = "text",
    requiredByCourseOutcome = false,
    chartColor = "1E88E5",
    levelChartColor = "1E88E5",
    timingChartColor = "1E88E5"
  ) {
    this._id = id;
    this._isEnabled = isEnabled;
    this._format = format;
    this._isWeightRequired = isWeightRequired;
    this._isTimingRequired = isTimingRequired;
    this._timingFormat = timingFormat;
    this._isLevelRequired = isLevelRequired;
    this._levelFormat = levelFormat;
    this._requiredByCourseOutcome = requiredByCourseOutcome;
    this._chartColor = chartColor ? chartColor : "1E88E5";
    this._levelChartColor = levelChartColor ? levelChartColor : "1E88E5";
    this._timingChartColor = timingChartColor ? timingChartColor : "1E88E5";
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get isEnabled(): boolean {
    return this._isEnabled;
  }

  public set isEnabled(value: boolean) {
    this._isEnabled = value;
  }

  public get format(): string {
    return this._format;
  }

  public set format(value: string) {
    this._format = value;
  }

  public get isTimingRequired(): boolean {
    return this._isTimingRequired;
  }

  public set isTimingRequired(value: boolean) {
    this._isTimingRequired = value;
  }

  public get isWeightRequired(): boolean {
    return this._isWeightRequired;
  }

  public set isWeightRequired(value: boolean) {
    this._isWeightRequired = value;
  }

  public get isLevelRequired(): boolean {
    return this._isLevelRequired;
  }

  public set isLevelRequired(value: boolean) {
    this._isLevelRequired = value;
  }

  public get levelFormat(): string {
    return this._levelFormat;
  }

  public set levelFormat(value: string) {
    this._levelFormat = value;
  }

  public get requiredByCourseOutcome(): boolean {
    return this._requiredByCourseOutcome;
  }

  public set requiredByCourseOutcome(value: boolean) {
    this._requiredByCourseOutcome = value;
  }

  public get timingFormat(): string {
    return this._timingFormat;
  }

  public set timingFormat(value: string) {
    this._timingFormat = value;
  }

  /**
   * Getter chartColor
   * @return {string}
   */
  public get chartColor(): string {
    return this._chartColor;
  }

  /**
   * Setter chartColor
   * @param {string} value
   */
  public set chartColor(value: string) {
    this._chartColor = value;
  }

  /**
   * Getter levelChartColor
   * @return {string}
   */
  public get levelChartColor(): string {
    return this._levelChartColor;
  }

  /**
   * Setter levelChartColor
   * @param {string} value
   */
  public set levelChartColor(value: string) {
    this._levelChartColor = value;
  }

  /**
   * Getter timingChartColor
   * @return {string}
   */
  public get timingChartColor(): string {
    return this._timingChartColor;
  }

  /**
   * Setter timingChartColor
   * @param {string} value
   */
  public set timingChartColor(value: string) {
    this._timingChartColor = value;
  }
}
