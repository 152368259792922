<div class="level-section">

  <table class="ui table">
    <tr *ngFor="let level of levels">
      <td class="level-title">
        <div class="square" [ngStyle]="{'background-color': level.bgColor, 'color': level.fontColor}"></div>
        <span>{{level.name}} ({{level.abbreviation}})</span>
      </td>
      <td class="leve-description">
        <div class="level-description">{{level.description}}</div>
      </td>
    </tr>
  </table>

</div>

