import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-all-course-list-with-outcomes',
  templateUrl: './all-course-list-with-outcomes.component.html',
  styleUrls: ['./all-course-list-with-outcomes.component.scss']
})
export class AllCourseListWithOutcomesComponent implements OnInit {

  @Input()
  courseList: Array<any>;

  @Input()
  includedCourseIds: Array<number>;

  constructor() { }

  ngOnInit() {
  }

}
