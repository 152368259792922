<!-- <div class="ui secondary menu nav-bar"> -->

<div id="navigation">

  <div class="logo-container" [ngStyle]="{'background-color': appInfo.logoBackgroundColor}"
    *ngIf="appInfo.displayMainLogo">
    <div class="ui container">
      <div class="logo">
        <!-- <a href="https://www.ucalgary.ca"> -->
        <img [src]="appInfo.mainLogo" />
        <!-- </a> -->
      </div>

    </div>
  </div>

  <div class="nav-container" [ngStyle]="{'background-color': appInfo.menuBackgroundColor}" [ngClass]="{'tall-nav': !appInfo.displayMainLogo}">
    <div class="ui container">

      <div [ngClass]="{'flex-container' : appInfo.displaySecondaryLogo}">


        <div class="secondary-logo" *ngIf="appInfo.displaySecondaryLogo">
          <img [src]="appInfo.secondaryLogo" />
        </div>


        <div class="menu-container">

          <div>


            <div class="title">

              <a href="https://curriculumlinks.ucalgary.ca">{{appInfo.title}} (v.{{appInfo.currentVersion}})</a><span
                *ngIf="appInfo.subtitle" class="subtitle">- {{appInfo.subtitle}}
              </span>
              <span class="right-menu">
                <span *ngIf="this.user && (user.id == 1 || (user.id && user.id != 0))" class="user-email"
                  [ngClass]="{'system-admin': this.user && user.id == 1}">Logged
                  in as {{ user.email }}
                  <span *ngIf="this.user && user.id == 1">
                    (System Administrator)
                  </span>
                </span>
                <span *ngIf="this.user && user.id == 0">
                  Logged in as Guest
                </span>
              </span>
            </div>

            <div class="menu">
              <a routerLink="/" class="item" *ngIf="isHomeEnabled">Home</a>
              <a routerLink="/about" class="item">About Curriculum Links</a>

              <ng-container *ngIf="this.user && (user.id == 1 || (user.id && user.id != 0))">

                <span class="right-menu">
                  <a routerLink="{{menuItem.link}}" class="item" *ngFor="let menuItem of menuItems">
                    {{menuItem.title}}
                  </a>
                  <a class="last item" (click)="logout()">Logout</a>

                </span>

              </ng-container>

              <span class="right-menu">
                <a class="item login-item " [routerLink]="['auth/register']"
                  *ngIf="!user || user.id == null || user.id == 0">Register</a>
                <a class="item login-item last" [routerLink]="['auth/login']"
                  *ngIf="!user || user.id == null || user.id == 0">Login</a>

              </span>

            </div>

          </div>

        </div>


      </div>



    </div>
  </div>

</div>


<!-- <div class="menu-bar">
  <div class="ui container ">


    <div class="left-column">

      <a href="{{logoUrl}}">

        <div class="logo">
          <img src="assets/images/uc-vert-rgb.png">
        </div>

        <div class="app-info" *ngIf="appInfo">

          <div class="app-name"> {{appInfo.title}} (v.{{appInfo.currentVersion}})</div>
          <div class="school-name">University of Calgary</div>

        </div>

      </a>

    </div>

    <div class="right-column">

      <div class="user-greeting">
        <span *ngIf="this.user && (user.id == 1 || (user.id && user.id != 0))" class="user-email"
          [ngClass]="{'system-admin': this.user && user.id == 1}">Logged
          in as {{ user.email }}
          <span *ngIf="this.user && user.id == 1">
            (System Administrator)
          </span>
        </span>
        <span *ngIf="this.user && user.id == 0">
          Logged in as Guest
        </span>
      </div>

      <div class="left-menu">
        <a routerLink="/" class="item" *ngIf="isHomeEnabled">Home</a>
        <a routerLink="/about" class="item">About Curriculum Links</a>
        <a class="item login-item last" [routerLink]="['auth/login']"
          *ngIf="!user || user.id == null || user.id == 0">Login</a>
        <a class="item login-item " [routerLink]="['auth/register']"
          *ngIf="!user || user.id == null || user.id == 0">Register</a>
      </div>

      <div class="right-menu">
        <a class="item login-item " [routerLink]="['auth/register']"
          *ngIf="!user || user.id == null || user.id == 0">Register</a>
        <a class="item login-item last" [routerLink]="['auth/login']"
          *ngIf="!user || user.id == null || user.id == 0">Login</a>


        <ng-container *ngIf="this.user && (user.id == 1 || (user.id && user.id != 0))">
          <a routerLink="{{menuItem.link}}" class="item" *ngFor="let menuItem of menuItems">
            {{menuItem.title}}
          </a>
          <a class="last item" (click)="logout()">Logout</a>
        </ng-container>

      </div>

    </div>

  </div>
</div> -->




<!-- </div> -->