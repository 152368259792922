import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { AppComponent } from "./app.component";
import { AuthGuardService as AuthGuard } from "./core/guards/auth-guard.service";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";

const routes: Routes = [
  { path: "auth", loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule) },
  {
    path: "user",
    loadChildren: () => import('app/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: "admin",
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  { path: "", loadChildren: () => import('app/public/public.module').then(m => m.PublicModule) }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    FormsModule,

    HttpClientModule,
    SharedModule,
    // SortablejsModule.forRoot({}),
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules //preload all modules in background so loading admin screen is faster
    ,
    relativeLinkResolution: 'legacy'
})
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
