import { version } from "punycode";

export class User {

	private _id: number;
	private _name: string;
    private _email: string;
	// private _password: string;
	private _verified: boolean;



	constructor(id: number, name: string, email: string, verified: boolean) {
		this._id = id;
		this._name = name;
		this._email = email;
		this._verified = verified;
	}


	public get id(): number {
		return this._id;
	}

	public set id(value: number) {
		this._id = value;
	}

	public get email(): string {
		return this._email;
	}

	public set email(value: string) {
		this._email = value;
	}

	// public get password(): string {
	// 	return this._password;
	// }

	// public set password(value: string) {
	// 	this._password = value;
	// }

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}


    /**
     * Getter verified
     * @return {boolean}
     */
	public get verified(): boolean {
		return this._verified;
	}

    /**
     * Setter verified
     * @param {boolean} value
     */
	public set verified(value: boolean) {
		this._verified = value;
	}


}
