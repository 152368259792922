<div *ngIf="dialog" class="modal-window" [ngClass]="{'show': dialog.show}">

  <div class="modal-window-header">
    {{ dialog.header }}
  </div>

  <div class="modal-window-content">
    {{dialog.message}}
  </div>


  <app-modal-action-error [actionError]="actionError">
  </app-modal-action-error>


  <div class="modal-actions">
    <span class="in-progress-message" *ngIf="updating">{{ dialog.inProgressMessage}}</span>

    <button class="ui tiny  button" (click)="submitClicked()" [disabled]="updating" [ngClass]="{'loading': updating, 'submit-button': dialog.buttonType == 'submit', 'delete-button': dialog.buttonType == 'delete'}">
      {{ dialog.buttonTextSubmit }}
    </button>

    <button class="ui tiny basic button deny" (click)="cancelClicked()" [disabled]="updating">{{
      dialog.buttonTextCancel }}</button>

  </div>

</div>
