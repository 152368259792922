export class AssessmentTimingOptionSelected {
  private _selectionId: number;
  private _timingOptionId: number;
  private _otherText: string;

  constructor(selectionId: number, timingOptionId: number, otherText: string) {
    this._selectionId = selectionId;
    this._timingOptionId = timingOptionId;
    this._otherText = otherText;
  }

  /**
   * Getter selectionId
   * @return {number}
   */
  public get selectionId(): number {
    return this._selectionId;
  }

  /**
   * Setter selectionId
   * @param {number} value
   */
  public set selectionId(value: number) {
    this._selectionId = value;
  }

  /**
   * Getter timingOptionId
   * @return {number}
   */
  public get timingOptionId(): number {
    return this._timingOptionId;
  }

  /**
   * Setter timingOptionId
   * @param {number} value
   */
  public set timingOptionId(value: number) {
    this._timingOptionId = value;
  }

  /**
   * Getter otherText
   * @return {string}
   */
  public get otherText(): string {
    return this._otherText;
  }

  /**
   * Setter otherText
   * @param {string} value
   */
  public set otherText(value: string) {
    this._otherText = value;
  }
}
