import { CourseUser } from "app/core/models/course-user";
import { CourseOutcome } from "app/core/models/course-outcome";
import { AssessmentSelectedMethod } from "app/core/models/assessment-selected-method";
import { TlaSelectedOption } from "app/core/models/tla-selected-option";

export class Course {
  private _id: number;
  private _code: string;
  private _number: string;
  private _section: string;
  private _name: string;
  private _year: string;
  private _semester: string;
  private _reviewId: number;
  private _required: boolean;
  private _assignedTo: Array<any>;
  private _status: string;
  private _outcomes: Array<CourseOutcome>;
  private _assessments: Array<AssessmentSelectedMethod>;
  private _tlas: Array<TlaSelectedOption>;

  private _isArchived: boolean;
  private _isDeleted: boolean;

  constructor(
    id: number = null,
    code: string = null,
    number: string = null,
    section: string = null,
    name: string = null,
    year: string = null,
    semester: string = null,
    reviewId: number = null,
    required: boolean = false,
    assignedTo: Array<any> = [],
    status: string = null,
    outcomes: Array<CourseOutcome> = []
  ) {
    this._id = id;
    this._code = code;
    this._number = number;
    this._section = section;
    this._name = name;
    this._year = year;
    this._semester = semester;
    this._reviewId = reviewId;
    this._required = required;
    this._assignedTo = assignedTo;
    this._status = status;
    this._outcomes = outcomes;
    this._isArchived = false,
    this._isDeleted = false
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get code(): string {
    return this._code;
  }

  public set code(value: string) {
    this._code = value;
  }

  public get number(): string {
    return this._number;
  }

  public set number(value: string) {
    this._number = value;
  }

  public get section(): string {
    return this._section;
  }

  public set section(value: string) {
    this._section = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get year(): string {
    return this._year;
  }

  public set year(value: string) {
    this._year = value;
  }

  public get semester(): string {
    return this._semester;
  }

  public set semester(value: string) {
    this._semester = value;
  }

  public get reviewId(): number {
    return this._reviewId;
  }

  public set reviewId(value: number) {
    this._reviewId = value;
  }

  public get required(): boolean {
    return this._required;
  }

  public set required(value: boolean) {
    this._required = value;
  }

  /**
   * Getter assignedTo
   * @return {Array<CourseUser>}
   */
  public get assignedTo(): Array<any> {
    return this._assignedTo;
  }

  /**
   * Setter assignedTo
   * @param {Array<CourseUser>} value
   */
  public set assignedTo(value: Array<any>) {
    this._assignedTo = value;
  }

  public get status(): string {
    return this._status;
  }

  public set status(value: string) {
    this._status = value;
  }

  /**
   * Getter outcomes
   * @return {Array<CourseOutcome>}
   */
  public get outcomes(): Array<CourseOutcome> {
    return this._outcomes;
  }

  /**
   * Setter outcomes
   * @param {Array<CourseOutcome>} value
   */
  public set outcomes(value: Array<CourseOutcome>) {
    this._outcomes = value;
  }

  /**
   * Getter assessments
   * @return {Array<AssessmentSelectedMethod>}
   */
  public get assessments(): Array<AssessmentSelectedMethod> {
    return this._assessments;
  }

  /**
   * Setter assessments
   * @param {Array<AssessmentSelectedMethod>} value
   */
  public set assessments(value: Array<AssessmentSelectedMethod>) {
    this._assessments = value;
  }

  /**
   * Getter tlas
   * @return {Array<SelectedTlaOption>}
   */
  public get tlas(): Array<TlaSelectedOption> {
    return this._tlas;
  }

  /**
   * Setter tlas
   * @param {Array<SelectedTlaOption>} value
   */
  public set tlas(value: Array<TlaSelectedOption>) {
    this._tlas = value;
  }


    /**
     * Getter isArchived
     * @return {boolean}
     */
	public get isArchived(): boolean {
		return this._isArchived;
	}

    /**
     * Setter isArchived
     * @param {boolean} value
     */
	public set isArchived(value: boolean) {
		this._isArchived = value;
	}

    /**
     * Getter isDeleted
     * @return {boolean}
     */
	public get isDeleted(): boolean {
		return this._isDeleted;
	}

    /**
     * Setter isDeleted
     * @param {boolean} value
     */
	public set isDeleted(value: boolean) {
		this._isDeleted = value;
	}


}
