import { AssessmentTimingOption } from "./assessment-timing-option";
import { AssessmentLevelOption } from "./assessment-level-option";

import { SelectedAssessmentTimingText } from "./selected-assessment-timing-text";
import { SelectedAssessmentLevelText } from "./selected-assessment-level-text";

export class AssessmentSelectedMethod {
  private _id: number;
  private _methodId: number;
  private _methodName: string;
  private _description: string;
  private _weight: number;

  private _timings: Array<AssessmentTimingOption>;
  private _levels: Array<AssessmentLevelOption>;

  private _timingText: string;
  private _levelText: string;

  private _isSelectedByCourseOutcome: boolean;

  constructor(
    id: number = null,
    methodId: number = null,
    methodName: string = null,
    description: string = null,
    weight: number = null,
    timingText: string = null,
    levelText: string = null
  ) {
    this._id = id;
    this._methodId = methodId;
    this._methodName = methodName;
    this._description = description;
    this._weight = weight;
    this._timings = [];
    this._levels = [];
    this._isSelectedByCourseOutcome = false;
    this._timingText = timingText;
    this._levelText = levelText;
  }

  /**
   * Getter methodId
   * @return {number}
   */
  public get methodId(): number {
    return this._methodId;
  }

  /**
   * Setter methodId
   * @param {number} value
   */
  public set methodId(value: number) {
    this._methodId = value;
  }

  /**
   * Getter description
   * @return {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

  /**
   * Getter weight
   * @return {number}
   */
  public get weight(): number {
    return this._weight;
  }

  /**
   * Setter weight
   * @param {number} value
   */
  public set weight(value: number) {
    this._weight = value;
  }

  /**
   * Getter id
   * @return {number}
   */
  public get id(): number {
    return this._id;
  }

  /**
   * Setter id
   * @param {number} value
   */
  public set id(value: number) {
    this._id = value;
  }

  /**
   * Getter methodName
   * @return {string}
   */
  public get methodName(): string {
    return this._methodName;
  }

  /**
   * Setter methodName
   * @param {string} value
   */
  public set methodName(value: string) {
    this._methodName = value;
  }

  /**
   * Getter timings
   * @return {Array<AssessmentTimingOption>}
   */
  public get timings(): Array<AssessmentTimingOption> {
    return this._timings;
  }

  /**
   * Setter timings
   * @param {Array<AssessmentTimingOption>} value
   */
  public set timings(value: Array<AssessmentTimingOption>) {
    this._timings = value;
  }

  /**
   * Getter levels
   * @return {Array<AssessmentLevelOption>}
   */
  public get levels(): Array<AssessmentLevelOption> {
    return this._levels;
  }

  /**
   * Setter levels
   * @param {Array<AssessmentLevelOption>} value
   */
  public set levels(value: Array<AssessmentLevelOption>) {
    this._levels = value;
  }

  /**
   * Getter isSelectedByCourseOutcome
   * @return {boolean}
   */
  public get isSelectedByCourseOutcome(): boolean {
    return this._isSelectedByCourseOutcome;
  }

  /**
   * Setter isSelectedByCourseOutcome
   * @param {boolean} value
   */
  public set isSelectedByCourseOutcome(value: boolean) {
    this._isSelectedByCourseOutcome = value;
  }


    /**
     * Getter timingText
     * @return {string}
     */
	public get timingText(): string {
		return this._timingText;
	}

    /**
     * Setter timingText
     * @param {string} value
     */
	public set timingText(value: string) {
		this._timingText = value;
	}

    /**
     * Getter levelText
     * @return {string}
     */
	public get levelText(): string {
		return this._levelText;
	}

    /**
     * Setter levelText
     * @param {string} value
     */
	public set levelText(value: string) {
		this._levelText = value;
	}




}
