export class Level {
  private _id: number;
  private _name: string;
  private _description: string;
  private _abbreviation: string;
  private _colorCode: string;
  private _level: number;

  private _bgColor: string;
  private _fontColor: string;

  constructor(
    id: number = null,
    name: string = null,
    description: string = null,
    abbreviation: string = null,
    colorCode: string = "000000",
    level: number = 0,
    bgColor: string = "#000000",
    fontColor: string = "#ffffff"
  ) {
    this._id = id;
    this._name = name;
    this._description = description;
    this._abbreviation = abbreviation;
    this._colorCode = colorCode;
    this._level = level;
    this._bgColor = bgColor;
    this._fontColor = fontColor;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get abbreviation(): string {
    return this._abbreviation;
  }

  public set abbreviation(value: string) {
    this._abbreviation = value;
  }

  public get colorCode(): string {
    return this._colorCode;
  }

  public set colorCode(value: string) {
    this._colorCode = value;
  }

  public get bgColor(): string {
    return this._bgColor;
  }

  public set bgColor(value: string) {
    this._bgColor = value;
  }

  public get fontColor(): string {
    return this._fontColor;
  }

  public set fontColor(value: string) {
    this._fontColor = value;
  }

  public get level(): number {
    return this._level;
  }

  public set level(value: number) {
    this._level = value;
  }

  public toJSON() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      abbreviation: this.abbreviation,
      colorCode: this.colorCode,
      level: this.level,
      bgColor: this.bgColor,
      fontColor: this.fontColor
    };
  }
}
