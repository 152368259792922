import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError, finalize } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { UtilService } from "app/core/services/util.service";

import { Config } from "app/config";

import { ApplicationInfo } from "app/core/models/application-info";

@Injectable({
  providedIn: "root"
})
export class SystemService {
  private _appInfo = new BehaviorSubject<ApplicationInfo>(null);
  appInfo = this._appInfo.asObservable();

  constructor(
    private httpClient: HttpClient,
    private utilService: UtilService
  ) { }

  getAppInfo() {
    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/app-info")
      .pipe(
        map((appInfo: any) => {
          // console.log(appInfo);


          const applicationInfo = new ApplicationInfo(appInfo.currentVersion,
            appInfo.terms,
            appInfo.title,
            appInfo.subtitle,

            appInfo.mainLogo,
            appInfo.secondaryLogo,
            
            appInfo.displayMainLogo,
            appInfo.displaySecondaryLogo,
            
            appInfo.logoBackgroundColor,
            appInfo.menuBackgroundColor,
            appInfo.userDomainRestricted,
            appInfo.userAllowedDomains,
            appInfo.guestDomainAllowed,
            appInfo.isUnderMaintenance
          );

          // console.log(applicationInfo);

          return applicationInfo;

        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  setAppInfo(appInfo: ApplicationInfo) {
    this._appInfo.next(appInfo);
  }



}
