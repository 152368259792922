<div *ngIf="loading.isLoading;else loaded">
  <app-loading [loadingText]="loading.message"></app-loading>
</div>


<ng-template #loaded>

  <app-load-error *ngIf="loadError; else noErrors" [loadError]="loadError">
  </app-load-error>

  <ng-template #noErrors>


    <div *ngIf="selectedTlaOptions.length == 0; else tla">
      <div class="ui message">
        There are no teaching and learning activities.
      </div>
    </div>

    <ng-template #tla>


      <table class="ui table tla-table">

        <tr class="header-row">
          <td>Teaching and learning activity</td>
          <td *ngIf="tlaFormat.isExtentRequired">Extent of use

            <span class="tooltip-wrapper" data-tooltip="Extent of use" data-inverted="">
              <i class="question circle icon"></i>
            </span>


            <!-- <div class="tooltip">
              <i class="question circle icon"></i>
              <span class="tooltip-text top">Explain Extend of Use here</span>




            </div> -->
          </td>
          <td *ngIf="editable"></td>
        </tr>

        <tr *ngFor="let tla of selectedTlaOptions">
          <td>{{ tla.description }}
            <span *ngIf="tla.additionalText">:
              <br>{{tla.additionalText}}</span>
          </td>
          <td *ngIf="tlaFormat.isExtentRequired">

            <div *ngIf="tlaFormat.extentFormat == 'text'">
              {{tla.extentText}}
            </div>

            <div *ngIf="tlaFormat.extentFormat == 'list'">


              <div *ngIf="tla.extentOption">

                {{ tla.extentOption.description }}

                <span *ngIf="tla.extentOption.otherText">:
                  <br>{{tla.extentOption.otherText}}</span>
              </div>

              <!-- <div *ngIf="!tla.extentOption || !tla.extentOption " class="not-specified">
                      Not Specified
                    </div> -->

            </div>


          </td>
          <td class="button-col" *ngIf="editable">


            <button class="ui basic button mini" (click)="openEditTlaForm(tla)">
              <i class="edit icon"></i>Edit</button>
            <button class="ui basic button mini delete-button" (click)="confirmDeleteTla(tla)">
              <i class="trash alternate outline icon"></i>Delete</button>
          </td>
        </tr>

      </table>



      <!-- <table class="ui table tla-table">
      
          <tr class="sub-header-row">
            <td>Teaching and learning activity</td>
            <td *ngIf="tlaFormat.isExtentRequired">Extent of use
              <div class="tooltip">
                <i class="far fa-question-circle"></i>
                <span class="tooltip-text top">Explain Extend of Use here</span>
              </div>
            </td>
      
          </tr>
      
          <tr *ngFor="let tla of selectedTlaOptions">
            <td>{{ tla.description }}
              <span *ngIf="tla.additionalText">
                :
                <br>{{ tla.additionalText }}
              </span>
      
      
            </td>
            <td *ngIf="tlaFormat.isExtentRequired">
              {{ tla.extentDescription}}
              <span *ngIf="tla.extentDescription">
                :
                <br>{{ tla.extentOtherText}}
              </span>
      
            </td>
          </tr>
      
        </table> -->
    </ng-template>


  </ng-template>

</ng-template>