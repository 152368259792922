import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";

import { chart } from "highcharts";
import { CustomError } from "app/core/models/custom-error";

@Component({
  selector: "app-highchart-horizontal-bar",
  templateUrl: "./highchart-horizontal-bar.component.html",
  styleUrls: ["./highchart-horizontal-bar.component.scss"]
})
export class HighchartHorizontalBarComponent implements OnInit {
  @ViewChild("chartTarget", { static: true })
  chartTarget: ElementRef;

  @Input()
  chartOptions: any;

  chart: any;

  loadError: CustomError;

  constructor() { }

  ngOnInit() {

    const options: Highcharts.Options = this.chartOptions;


    try {
      this.chart = chart(this.chartTarget.nativeElement, options);
    }
    catch (error) {
      this.loadError = new CustomError(null, "Invalid Chart Data", "There was an error while generating the chart. Please try again later.", null);
    }

  }
}
