import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CustomError } from "../models/custom-error";

@Injectable({
  providedIn: "root"
})
export class GlobalErrorService {
  private _globalError = new Subject<CustomError>();
  globalError = this._globalError.asObservable();

  constructor() {
    this._globalError.next(null);
  }

  showError(error: CustomError) {
    this._globalError.next(error);
  }
}
