import { AssessmentSelectedMethod } from "./assessment-selected-method";
import { TlaSelectedOption } from "./tla-selected-option";
import { Question } from "app/core/models/question";
// import { AssessmentMethodValue } from '../assessment/assessment-method-value';
// import { Question } from '../question/question';
// import { OutcomeMapValue } from '../outcome-map-value';

export class CourseOutcome {
  private _id: number;
  private _description: string;

  private _selectedAssessmentMethodIds: Array<number>;
  private _selectedTlaOptionIds: Array<number>;

  private _selectedAssessmentMethods: Array<AssessmentSelectedMethod> = [];
  private _selectedTlaOptions: Array<TlaSelectedOption> = [];

  private _questions: Array<Question>;

  // private _isVisible: boolean;
  // private _isLoading: boolean;
  // private _isAlreadyLoaded: boolean;

  // private _isAssessmentRequired: boolean;
  // private _assessments: Array<AssessmentMethodValue> = [];

  // private _isTlaRequired: boolean;
  // private _tlas: Array<any> = [];

  // private _questions: Array<Question> = [];

  // private _outcomeMaps: Array<OutcomeMapValue> = [];

  //isVisible indicates whether the course outcome details are collapsed
  // private _isVisible: boolean;

  // private _isCompleted: boolean;

  // private _hasNoMapping: boolean;

  constructor(id: number, description: string) {
    this._id = id;
    this._description = description;
    this._selectedAssessmentMethodIds = [];
    this._selectedTlaOptionIds = [];
    // this._isVisible = false;
    // this._isCompleted = false;
    // this._hasNoMapping = false;

    this._selectedAssessmentMethods = [];
    this._selectedTlaOptions = [];
    // this._isVisible = false;
    // this._isLoading = false;
    // this._isAlreadyLoaded = false;
    this._questions = [];
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  /**
   * Getter selectedAssessmentMethodIds
   * @return {Array<number>}
   */
  public get selectedAssessmentMethodIds(): Array<number> {
    return this._selectedAssessmentMethodIds;
  }

  /**
   * Setter selectedAssessmentMethodIds
   * @param {Array<number>} value
   */
  public set selectedAssessmentMethodIds(value: Array<number>) {
    this._selectedAssessmentMethodIds = value;
  }

  /**
   * Getter selectedTlaOptionIds
   * @return {Array<number>}
   */
  public get selectedTlaOptionIds(): Array<number> {
    return this._selectedTlaOptionIds;
  }

  /**
   * Setter selectedTlaOptionIds
   * @param {Array<number>} value
   */
  public set selectedTlaOptionIds(value: Array<number>) {
    this._selectedTlaOptionIds = value;
  }

  /**
   * Getter selectedAssessmentMethods
   * @return {Array<AssessmentSelectedMethod> }
   */
  public get selectedAssessmentMethods(): Array<AssessmentSelectedMethod> {
    return this._selectedAssessmentMethods;
  }

  /**
   * Setter selectedAssessmentMethods
   * @param {Array<AssessmentSelectedMethod> } value
   */
  public set selectedAssessmentMethods(value: Array<AssessmentSelectedMethod>) {
    this._selectedAssessmentMethods = value;
  }

  /**
   * Getter selectedTlaOptions
   * @return {Array<SelectedTlaOption> }
   */
  public get selectedTlaOptions(): Array<TlaSelectedOption> {
    return this._selectedTlaOptions;
  }

  /**
   * Setter selectedTlaOptions
   * @param {Array<SelectedTlaOption> } value
   */
  public set selectedTlaOptions(value: Array<TlaSelectedOption>) {
    this._selectedTlaOptions = value;
  }

  public get questions(): Array<Question> {
    return this._questions;
  }

  public set questions(value: Array<Question>) {
    this._questions = value;
  }

  // public get assessments(): Array<any> {
  // 	return this._assessments;
  // }

  // public set assessments(value: Array<any>) {
  // 	this._assessments = value;
  // }

  // public get tlas(): Array<any> {
  // 	return this._tlas;
  // }

  // public set tlas(value: Array<any>) {
  // 	this._tlas = value;
  // }

  // public get questions(): Array<Question> {
  // 	return this._questions;
  // }

  // public set questions(value: Array<Question>) {
  // 	this._questions = value;
  // }

  // public get isAssessmentRequired(): boolean {
  // 	return this._isAssessmentRequired;
  // }

  // public set isAssessmentRequired(value: boolean) {
  // 	this._isAssessmentRequired = value;
  // }

  // public get isTlaRequired(): boolean {
  // 	return this._isTlaRequired;
  // }

  // public set isTlaRequired(value: boolean) {
  // 	this._isTlaRequired = value;
  // }

  // public get isVisible(): boolean {
  //   return this._isVisible;
  // }

  // public set isVisible(value: boolean) {
  //   this._isVisible = value;
  // }

  // /**
  //  * Getter isLoading
  //  * @return {boolean}
  //  */
  // public get isLoading(): boolean {
  //   return this._isLoading;
  // }

  // /**
  //  * Setter isLoading
  //  * @param {boolean} value
  //  */
  // public set isLoading(value: boolean) {
  //   this._isLoading = value;
  // }

  // /**
  //  * Getter isAlreadyLoaded
  //  * @return {boolean}
  //  */
  // public get isAlreadyLoaded(): boolean {
  //   return this._isAlreadyLoaded;
  // }

  // /**
  //  * Setter isAlreadyLoaded
  //  * @param {boolean} value
  //  */
  // public set isAlreadyLoaded(value: boolean) {
  //   this._isAlreadyLoaded = value;
  // }

  // public get outcomeMaps(): Array<OutcomeMapValue> {
  // 	return this._outcomeMaps;
  // }

  // public set outcomeMaps(value: Array<OutcomeMapValue>) {
  // 	this._outcomeMaps = value;
  // }

  // public get isCompleted(): boolean {
  // 	return this._isCompleted;
  // }

  // public set isCompleted(value: boolean) {
  // 	this._isCompleted = value;
  // }

  // public get hasNoMapping(): boolean {
  // 	return this._hasNoMapping;
  // }

  // public set hasNoMapping(value: boolean) {
  // 	this._hasNoMapping = value;
  // }
}
