import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Config } from "../../config";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { CurrentUserService } from "app/core/services/current-user.service";
import { UtilService } from "../services/util.service";
import { map, catchError, finalize } from "rxjs/operators";
import { CustomError } from "../models/custom-error";


@Injectable({
  providedIn: "root"
})
export class AuthService {
  // refreshingToken: boolean = false;

  // failedRequests: Array<HttpRequest<any>> = [];
  isRefreshingToken: boolean = false;
  tokenRefreshed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private httpClient: HttpClient,
    private utilService: UtilService,
    private currentUserService: CurrentUserService,
  ) { }

  public getLocalToken(): string {
    return localStorage.getItem("access_token");
  }

  public getRefreshToken(): string {
    return localStorage.getItem("refresh_token");
  }

  // isRefreshingToken() {
  //   return this.isRefreshingToken;
  // }

  public removeToken(): void {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("guest_token");
  }

  public logout() {

    // console.log("removing token..");
    this.removeToken();

    this.currentUserService.logoutUser();

    //send logout request to API
  }

  public isAuthenticated(): boolean {
    return localStorage.getItem("access_token") ? true : false;
  }

  /******************************************************************
   * Get access token by sending username and password to API server
   ******************************************************************/
  /**
   * 
   * @param username 
   * @param password 
   */
  public getAccessToken(username: string, password: string): any {

    //remove tokens if exist
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("guest_token");


    let body = {
      username: username,
      password: password
    };

    return this.httpClient
      .post(Config.BASE_API_URL_V1 + "/login", body)
      .pipe(
        map((token: any) => {
  
          //store this token in local storage

          this.storeTokenInLocalStorage(token);
          return token;
        }),
        catchError(error => {
          return throwError(error);
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param guestToken 
   */
  public loginAsGuestUser(guestToken) {
    // console.log(guestToken);

    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/guest/verify/" + guestToken)
      .pipe(
        map(
          (userData: any) => {
            // console.log(userData);

            this.storeGuestToken(guestToken);

            if (Object.keys(userData).length > 0) {
              this.storeAccessTokenForGuestInLocalStorage(
                userData.personalAccessToken
              );
              return userData;
            }
            return null;
          },
          catchError(error => {
            // console.log(error);
            return throwError(this.utilService.handleApiError(error));
            // return;
          })
        )
      )
      .toPromise();
  }

  /**
   * 
   * @param token 
   */
  public storeAccessTokenForGuestInLocalStorage(token) {

    localStorage.setItem("access_token", token);
  }

  /**
   * 
   * @param guestToken 
   */
  public storeGuestToken(guestToken) {

    localStorage.setItem("guest_token", guestToken);
  }

  /**
   * 
   */
  public getGuestToken() {
    return localStorage.getItem("guest_token")
      ? localStorage.getItem("guest_token")
      : "";
  }

  /**
   * 
   * @param token 
   */
  public storeTokenInLocalStorage(token) {

    const currentTimeStamp = new Date().getTime();


    localStorage.setItem("access_token", token.access_token);
  }

  /**
   * 
   * @param userInfo 
   * @param callback 
   */
  public createUser(userInfo, callback) {

    const data = {
      userInfo: userInfo,
      callback: callback
    };

    return this.httpClient
      .post(Config.BASE_API_URL_V1 + "/register", data)
      .pipe(
        map(data => {
          // console.log(data);
          return data;
        }),
        catchError(error => {
          console.log(error);

          if (error.status == 591) {
            //duplicate email error
            return throwError(
              new CustomError(
                "400",
                "Duplicate Email",
                "User with the email address already exists. If you have forgotten your password, please <a href='/auth/password/forgot'>reset your password</a>.",
                null
              )
            );
          }
          else if (error.status == 592) {
            return throwError(
              new CustomError(
                "400",
                "Invalid Email Address",
                "Domain name of the email address is not allowed.",
                null
              )
            );

          }

          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param activation_token 
   */
  public activateUser(activation_token) {
    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/activate/" + activation_token)
      .pipe(
        map(
          accessToken => {
            return accessToken;
          },
          catchError(error => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
      )
      .toPromise();
  }

  /**
   * 
   * @param email 
   * @param callback 
   */
  public requestPasswordReset(email, callback) {
    const data = {
      email: email,
      callback: callback
    };

    return this.httpClient
      .post(Config.BASE_API_URL_V1 + "/password/forgot", data)
      .pipe(
        map(
          result => {
            return result;
          },
          catchError(error => {
            console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
      )
      .toPromise();
  }

  /**
   * 
   * @param token 
   */
  public verifyPasswordResetToken(token) {
    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/password/validate-token/" + token)
      .pipe(
        map(result => {
          return result; //true if valid
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param email 
   * @param newPassword 
   * @param token 
   */
  public resetPassword(email, newPassword, token) {
    const data = {
      email: email,
      password: newPassword,
      token: token
    };

    return this.httpClient
      .post(Config.BASE_API_URL_V1 + "/password/reset", data)
      .pipe(
        map(result => {
          // console.log(result);
          return result;
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * 
   * @param email 
   * @param callback 
   */
  public resendConfirmation(email, callback) {


    const data = {
      email: email,
      callback: callback
    };


    return this.httpClient.post(Config.BASE_API_URL_V1 + "/resend", data)
      .pipe(
        map(
          result => {
            return result;
          },
          catchError(error => {
            console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
      )
      .toPromise();

  }

}
