import { Component, OnInit, Input } from "@angular/core";
import { CourseOutcome } from "app/core/models/course-outcome";
import { AssessmentSelectedMethod } from "app/core/models/assessment-selected-method";
import { AssessmentFormat } from "app/core/models/assessment-format";
import { TlaFormat } from "app/core/models/tla-format";

import { TlaSelectedOption } from "app/core/models/tla-selected-option";
import { TlaExtentOption } from "app/core/models/tla-extent-option";

//services
import { CourseOutcomeService } from "app/core/services/course-outcome.service";
import { Loading } from "app/core/models/loading";

import { AssessmentService } from "app/core/services/assessment.service";
import { TlaService } from "app/core/services/tla.service";
import { CustomError } from "app/core/models/custom-error";


@Component({
  selector: "app-course-outcome-list",
  templateUrl: "./course-outcome-list.component.html",
  styleUrls: ["./course-outcome-list.component.scss"]
})
export class CourseOutcomeListComponent implements OnInit {
  @Input()
  courseId: number;
  @Input()
  assessmentFormat: AssessmentFormat;
  @Input()
  tlaFormat: TlaFormat;

  courseOutcomes: Array<CourseOutcome> = [];
  editable: boolean = false;
  loading: Loading;

  assessmentMethodsForCourse: Array<AssessmentSelectedMethod> = [];
  selectedAssessmentIdsArray: Array<any> = [];


  tlaOptionsForCourse: Array<TlaSelectedOption> = [];
  selectedTlaIdsArray: Array<any> = [];

  loadError: CustomError;

  constructor(
    private courseOutcomeService: CourseOutcomeService,
    private assessmentService: AssessmentService,
    private tlaService: TlaService
  ) {}

  async ngOnInit() {
    this.loading = new Loading(true, "Loading course outcomes...");

    try{
      this.courseOutcomes = await this.courseOutcomeService.getCourseOutcomes(
        this.courseId
      );
  
      //get assessment for each course outcome
      if (this.assessmentFormat.isEnabled && this.assessmentFormat.requiredByCourseOutcome) {
        if (this.assessmentFormat.format == "list") {
          //get selected assessment methods in step 1
          //let currently selected assessment methods
          this.assessmentMethodsForCourse = await this.assessmentService.getSelectedAssessmentMethods(
            this.courseId,
            this.assessmentFormat.id
          );
        } else if (this.assessmentFormat.format == "text") {
          this.assessmentMethodsForCourse = await this.assessmentService.getAssessmentMethodsTextForCourse(
            this.courseId,
            this.assessmentFormat.id
          );
        }
  
        //format is {id, courseOutcomeId, selectedAssessmentId, selectedAssessmentTextId, courseId} by all  course outcomes from API
        let selectedAssessmentIdsData: Array<
          any
        > = await this.assessmentService.getAllSelectedAssessmentMethodOptionsByCourseOutcomes(
          this.courseId,
          this.assessmentFormat.format
        );
  
        //convert the data above to key value array
        this.selectedAssessmentIdsArray =  this.assessmentService.getAllSelectedAssessmentIdsByCourseOutcomes(
          this.courseOutcomes,
          this.assessmentMethodsForCourse,
          selectedAssessmentIdsData,
          this.assessmentFormat.format
        );
  
  
        // console.log(selectedAssessmentIdsData);
        // console.log(this.selectedAssessmentIdsArray);
  
      }
  
  
        //get tla options if required
        if (this.tlaFormat.isEnabled && this.tlaFormat.requiredByCourseOutcome) {
          if (this.tlaFormat.format == "list") {
            this.tlaOptionsForCourse = await this.tlaService.getSelectedTlaOptionsForCourse(
              this.courseId,
              this.tlaFormat.id
            );
          } else if (this.tlaFormat.format == "text") {
            this.tlaOptionsForCourse = await this.tlaService.getSelectedTlaOptionsText(
              this.courseId,
              this.tlaFormat.id
            );
          }
  
          // console.log(this.tlaOptionsForCourse);
  
          let selectedTlaOptionIdsData: Array<
            any
          > = await this.tlaService.getAllSelectedTlaOptionsByCourseOutcomes(
            this.courseId,
            this.tlaFormat.format
          );
  
          // console.log(selectedTlaOptionIdsData);
  
          //convert the above data to array
          //conver the data above to key value array
          this.selectedTlaIdsArray = await this.tlaService.getAllSelectedTlaIdsByCourseOutcomes(
            this.courseOutcomes,
            this.tlaOptionsForCourse,
            selectedTlaOptionIdsData,
            this.tlaFormat.format
          );
  
          // console.log( this.selectedTlaIdsArray );
  
  
        }
  
  
    }
    catch(error){

      this.loadError = error;
    }
    finally{
      this.loading.isLoading = false;
    }


  }
}
