import { Injectable } from "@angular/core";
// import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Config } from "app/config";

//models
import { Level } from "app/core/models/level";
import { ProgramOutcome } from "app/core/models/program-outcome";
import { OutcomeMapValue } from "app/core/models/outcome-map-value";
import { AnalysisQuestion } from "app/core/models/analysis-question";

import { map, catchError, finalize } from "rxjs/operators";

//services
import { UtilService } from "app/core/services/util.service";

@Injectable({
  providedIn: 'root'
})
export class MappingService {
  constructor(
    private utilService: UtilService,
    private httpClient: HttpClient
  ) { }

  /*******************************************
   * UPDATED: Get mapping scale levels
   * Error: Checked
   ******************************************/
  getScaleLevels(reviewId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels",
        // httpOptions
      )
      .pipe(
        map((levels: any) => {
          //console.log(levels);

          let mappingLevels: Array<Level> = [];

          levels.forEach(level => {
            let newLevel = new Level(
              level.id,
              level.name,
              level.description,
              level.abbreviation,
              level.colorCode,
              level.level
            );
            if (!level.colorCode) {
              level.colorCode = "000000";
            }
            newLevel.bgColor = this.utilService.getHexColor(level.colorCode);

            //console.log(newLevel.bgColor);

            newLevel.fontColor = this.utilService.getFontHexColor(
              level.colorCode
            );

            mappingLevels.push(newLevel);
          });

          return mappingLevels;
        }),
        catchError((error: any) => {
          console.log(error);

          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  getScaleLevelsForCourse(courseId: number) {

    const token = localStorage.getItem("token");

    // const headers = new Headers({ "x-access-token": token });
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json"
    //     // "x-access-token": token
    //   })
    // };

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/mapping-levels",
        // httpOptions
      )
      .pipe(
        map((levels: any) => {
          //console.log(levels);

          let mappingLevels: Array<Level> = [];

          levels.forEach(level => {
            let newLevel = new Level(
              level.id,
              level.name,
              level.description,
              level.abbreviation,
              level.colorCode,
              level.level
            );
            if (!level.colorCode) {
              level.colorCode = "000000";
            }
            newLevel.bgColor = this.utilService.getHexColor(level.colorCode);

            //console.log(newLevel.bgColor);

            newLevel.fontColor = this.utilService.getFontHexColor(
              level.colorCode
            );

            mappingLevels.push(newLevel);
          });

          return mappingLevels;
        }),
        catchError((error: any) => {
          console.log(error);
          error.customErrorDescription = "Could not load mapping scale levels.";
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************************
   * UPDATED: Get mapping scale level examples
   * Error: Checked
   **********************************************************/
  getScaleExampleLevels(reviewId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/examples"
      )
      .pipe(
        map((examples: any) => {
          console.log(examples);

          let mappingLevelExamples: Array<any> = [];


          Object.keys(examples).forEach(key => {

            console.log(key);
            console.log(examples[key]);



            let groupedExample: { 'group': string, 'levels': Array<Level> } = { 'group': null, 'levels': [] };

            groupedExample.group = examples[key].group.description;





            examples[key].levels.forEach(level => {
              let newExampleLevel = new Level(
                level.id,
                level.name,
                level.description,
                level.abbreviation,
                level.colorCode,
                level.level
              );
              newExampleLevel.bgColor = this.utilService.getHexColor(
                level.colorCode
              );
              newExampleLevel.fontColor = this.utilService.getFontHexColor(
                level.colorCode
              );

              groupedExample.levels.push(newExampleLevel);
            });



            mappingLevelExamples.push(groupedExample);
          });




          return mappingLevelExamples;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************************
   * UPDATED: Post single mapping scale level
   * Error: Checked
   **********************************************************/
  postMappingScaleLevel(reviewId: number, level: Level) {
    const body = {
      reviewId: reviewId,
      name: level.name,
      level: level.level,
      abbreviation: level.abbreviation,
      description: level.description,
      colorCode: level.colorCode
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels",
        body
      )
      .pipe(
        map((result: any) => {
          return;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      );
  }

  /******************************************************
   * Update order of levels
   ******************************************************/
  updateLevelOrder(reviewId: number, order: Array<any>) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    const body = {
      order: order
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/reorder",
        body,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          return;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /***************************************************************
   * UPDATED: Delete single mapping scale level for the review
   *****************************************************************/
  deleteMappingScaleLevel(reviewId: number, levelId: number) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/" +
        levelId,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          //console.log(result);
          return;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      );
  }

  /***************************************************************
   * UPDATED: Update a single mapping scale level for the review
   *****************************************************************/
  updateMappingScaleLevel(reviewId: number, level: Level) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    const body = {
      reviewId: reviewId,
      name: level.name,
      abbreviation: level.abbreviation,
      description: level.description,
      colorCode: level.colorCode
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/" +
        level.id,
        body,
        httpOptions
      )
      .pipe(
        map((result: any) => {
          //console.log(result);
          return;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      );
  }

  /***************************************************************
   * UPDATED: Delete all mapping scale levels for the review
   *****************************************************************/
  deleteAllMappingScaleLevels(reviewId: number) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels",
        httpOptions
      )
      .pipe(
        map((result: any) => {
          //console.log(result);
          return;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      ).toPromise();
  }
  /*****************************************
   * UPDATED: Get color palette
   *******************************************/
  getColorPalettes(reviewId: number) {
    const token = localStorage.getItem("token");
    const headers = new Headers({ "x-access-token": token });
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
        // "x-access-token": token
      })
    };

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/color-palettes",
        httpOptions
      )
      .pipe(
        map((colorPalettesArray: any) => {
          //console.log(colorPalettesArray);

          let colorPalettes = [];

          //convert this array to the array format we need
          Object.keys(colorPalettesArray).forEach(key => {
            let groupedColorPalettes: Array<Level> = [];

            colorPalettesArray[key].forEach(colors => {
              groupedColorPalettes.push(colors);
            });

            colorPalettes.push(groupedColorPalettes);
          });

          return colorPalettes;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  getColorPalettesForSelectedColor(reviewId: number, hex: string) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/mapping-levels/color-palettes/" + hex,

      )
      .pipe(
        map((colorPalettesArray: any) => {
          // console.log(colorPalettesArray);

          // let colorPalettes = [];

          //convert this array to the array format we need
          // Object.keys(colorPalettesArray).forEach(key => {
          //   let groupedColorPalettes: Array<Level> = [];

          //   colorPalettesArray[key].forEach(colors => {
          //     groupedColorPalettes.push(colors);
          //   });

          //   colorPalettes.push(groupedColorPalettes);
          // });

          return colorPalettesArray;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();


  }

  /*************************************************
   * Get outcome map values for a course outcome
   *************************************************/

  getOutcomeMapValues(courseId: number, courseOutcomeId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcomes/" +
        courseOutcomeId +
        "/outcome-maps"
      )
      .pipe(
        map((data: Array<any>) => {
          let outcomeMapValues: Array<OutcomeMapValue> = [];

          data.forEach(outcomeMap => {
            outcomeMapValues.push(
              new OutcomeMapValue(
                outcomeMap.programOutcomeId,
                outcomeMap.levelId
              )
            );
          });

          return outcomeMapValues;
        }),
        catchError((error: any) => {
          console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************************
   * Update Course outcome maps
   * Error: Checked
   ***************************************/
  updateOutcomeMaps(
    courseId: number,
    courseOutcomeId: number,
    outcomeMaps: Array<any>
  ) {
    //console.log(outcomeMaps);

    let outcomeMapsArray = [];

    for (let ploId of Object.keys(outcomeMaps)) {
      //console.log(ploId);
      //console.log(outcomeMaps[ploId]);

      if (outcomeMaps[ploId]) {
        outcomeMapsArray.push({
          courseOutcomeId: courseOutcomeId,
          programOutcomeId: ploId,
          levelId: outcomeMaps[ploId].id
        });
      }
    }

    const body = { outcomeMaps: outcomeMapsArray };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcomes/" +
        courseOutcomeId +
        "/outcome-maps",
        outcomeMapsArray
      )
      .pipe(
        map((data: Array<{ levelId: number; programOutcomeId: number }>) => {
          //console.log(data);
          return data;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();

    // let outcomeMapsArray = [];

    // const token = localStorage.getItem("token");
    // const headers = new Headers({ "x-access-token": token });

    // const options = new RequestOptions({ headers: headers });
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json"
    //     // "x-access-token": token
    //   })
    // };

    // let outcomeMapArray = [];

    // //console.log(outcomeMaps);

    // for (let key of Object.keys(outcomeMaps)) {
    //   if (outcomeMaps[key] && outcomeMaps[key] != -1) {
    //     outcomeMapArray.push({
    //       courseOutcomeId: courseOutcomeId,
    //       programOutcomeId: key,
    //       levelId: outcomeMaps[key]
    //     });
    //   }
    // }

    // // outcomeMaps.forEach(outcomeMap => {
    // //   outcomeMapArray.push({
    // //     courseOutcomeId: courseOutcomeId,
    // //     programOutcomeId: outcomeMap.programOutcomeId,
    // //     levelId: outcomeMap.levelId
    // //   });
    // // });

    // const body = { outcomeMaps: outcomeMaps };

    // return this.httpClient
    //   .post(
    //     Config.BASE_API_URL_V1 +
    //       "/courses/" +
    //       courseId +
    //       "/course-outcomes/" +
    //       courseOutcomeId +
    //       "/outcome-maps",
    //     outcomeMapArray,
    //     httpOptions
    //   )
    //   .pipe(
    //     map((data: Array<{ levelId: number; programOutcomeId: number }>) => {
    //       //console.log(data);
    //       return data;
    //     }),
    //     catchError((error: any) => {
    //       return throwError(this.utilService.handleApiError(error));
    //     })
    //   );
  }


  /**
 * Get analysis questions
 * @param reviewId 
 * @param courseId 
 * @param callbackUrl 
 */

  getAnalysisQuestions(reviewId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 + "/curriculum-reviews/" + reviewId + "/courses/analysis-questions"
      )
      .pipe(
        map((analysisQuestionsData: Array<any>) => {
          // console.log(analysisQuestionsData);

          let analysisQuestions: Array<AnalysisQuestion> = [];

          for (let question of analysisQuestionsData) {
            analysisQuestions.push(new AnalysisQuestion(question.id, question.question));
          }


          return analysisQuestions;
        }),
        catchError((error: any) => {
          console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  // /**
  //  *
  //  *
  //  *
  //  *
  //  *
  //  * DEPRECATED
  //  *
  //  *
  //  *
  //  *
  //  */
  // /****************************
  //  * Get mapping scale levels
  //  * @param courseId
  //  ***************************/
  // getLevelsByReviewId(reviewId: number) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   let levels: Array<Level> = [];

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/curriculum-reviews/" +
  //         reviewId +
  //         "/mapping-levels",
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(scaelData => {
  //       let scaleLevels: Array<Level> = [];

  //       if (scaelData && scaelData.length > 0) {
  //         scaleLevels = scaelData.map(scaleLevel => {
  //           const bgColor = this.utilService.getColor(scaleLevel.colorCode);
  //           const fontColor = this.utilService.getFontColor(
  //             scaleLevel.colorCode
  //           );

  //           const newLevel = new Level(
  //             scaleLevel.id,
  //             scaleLevel.name,
  //             scaleLevel.description,
  //             scaleLevel.abbreviation,
  //             scaleLevel.colorCode,
  //             scaleLevel.level
  //           );
  //           newLevel.bgColor = this.utilService.getColor(scaleLevel.colorCode);
  //           newLevel.fontColor = this.utilService.getFontColor(
  //             scaleLevel.colorCode
  //           );

  //           return newLevel;
  //         });
  //       }

  //       return scaleLevels;
  //     })
  //     .catch((error: any) => {
  //       return throwError(
  //         this.utilService.handleError(error, "mapping scale")
  //       );
  //     });
  // }

  // /****************************
  //  * Get mapping scale levels
  //  * @param courseId
  //  ***************************/
  // getLevels(courseId: number) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   let levels: Array<Level> = [];

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL + "/api/user/courses/" + courseId + "/levels",
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(data => {
  //       if (data && data.length > 0) {
  //         data.forEach(level => {
  //           levels.push(
  //             new Level(
  //               level.id,
  //               level.name,
  //               level.description,
  //               level.abbreviation,
  //               level.colorCode,
  //               level.level
  //             )
  //           );
  //         });
  //       }
  //       return levels;
  //     })
  //     .catch((error: any) => {
  //       return throwError(error.json());
  //     });
  // }

  // /********************************************************************
  //  * Get Array<OutcomeMapValue> for the selected courseOutcomeId - CHNAGE THIS!
  //  ********************************************************************/

  // getOutcomeMap(
  //   programOutcomeId: number,
  //   courseId: number,
  //   courseOutcomeId: number
  // ) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/courses/" +
  //         courseId +
  //         "/outcome-maps/course-outcomes/" +
  //         courseOutcomeId +
  //         "/program-outcomes/" +
  //         programOutcomeId,
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(data => {
  //       if (data && data.length > 0) {
  //         //console.log(data);
  //       }

  //       let level: Level = null;

  //       if (data && data.length > 0) {
  //         level = new Level(
  //           data[0].id,
  //           data[0].name,
  //           data[0].description,
  //           data[0].abbreviation,
  //           data[0].colorCode,
  //           data[0].level
  //         );
  //       }

  //       return level;
  //     })
  //     .catch((error: any) => {
  //       return throwError(error.json());
  //     });
  // }
}
