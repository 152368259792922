export class ApplicationInfo {
  private _currentVersion: number;
  private _terms: string; //json
  private _title: string;
  private _subtitle: string;

  private _mainLogo: string;
  private _secondaryLogo: string;

  private _displayMainLogo: boolean;
  private _displaySecondaryLogo: boolean;

  private _logoBackgroundColor: string;
  private _menuBackgroundColor: string;
  private _userDomainRestricted: boolean;
  private _userAllowedDomains: string;
  private _guestDomainAllowed: boolean;
  private _isUnderMaintenance: boolean;

  constructor(currentVersion: number = 0,
    terms: string = null,
    title: string = null,
    subtitle: string = null,

    mainLogo: string = null,
    secondaryLogo: string = null,

    displayMainLogo: boolean = false,
    displaySecondaryLogo: boolean = false,

    logoBackgroundColor: string = null,
    menuBackgroundColor: string = null,
    userDomainRestricted: boolean = false,
    userAllowedDomains: string = null,
    guestDomainAllowed: boolean = false,
    isUnderMaintenance: boolean = false

  ) {
    this._currentVersion = currentVersion;
    this._terms = terms;
    this._title = title;
    this._subtitle = subtitle;

    this._mainLogo = mainLogo;
    this._secondaryLogo = secondaryLogo;


    this._displayMainLogo = displayMainLogo;
    this._displaySecondaryLogo = displaySecondaryLogo;

    this._logoBackgroundColor = logoBackgroundColor ? logoBackgroundColor : "#9a9a9a";
    this._menuBackgroundColor = menuBackgroundColor ? menuBackgroundColor : "#7d7d7d";

    this._userDomainRestricted = userDomainRestricted;
    this._userAllowedDomains = userAllowedDomains;
    this._guestDomainAllowed = guestDomainAllowed;
    this._isUnderMaintenance = isUnderMaintenance;


  }

  /**
   * Getter currentVersion
   * @return {number}
   */
  public get currentVersion(): number {
    return this._currentVersion;
  }

  /**
   * Setter currentVersion
   * @param {number} value
   */
  public set currentVersion(value: number) {
    this._currentVersion = value;
  }

  /**
   * Getter title
   * @return {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Getter subtitle
   * @return {string}
   */
  public get subtitle(): string {
    return this._subtitle;
  }

  /**
   * Setter subtitle
   * @param {string} value
   */
  public set subtitle(value: string) {
    this._subtitle = value;
  }

  /**
   * Getter terms
   * @return {string}
   */
  public get terms(): string {
    return this._terms;
  }

  /**
   * Setter terms
   * @param {string} value
   */
  public set terms(value: string) {
    this._terms = value;
  }

  /**
   * Getter logoBackgroundColor
   * @return {string}
   */
  public get logoBackgroundColor(): string {
    return this._logoBackgroundColor;
  }

  /**
   * Setter logoBackgroundColor
   * @param {string} value
   */
  public set logoBackgroundColor(value: string) {
    this._logoBackgroundColor = value;
  }

  /**
   * Getter menuBackgroundColor
   * @return {string}
   */
  public get menuBackgroundColor(): string {
    return this._menuBackgroundColor;
  }

  /**
   * Setter menuBackgroundColor
   * @param {string} value
   */
  public set menuBackgroundColor(value: string) {
    this._menuBackgroundColor = value;
  }

  /**
   * Getter userDomainRestricted
   * @return {boolean}
   */
  public get userDomainRestricted(): boolean {
    return this._userDomainRestricted;
  }

  /**
   * Setter userDomainRestricted
   * @param {boolean} value
   */
  public set userDomainRestricted(value: boolean) {
    this._userDomainRestricted = value;
  }

  /**
   * Getter userAllowedDomains
   * @return {string}
   */
  public get userAllowedDomains(): string {
    return this._userAllowedDomains;
  }

  /**
   * Setter userAllowedDomains
   * @param {string} value
   */
  public set userAllowedDomains(value: string) {
    this._userAllowedDomains = value;
  }

  /**
   * Getter guestDomainAllowed
   * @return {boolean}
   */
  public get guestDomainAllowed(): boolean {
    return this._guestDomainAllowed;
  }

  /**
   * Setter guestDomainAllowed
   * @param {boolean} value
   */
  public set guestDomainAllowed(value: boolean) {
    this._guestDomainAllowed = value;
  }

  /**
   * Getter isUnderMaintenance
   * @return {boolean}
   */
  public get isUnderMaintenance(): boolean {
    return this._isUnderMaintenance;
  }

  /**
   * Setter isUnderMaintenance
   * @param {boolean} value
   */
  public set isUnderMaintenance(value: boolean) {
    this._isUnderMaintenance = value;
  }

  /**
   * Getter mainLogo
   * @return {string}
   */
  public get mainLogo(): string {
    return this._mainLogo;
  }

  /**
   * Setter mainLogo
   * @param {string} value
   */
  public set mainLogo(value: string) {
    this._mainLogo = value;
  }

  /**
   * Getter secondaryLogo
   * @return {string}
   */
  public get secondaryLogo(): string {
    return this._secondaryLogo;
  }

  /**
   * Setter secondaryLogo
   * @param {string} value
   */
  public set secondaryLogo(value: string) {
    this._secondaryLogo = value;
  }

  /**
   * Getter displayMainLogo
   * @return {boolean}
   */
  public get displayMainLogo(): boolean {
    return this._displayMainLogo;
  }

  /**
   * Setter displayMainLogo
   * @param {boolean} value
   */
  public set displayMainLogo(value: boolean) {
    this._displayMainLogo = value;
  }

  /**
   * Getter displaySecondaryLogo
   * @return {boolean}
   */
  public get displaySecondaryLogo(): boolean {
    return this._displaySecondaryLogo;
  }

  /**
   * Setter displaySecondaryLogo
   * @param {boolean} value
   */
  public set displaySecondaryLogo(value: boolean) {
    this._displaySecondaryLogo = value;
  }


}
