<div *ngIf="actionError" class="modal-action-error">
  <div class="action-error-header">
    <i class="exclamation triangle icon"></i> There was an error processing your request. Please try again later.
  </div>
  <!-- <div class="action-error-title">
    
  </div> -->
  <div class="action-error-description">
    {{actionError.title}}: {{ actionError.description.length > 300 ? actionError.description.substring(0, 300) + "...":
    actionError.description
    }}
  </div>

</div>
