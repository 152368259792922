import { Component, OnInit, Input } from '@angular/core';
import { CustomError } from "app/core/models/custom-error";

@Component({
  selector: 'app-modal-action-error',
  templateUrl: './modal-action-error.component.html',
  styleUrls: ['./modal-action-error.component.scss']
})
export class ModalActionErrorComponent implements OnInit {

  @Input()
  actionError: CustomError;

  constructor() { }

  ngOnInit() {
  }

}
