<div *ngIf="loading.isLoading;else loaded">
  <app-loading [loadingText]="loading.message"></app-loading>
</div>


<ng-template #loaded>

  <app-load-error *ngIf="loadError; else noErrors" [loadError]="loadError">
  </app-load-error>

  <ng-template #noErrors>

    <div *ngIf="questions.length == 0; else questionsList">
      <div class="ui message">
        There are no questions about this course for the curriculum review.
      </div>
    </div>

    <ng-template #questionsList>
      <table class="ui table">

        <ng-container *ngFor="let question of questions; let i = index;">

          <tr>
            <td>
              <div class="question-section">
                {{i+1}}. {{question.question}}
              </div>
              <div class="answer-section">
                <!-- <div class="answer-title">Answer</div> -->
                <div *ngIf="question.format == 'text'">

                  <div *ngIf="question.textAnswer" class="answer-text">

                    <div *ngIf="!question.textAnswer.textAnswer" class="answer-text no-answer">
                      No answer.
                    </div>

                    <div>
                      {{question.textAnswer.textAnswer}}
                    </div>

                  </div>

                </div>

                <div *ngIf="question.format == 'checkbox' || question.format == 'radio'">

                  <ul class="answer-option-list">
                    <li *ngFor="let option of question.options"
                      [ngClass]="{'not-selected': !option.isSelected, 'selected': option.isSelected}">
                      <i class="check icon" *ngIf="option.isSelected"></i>
                      <i class="times icon" *ngIf="!option.isSelected"></i>
                      {{option.description}}

                      <span *ngIf="option.isSelected"> (Selected)</span>
                      <span *ngIf="!option.isSelected"> (Not Selected)</span>
                      <span *ngIf="option.isSelected && option.otherTextRequired">:&nbsp;{{option.otherText}}</span>
                    </li>
                  </ul>

                </div>

              </div>

            </td>
          </tr>


        </ng-container>

      </table>


    </ng-template>

  </ng-template>

</ng-template>