import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
  Output
} from "@angular/core";


import { chart } from "highcharts";
import { CustomError } from "app/core/models/custom-error";


@Component({
  selector: "app-highchart-vertical-bar",
  templateUrl: "./highchart-vertical-bar.component.html",
  styleUrls: ["./highchart-vertical-bar.component.scss"]
})
export class HighchartVerticalBarComponent implements OnInit, OnChanges {
  @ViewChild("chartTarget", { static: true })
  chartTarget: ElementRef;


  //TODO Change thses to setter ang getter
  @Input()
  chartData: any;
  @Input()
  chartType: string;
  @Input()
  withLegend: boolean;
  @Input()
  chartOptions: any;

  chart: Highcharts.Chart;
  loadError: CustomError;



  constructor() { }

  ngOnChanges(changes) {


    if (this.chart) {
      if (changes.chartType) {

        this.chart.update({
          plotOptions: {
            column: {
              stacking: this.chartType == "stacked" ? "normal" : undefined
            }
          }
        });

      }
      if (changes.chartData) {
    
        this.chart.update({
          series: this.chartData
        });

    

      }

      if (changes.chartOptions) {

        this.chart.update(this.chartOptions);

      }
    }




  }

  ngOnInit() {

    const options: Highcharts.Options = this.chartOptions;

    try {
      this.chart = chart(this.chartTarget.nativeElement, options);
    }
    catch (error) {

      this.loadError = new CustomError(null, "Invalid Chart Data", "There was an error while generating the chart. Please try again later.", null);
    }

  }



}
