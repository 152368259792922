import { Course } from "app/core/models/course";

export class UserCourse extends Course {
  private _review: string;
  private _program: string;
  private _faculty: string;

  /**
   * Getter review
   * @return {string}
   */
  public get review(): string {
    return this._review;
  }

  /**
   * Setter review
   * @param {string} value
   */
  public set review(value: string) {
    this._review = value;
  }

  /**
   * Getter program
   * @return {string}
   */
  public get program(): string {
    return this._program;
  }

  /**
   * Setter program
   * @param {string} value
   */
  public set program(value: string) {
    this._program = value;
  }

  /**
   * Getter faculty
   * @return {string}
   */
  public get faculty(): string {
    return this._faculty;
  }

  /**
   * Setter faculty
   * @param {string} value
   */
  public set faculty(value: string) {
    this._faculty = value;
  }
}
