import { TlaOption } from "./tla-option";
import { TlaExtentOption } from "./tla-extent-option";

export class TlaSelectedOption extends TlaOption {
  private _selectionId: number;
  private _additionalText: string;
  private _extentText: string;


  private _extentOption: TlaExtentOption;

  // private _extentId: number;
  // private _extentDescription: string;
  // private _extentTextRequired: boolean;
  // private _extentOtherText: string;

  constructor(
    id: number,
    description: string,
    isTextRequired: boolean,
    flag: string,
    selectionId: number,
    additionalText: string,
    extentText: string
  ) {
    super(id, description, isTextRequired, flag);

    this._selectionId = selectionId;
    this._additionalText = additionalText;
    this._extentText = extentText;

  }

  /**
   * Getter selectionId
   * @return {number}
   */
  public get selectionId(): number {
    return this._selectionId;
  }

  /**
   * Setter selectionId
   * @param {number} value
   */
  public set selectionId(value: number) {
    this._selectionId = value;
  }

  /**
   * Getter additionalText
   * @return {string}
   */
  public get additionalText(): string {
    return this._additionalText;
  }

  /**
   * Setter additionalText
   * @param {string} value
   */
  public set additionalText(value: string) {
    this._additionalText = value;
  }



    /**
     * Getter extentOption
     * @return {TlaExtentOption}
     */
	public get extentOption(): TlaExtentOption {
		return this._extentOption;
	}

    /**
     * Setter extentOption
     * @param {TlaExtentOption} value
     */
	public set extentOption(value: TlaExtentOption) {
		this._extentOption = value;
	}


    /**
     * Getter extentText
     * @return {string}
     */
	public get extentText(): string {
		return this._extentText;
	}

    /**
     * Setter extentText
     * @param {string} value
     */
	public set extentText(value: string) {
		this._extentText = value;
	}


  
}
