import { Injectable } from "@angular/core";
// import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Config } from "app/config";

//models
import { TlaFormat } from "app/core/models/tla-format";
import { TlaExtentOption } from "app/core/models/tla-extent-option";
import { TlaOption } from "app/core/models/tla-option";
import { TlaSelectedOption } from "app/core/models/tla-selected-option";
import { TlaOptionValue } from "app/core/models/tla-option-value";
import { CourseOutcome } from "app/core/models/course-outcome";

//services
import { UtilService } from "app/core/services/util.service";
// import { utcFormat } from "d3";

@Injectable({
  providedIn: "root"
})
export class TlaService {
  constructor(
    private utilService: UtilService,
    private httpClient: HttpClient
  ) { }

  /////////////////////////////////////  TLA Format - ADMIN ////////////////////////////////////////////

  /*****************************************************************
   * Get tla format for the selected curriculum review
   * V.1
   * Error: Load Errors
   *****************************************************************/
  getTlaFormatByReviewId(reviewId: number) {
    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/curriculum-reviews/" + reviewId + "/tla")
      .pipe(
        map((tlaFormatData: any) => {
          return tlaFormatData
            ? new TlaFormat(
              tlaFormatData.id,
              tlaFormatData.isEnabled,
              tlaFormatData.format,
              tlaFormatData.isExtentRequired == 1 ? true : false,
              tlaFormatData.extentFormat,
              tlaFormatData.requiredByCourseOutcome == 1 ? true : false,
              tlaFormatData.chartColor,
              tlaFormatData.extentChartColor
            )
            : null;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  getTlaFormatByCourseId(courseId: number) {
    return this.httpClient
      .get(Config.BASE_API_URL_V1 + "/courses/" + courseId + "/tla")
      .pipe(
        map((tlaFormat: any) => {
          ////console.log(tlaFormat);

          return new TlaFormat(
            tlaFormat.id,
            tlaFormat.isEnabled,
            tlaFormat.format,
            tlaFormat.isExtentRequired == 1 ? true : false,
            tlaFormat.extentFormat,
            tlaFormat.requiredByCourseOutcome == 1 ? true : false,
            tlaFormat.chartColor,
            tlaFormat.extentChartColor
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /****************************************************
   * UPDATED: Post TLA Format
   *************************************************/
  postTlaFormat(reviewId: number, newTlaFormat: TlaFormat) {
    ////console.log(newTlaFormat);

    const body = {
      id: newTlaFormat.id,
      isEnabled: newTlaFormat.isEnabled ? 1 : 0,
      format: newTlaFormat.format,
      // isExtentRequired: newTlaFormat.isExtentRequired,
      isExtentRequired: 0,
      // extentFormat: newTlaFormat.extentFormat,
      extentFormat: newTlaFormat.extentFormat,
      // requriedByCourseOutcome: newTlaFormat.requiredByCourseOutcome
      requriedByCourseOutcome: 0,
      chartColor: newTlaFormat.chartColor
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 + "/curriculum-reviews/" + reviewId + "/tla",
        body
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return new TlaFormat(
            result.id,
            result.isEnabled,
            result.format,
            result.isExtentRequired,
            result.extentFormat,
            result.requiredByCourseOutcome,
            result.chartColor
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**********************************
   * UPDATED: Update TLA Format
   * **********************************/
  updateTlaFormat(reviewId: number, tlaId: number, updatedFormat: TlaFormat) {
    const body = {
      id: updatedFormat.id,
      isEnabled: updatedFormat.isEnabled ? 1 : 0,
      format: updatedFormat.format,
      // isExtentRequired: newTlaFormat.isExtentRequired,
      isExtentRequired: updatedFormat.isExtentRequired ? 1 : 0,
      extentFormat: updatedFormat.extentFormat,
      requiredByCourseOutcome: updatedFormat.requiredByCourseOutcome ? 1 : 0,
      chartColor: updatedFormat.chartColor
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId,
        body
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return new TlaFormat(
            result.id,
            result.isEnabled,
            result.format,
            result.isExtentRequired,
            result.extentFormat,
            result.requiredByCourseOutcome,
            result.chartColor
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  ///////////////////////////// TLA Extent Options List - ADMIN ////////////////////////////

  /*****************************************************************
   * UPDATED: Get TLA Extent options without value
   ****************************************************************/
  getTlaExtentOptionsByReviewId(reviewId: number, tlaId: number) {
    let tlaExtentOptions: Array<TlaExtentOption> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/extent-options"
      )
      .pipe(
        map((tlaExtentOptionsData: any) => {
          ////console.log(tlaExtentOptionsData);

          for (let extentOption of tlaExtentOptionsData) {
            tlaExtentOptions.push(
              new TlaExtentOption(
                extentOption.id,
                extentOption.description,
                extentOption.otherTextRequired ? true : false
              )
            );
          }
          return tlaExtentOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**********************************
   * UPDATED: Post TLA extent option
   * **********************************/
  postTlaExtentOption(
    reviewId: number,
    tlaId: number,
    newTlaExtentOption: TlaExtentOption
  ) {
    const body = {
      description: newTlaExtentOption.description,
      otherTextRequired: newTlaExtentOption.isTextRequired ? 1 : 0
    };

    ////console.log(body);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/extent-options",
        body
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return new TlaOption(result.id, result.option);
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**********************************
   * UPDATED: Delete TLA extent option
   * **********************************/
  deleteTlaExtentOption(
    reviewId: number,
    tlaId: number,
    tlaExtentOptionId: number
  ) {
    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/extent-options/" +
        tlaExtentOptionId
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return new TlaOption(result.id, result.option);
        }),

        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /************************************************
   * UPDATED: Get TLA Extent option examples
   ***************************************************/
  getTlaExtentOptionExamples(reviewId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/extent-examples"
      )
      .pipe(
        map((data: any) => {
          let tlaExtentExamples = [];

          ////console.log(data);

          data.forEach(example => {
            //comma separate
            const extentOptions = example.extents.split(",");

            let tlaExtentSet: Array<TlaExtentOption> = [];

            extentOptions.forEach(extentOption => {
              tlaExtentSet.push(
                new TlaExtentOption(
                  null,
                  extentOption.trim(), //trim whitespace
                  null
                )
              );
            });

            tlaExtentExamples.push(tlaExtentSet);
          });

          return tlaExtentExamples;
        }),

        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  ///////////////////////// TLA Options Text - USER  ////////////////////////////////

  /**************************************************
   * Get currently selected tla options (text)
   * Error: Load Error
   * V.1
   ***************************************************/
  getSelectedTlaOptionsText(courseId: number, tlaId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/tla/" +
        tlaId +
        "/text-values"
      )
      .pipe(
        map((tlaOptionsData: Array<any>) => {
          //console.log(tlaOptionsData);

          let selectedTlaOptions: Array<TlaSelectedOption> = [];

          for (let selectedTlaOption of tlaOptionsData) {
            selectedTlaOptions.push(
              new TlaSelectedOption(
                selectedTlaOption.tlaOptionId,
                selectedTlaOption.tlaName,
                false,
                null,
                selectedTlaOption.id,
                selectedTlaOption.tlaDescription,
                selectedTlaOption.tlaExtentText
              )
            );
          }

          return selectedTlaOptions;
        }),
        catchError((exception: any) => {
          //console.log(exception);
          return throwError(this.utilService.handleApiError(exception));
        })
      )
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //////////////////////////////// TLA Options List - ADMIN /////////////////////////////////////////////

  /************************************
   * Get TLA options by review ID
   ***********************************/
  getTlaOptions(reviewId: number, tlaId: number) {
    let tlaOptions: Array<TlaOption> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/options"
      )
      .pipe(
        map((tlaOptionsData: Array<any>) => {
          ////console.log(tlaOptionsData);

          for (let tlaOption of tlaOptionsData) {
            tlaOptions.push(
              new TlaOption(
                tlaOption.id,
                tlaOption.description,
                tlaOption.otherTextRequired ? true : false,
                null
              )
            );
          }
          return tlaOptions;
        }),
        catchError((exception: any) => {
          return throwError(this.utilService.handleApiError(exception));
        })
      )
      .toPromise();
  }

  /**********************************
   * Post TLA option
   * **********************************/
  postTlaOption(reviewId: number, tlaId: number, newOption: TlaOption) {
    const body = {
      description: newOption.description,
      otherTextRequired: newOption.isTextRequired
    };

    ////console.log(body);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/options",
        body
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return new TlaOption(result.id, result.option);
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**********************************
   * Delete TLA option
   * **********************************/
  deleteTlaOption(reviewId: number, tlaId: number, tlaOptionId: number) {
    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/options/" +
        tlaOptionId
      )
      .pipe(
        map((result: any) => {
          return true;
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /////////////////////////////////////////////////////////////////////////////

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  ////////////////////////////// Available TLA Options (List) - USER  ///////////////////////////////////////////////

  /******************************************
   * Get Available TLA options by Course Id
   * Error: Load Errors
   * V.1
   ****************************************/
  getAvailableTlaOptionsForCourse(courseId: number, tlaId: number) {
    let tlaOptions: Array<TlaOption> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/tla/" +
        tlaId +
        "/options"
      )
      .pipe(
        map((tlaOptionsData: Array<any>) => {
          ////console.log(tlaOptionsData);

          for (let tlaOption of tlaOptionsData) {
            tlaOptions.push(
              new TlaOption(
                tlaOption.id,
                tlaOption.description,
                tlaOption.otherTextRequired ? true : false,
                null
              )
            );
          }
          return tlaOptions;
        }),
        catchError((exception: any) => {
          return throwError(this.utilService.handleApiError(exception));
        })
      )
      .toPromise();
  }

  /******************************************************************
   * Get Selected TLA options for course
   * Error: Load Errors
   * V.1
   *****************************************************************/
  getSelectedTlaOptionsForCourse(courseId: number, tlaId: number) {
    let tlaOptions: Array<TlaOptionValue> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/tla/" +
        tlaId +
        "/selected-options"
      )
      .pipe(
        map((selectedTlaOptionsData: any) => {
          //console.log(selectedTlaOptionsData);

          let selectedTlaOptions: Array<TlaSelectedOption> = [];

          for (let selectedTlaOption of selectedTlaOptionsData) {
            selectedTlaOptions.push(
              new TlaSelectedOption(
                selectedTlaOption.tlaOptionId,
                selectedTlaOption.description,
                selectedTlaOption.otherTextRequired,
                null,
                selectedTlaOption.selectionId,
                selectedTlaOption.additionalText,
                selectedTlaOption.extentText
              )
            );
          }
          return selectedTlaOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /***************************************************************
   * Add selected TLA Option (also used by Text Option)
   * Error: Action Error
   **************************************************************/
  addSelectedTlaOption(
    courseId: number,
    tlaFormat: TlaFormat,
    tlaFormValue: TlaSelectedOption
  ) {
    if (tlaFormat.format == "list") {
      let tlaOption = {
        tlaId: tlaFormat.id,
        tlaOptionId: tlaFormValue.id,
        tlaOptionOtherText: tlaFormValue.additionalText,
        tlaExtentText: tlaFormValue.extentText
        // tlaExtentId: tlaOptionValue.extentOption
        //   ? tlaOptionValue.extentOption.id
        //   : null,
        // tlaExtentOtherText: tlaOptionValue.extentOption
        //   ? tlaOptionValue.extentOption.otherText
        //   : null
      };

      return this.httpClient
        .post(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-options",
          tlaOption
        )
        .pipe(
          map((data: any) => {
            // //console.log(data);

            return new TlaSelectedOption(
              data.tlaOptionId,
              null,
              false,
              null,
              data.id,
              data.tlaOptionOtherText,
              data.tlaExtentText
            );
          }),
          catchError((error: any) => {
            ////console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    } else if (tlaFormat.format == "text") {
      let tlaOptionText = {
        tlaId: tlaFormat.id,
        tlaName: tlaFormValue.description,
        tlaDescription: tlaFormValue.additionalText,
        tlaExtentText: tlaFormValue.extentText

        // tlaExtentText: tlaOptionValue.extentText,
        // tlaExtentId: tlaOptionValue.extentOption
        //   ? tlaOptionValue.extentOption.id
        //   : null,
        // tlaExtentOtherText: tlaOptionValue.extentOption
        //   ? tlaOptionValue.extentOption.otherText
        //   : null
      };
      return this.httpClient
        .post(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/text-values",
          tlaOptionText
        )
        .pipe(
          map((data: any) => {
            //console.log(data);

            return new TlaSelectedOption(
              data.tlaId,
              data.tlaName,
              false,
              null,
              data.id,
              data.tlaDescription,
              data.tlaExtentText
            );
          }),
          catchError((error: any) => {
            ////console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    }
  }

  /***************************************************************
   * Update existing selected tla option
   * V.1
   * Error: Action Error
   **************************************************************/
  updateSelectedTlaOption(
    courseId: number,
    tlaFormat: TlaFormat,
    tlaFormValue: TlaSelectedOption
  ) {
    // //console.log(tlaFormValue);

    if (tlaFormat.format == "text") {
      let tlaOption = {
        tlaId: tlaFormat.id,

        tlaName: tlaFormValue.description,
        tlaDescription: tlaFormValue.additionalText,
        tlaExtentText: tlaFormValue.extentText
      };

      return this.httpClient
        .put(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-options-text/" +
          tlaFormValue.selectionId,
          tlaOption
        )
        .pipe(
          map((data: any) => {
            //console.log(data);

            return new TlaSelectedOption(
              data.tlaId,
              data.tlaName,
              false,
              null,
              data.id,
              data.tlaDescription,
              data.tlaExtentText
            );
          }),
          catchError((error: any) => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    } else if (tlaFormat.format == "list") {
      let tlaOption = {
        tlaId: tlaFormat.id,
        tlaOptionId: tlaFormValue.id,
        tlaOptionOtherText: tlaFormValue.additionalText,
        tlaExtentText: tlaFormValue.extentText
      };

      return this.httpClient
        .put(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-options/" +
          tlaFormValue.selectionId,
          tlaOption
        )
        .pipe(
          map((data: any) => {
            // //console.log(data);

            return new TlaSelectedOption(
              data.tlaOptionId,
              null,
              false,
              null,
              data.id,
              data.tlaOptionOtherText,
              data.tlaExtentText
            );
          }),
          catchError((error: any) => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    } else {
      return throwError(null).toPromise();
    }
  }

  /***************************************************************
   * Delete existing selected tla option
   * V.1
   * Error: Action Error
   **************************************************************/

  deleteTlaOptionValue(
    courseId: number,
    tlaFormat: TlaFormat,
    selectedTlaId: number
  ) {
    if (tlaFormat.format == "text") {
      return this.httpClient
        .delete(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-options-text/" +
          selectedTlaId
        )
        .pipe(
          map(result => {
            return;
          }),
          catchError((error: any) => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    } else if (tlaFormat.format == "list") {
      return this.httpClient
        .delete(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-options/" +
          selectedTlaId
        )
        .pipe(
          map(result => {
            return;
          }),
          catchError((error: any) => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  ///////////////////////////////// TLA Extent Options (List) - USER ////////////////////////////////////////////

  /***************************************************************
   * Get Currently Selected TLA Extent options
   * Error: Load Error
   * V.1
   **************************************************************/
  getAvailableTlaExtentOptionsByCourseId(courseId: number, tlaId: number) {
    let tlaExtentOptions: Array<TlaExtentOption> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/tla/" +
        tlaId +
        "/extent-options"
      )
      .pipe(
        map((tlaExtentOptionsData: Array<any>) => {
          ////console.log(tlaExtentOptionsData);

          for (let extentOption of tlaExtentOptionsData) {
            tlaExtentOptions.push(
              new TlaExtentOption(
                extentOption.id,
                extentOption.description,
                extentOption.otherTextRequired
              )
            );
          }
          return tlaExtentOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /***************************************************************
   * Get TLA Selected Extent options for Text TLA
   * Error: Load Error
   **************************************************************/
  getSelectedTlaExtentOptionForTextTla(
    courseId: number,
    tlaId: number,
    selectedTlaId: number
  ) {
    let tlaOptions: Array<TlaOptionValue> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/tla/" +
        tlaId +
        "/selected-tla-text/" +
        selectedTlaId +
        "/extent-option"
      )
      .pipe(
        map((selectedTlaExtentOptionData: any) => {
          // //console.log(selectedTlaExtentOptionData);

          if (selectedTlaExtentOptionData) {
            return new TlaExtentOption(
              selectedTlaExtentOptionData.id,
              selectedTlaExtentOptionData.description,
              selectedTlaExtentOptionData.otherTextRequired ? true : false,
              selectedTlaExtentOptionData.extentAdditionalText,
              null,
              false
            );
          } else {
            return null;
          }
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /***************************************************************
   * Get TLA Selected Extent options for List TLA
   * Error: Load Error
   **************************************************************/
  getSelectedTlaExtentOptionForListTla(
    courseId: number,
    tlaId: number,
    selectedTlaId: number
  ) {
    let tlaOptions: Array<TlaOptionValue> = [];

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/tla/" +
        tlaId +
        "/selected-options/" +
        selectedTlaId +
        "/extent-option"
      )
      .pipe(
        map((selectedTlaExtentOptionData: any) => {
          // //console.log(selectedTlaExtentOptionData);

          if (selectedTlaExtentOptionData) {
            return new TlaExtentOption(
              selectedTlaExtentOptionData.id,
              selectedTlaExtentOptionData.description,
              selectedTlaExtentOptionData.otherTextRequired ? true : false,
              selectedTlaExtentOptionData.extentAdditionalText,
              null,
              false
            );
          } else {
            return null;
          }
        }),
        catchError(error => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  // getSelectedTlaExtentOptionsForCourse(
  //   reviewId: number,
  //   courseId: number,
  //   tlaId: number
  // ) {
  //   return this.httpClient
  //     .get(
  //       Config.BASE_API_URL_V1 +
  //         "/curriculum-reviews/" +
  //         reviewId +
  //         "/courses/" +
  //         courseId +
  //         "/tlas/" +
  //         tlaId +
  //         "/extent"
  //     )
  //     .pipe(
  //       map((data: any) => {
  //         return data;
  //       }),
  //       catchError((error: any) => {
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();
  // }

  updateSelectedTlaExtentOption(
    courseId: number,
    tlaFormat: TlaFormat,
    selectedTlaOptionId: number,
    extentOption: TlaExtentOption
  ) {
    let selectedExtentOption = {};

    if (tlaFormat.format == "text") {
      selectedExtentOption = {
        tlaSelectionTextId: selectedTlaOptionId, //both list and text
        extentId: extentOption.id,
        extentAdditionalText: extentOption.otherText
      };

      // //console.log(selectedExtentOption);

      return this.httpClient
        .put(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-tla-text/" +
          selectedTlaOptionId +
          "/extent-option",
          selectedExtentOption
        )
        .pipe(
          map((data: any) => {
            // //console.log(data);
            return new TlaExtentOption(
              data.extentId,
              null,
              false,
              data.extentAdditionalText,
              null,
              false
            );
          }),
          catchError(error => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    } else if (tlaFormat.format == "list") {
      selectedExtentOption = {
        tlaSelectionId: selectedTlaOptionId, //both list and text
        extentId: extentOption.id,
        extentAdditionalText: extentOption.otherText
      };

      return this.httpClient
        .put(
          Config.BASE_API_URL_V1 +
          "/courses/" +
          courseId +
          "/tla/" +
          tlaFormat.id +
          "/selected-options/" +
          selectedTlaOptionId +
          "/extent-option",
          selectedExtentOption
        )
        .pipe(
          map((data: any) => {
            // //console.log(data);

            return new TlaExtentOption(
              data.extentId,
              null,
              false,
              data.extentAdditionalText,
              null,
              false
            );
          }),
          catchError(error => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise();
    }

    // //console.log(extentOption);

    //{courseId}/tla/{tlaId}/selected-tla-text/{tlaSelectedId}/extent-option

    //{courseId}/tla/{tlaId}/selected-options/{tlaSelectedId}/extent-option

    // if(tlaFormat.format == "text"){

    // }
    // else if(tlaFormat.format == "list"){
    //   return this.httpClient.put(
    //     Config.BASE_API_URL_V1 + "/tla/" + tlaFormat.id + "/selected-options/" + selectedTlaOptionId + "/extent-option",
    //     selectedExtentOption
    //   ).pipe(
    //     catchError(error => {
    //       return throwError(this.utilService.handleApiError(error));
    //     })
    //   ).toPromise();
    // }
    // else{
    //   //return error custom
    // }
  }


  // getTextExtentForTextTla(courseId: number,
  //   tlaId: number,
  //   selectedTlaId: number) {

  //     return this.httpClient
  //     .get(
  //       Config.BASE_API_URL_V1 +
  //       "/courses/" +
  //       courseId +
  //       "/tla/" +
  //       tlaId +
  //       "/selected-tla-text/" +
  //       selectedTlaId +
  //       "/extent-option-text"
  //     )
  //     .pipe(
  //       map((selectedTlaExtentOptionData: any) => {
  //         // //console.log(selectedTlaExtentOptionData);

  //         if (selectedTlaExtentOptionData) {
  //           return new TlaExtentOption(
  //             selectedTlaExtentOptionData.id,
  //             selectedTlaExtentOptionData.description,
  //             selectedTlaExtentOptionData.otherTextRequired ? true : false,
  //             selectedTlaExtentOptionData.extentAdditionalText,
  //             null,
  //             false
  //           );
  //         } else {
  //           return null;
  //         }
  //       }),
  //       catchError(error => {
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();


  // }

  ///////////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //////////////////////// TLA Options for Course outcomes - USER /////////////////////////////////////////////////////

  getAllSelectedTlaOptionsByCourseOutcomes(courseId: number, type: string) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcome-tla-options"
      )
      .pipe(
        map(
          (selectedTlaIds: Array<any>) => {
            //console.log(selectedTlaIds);

            // let selectedAssessmentIdsArray: Array<any> = [];

            return selectedTlaIds;
          },
          catchError((error: any) => {
            //console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
      )
      .toPromise();
  }

  /***************************************************************
   * Get selected tla options by a selected course outcome
   **************************************************************/
  getTlaOptionsSelectedByCourseOutcome(
    courseId: number,
    courseOutcomeId: number,
    type: string
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcomes/" +
        courseOutcomeId +
        "/tla-options"
      )
      .pipe(
        map((selectedTlas: Array<any>) => {
          console.log(selectedTlas);

          let selectedTlaIds = [];

          if (type == "list") {
            selectedTlas.forEach(selectedTla => {
              selectedTlaIds.push(selectedTla.selectedTlaId);
            });
          } else if (type == "text") {
            selectedTlas.forEach(selectedTla => {
              selectedTlaIds.push(selectedTla.selectedTlaTextId);
            });
          }

          ////console.log(selectedTlaIds);

          return selectedTlaIds;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /***************************************************************
   * Get selected tla options by a selected course outcome
   **************************************************************/
  updateSelectedTlaOptionByCourseOutcome(
    courseId: number,
    selectedTlaOptionIds: Array<any>,
    tlaFormat: string
  ) {
    let tlaOptionIds = [];

    if (tlaFormat == "list") {
      selectedTlaOptionIds.forEach(selectedTlaOptionId => {
        tlaOptionIds.push({
          courseId: courseId,
          courseOutcomeId: selectedTlaOptionId.courseOutcomeId,
          selectedTlaId: selectedTlaOptionId.selectedTlaId
        });
      });
    } else if (tlaFormat == "text") {
      selectedTlaOptionIds.forEach(selectedTlaOptionId => {
        tlaOptionIds.push({
          courseId: courseId,
          courseOutcomeId: selectedTlaOptionId.courseOutcomeId,
          selectedTlaTextId: selectedTlaOptionId.selectedTlaId
        });
      });
    }

    ////console.log(assessmentMethodIds);

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/courses/" +
        courseId +
        "/course-outcome-tla-options",
        tlaOptionIds
      )
      .pipe()
      .toPromise();
  }

  /***************************************************
   * Returns selectedAssessmentMethodIdsArray[courseOutcomeId][selectedAssessmentMethodId] = true/false
   ***************************************************/
  async getAllSelectedTlaIdsByCourseOutcomes(
    courseOutcomes: Array<CourseOutcome>,
    tlaOptionsForCourse: Array<TlaSelectedOption>,
    selectedTlaIdsData: Array<any>, //[{id, courseOutcomeId, selectedTlaId, selectedTlaTextId, courseId}]
    tlaFormat: string
  ) {
    // //format assessmentMethodsForCourseArray[AssessmentSelectedMethodId] = AssessmentSelectedMethod;
    // let tlaOptionsForCourseArray: Array<any> = [];

    // //convetr to the format above
    // tlaOptionsForCourse.forEach(tlaOptonForCourse => {
    //   tlaOptionsForCourseArray[tlaOptonForCourse.selectionId] = tlaOptonForCourse;
    // });

    // //console.log(tlaOptionsForCourseArray);

    //create 2D array and initialize with selectedAssessmentIdsArray[courseOutcomeId][selectedAssessmentMethodId] = false

    //console.log(courseOutcomes);
    //console.log(tlaOptionsForCourse);
    //console.log(selectedTlaIdsData);
    //console.log(tlaFormat);

    //initialize
    let selectedTlaIdsArray: Array<any> = [];
    courseOutcomes.forEach(courseOutcome => {
      selectedTlaIdsArray[courseOutcome.id] = [];

      tlaOptionsForCourse.forEach(tlaOptionForCourse => {
        selectedTlaIdsArray[courseOutcome.id][
          tlaOptionForCourse.selectionId
        ] = false;
      });
    });

    //get selected assessment IDs
    let selectedTlaIds: Array<{
      courseOutcomeId: number;
      selectedTlaId: number;
    }> = [];

    selectedTlaIdsData.forEach(selectedTlaIdData => {
      if (tlaFormat == "text") {
        selectedTlaIds.push({
          courseOutcomeId: selectedTlaIdData.courseOutcomeId,
          selectedTlaId: selectedTlaIdData.selectedTlaTextId
        });
      } else if (tlaFormat == "list") {
        selectedTlaIds.push({
          courseOutcomeId: selectedTlaIdData.courseOutcomeId,
          selectedTlaId: selectedTlaIdData.selectedTlaId
        });
      }
    });

    //convert to selectedAssessmentMethodIdsArray[courseOutcomeId][selectedAssessmentMethodId] = true
    selectedTlaIds.forEach(selectedTlaId => {
      if (selectedTlaIdsArray[selectedTlaId.courseOutcomeId]) {
        //if selectedTlaId.selectedTlaId exists

        if (
          selectedTlaIdsArray[selectedTlaId.courseOutcomeId][
          selectedTlaId.selectedTlaId
          ] != undefined
        ) {
          selectedTlaIdsArray[selectedTlaId.courseOutcomeId][
            selectedTlaId.selectedTlaId
          ] = true;
        }
      }
    });

    return selectedTlaIdsArray;
  }

  /********************************************************
   * TLA count for each PLO
   *********************************************************/
  getTLAOutcomeCountForPLO(
    reviewId: number,
    tlaId: number,
    ploId: number,
    courseIds: Array<number>
  ) {
    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/tla/" +
        tlaId +
        "/outcome-frequency/plos/" +
        ploId,
        courseIds
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();

    //{tlaId}/frequency/plos/{ploId}
  }

  // getTLACourseCountForPLO(
  //   reviewId: number,
  //   tlaId: number,
  //   ploId: number,
  //   courseIds: Array<number>
  // ) {
  //   return this.httpClient
  //     .post(
  //       Config.BASE_API_URL_V1 +
  //         "/curriculum-reviews/" +
  //         reviewId +
  //         "/tla/" +
  //         tlaId +
  //         "/course-frequency/plos/" +
  //         ploId,
  //       courseIds
  //     )
  //     .pipe(
  //       map((data: any) => {
  //         console.log(data);
  //         return data;
  //       }),
  //       catchError((error: any) => {
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();
  // }
}
