import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CustomError } from "app/core/models/custom-error";


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private _alert = new Subject<any>();
  alert = this._alert.asObservable();

  private _shouldShowAlert = new Subject<boolean>();
  shouldShowAlert = this._shouldShowAlert.asObservable();

  constructor() {
    this._alert.next({
      success: false,
      message: "",
      autoHide: false
    });
  }

  showAlert(
    success: boolean,
    message: string,
    autoHide: boolean = false,
    errors: Array<any> = []
  ) {
    this._alert.next({
      success: success,
      message: message,
      autoHide: autoHide,
      errors: errors
    });
    this._shouldShowAlert.next(true);

    //if success, auto hide after 5 sec
    if (autoHide) {
      setTimeout(() => {
        this._shouldShowAlert.next(false);
      }, 5000);
    }
  }


  //use this for ApiError
  alertErrors(
    success: boolean,
    message: string,
    autoHide: boolean = false,
    errors: Array<CustomError> = []
  ) {
    this._alert.next({
      success: success,
      message: message,
      autoHide: autoHide,
      errors: errors
    });
    this._shouldShowAlert.next(true);

    //if success, auto hide after 5 sec
    if (autoHide) {
      setTimeout(() => {
        this._shouldShowAlert.next(false);
      }, 5000);
    }
  }

  //keep the current alert object as is. just change the shouldShowAlert.
  hideAlert() {
    this._shouldShowAlert.next(false);
  }
}
