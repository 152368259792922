export class TlaOption {
  private _id: number;
  private _description: string;
  private _isTextRequired: boolean;
  private _flag: string; //used when adding/deleting options

  constructor(
    id: number = null,
    description: string = null,
    isTextRequired: boolean = false,
    flag: string = null
  ) {
    this._id = id;
    this._description = description;
    this._isTextRequired = isTextRequired;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get isTextRequired(): boolean {
    return this._isTextRequired;
  }

  public set isTextRequired(value: boolean) {
    this._isTextRequired = value;
  }

  public get flag(): string {
    return this._flag;
  }

  public set flag(value: string) {
    this._flag = value;
  }
}
