import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output
} from "@angular/core";

//Models
import { ProgramOutcomeCategory } from "app/core/models/program-outcome-category";
import { ProgramOutcome } from "app/core/models/program-outcome";
import { CustomError } from "app/core/models/custom-error";

//Service
import { UtilService } from "app/core/services/util.service";


@Component({
  selector: "app-plo-list",
  templateUrl: "./plo-list.component.html",
  styleUrls: ["./plo-list.component.scss"]
})
export class PloListComponent implements OnInit, OnChanges {
  @Input() programOutcomeCategories: Array<ProgramOutcomeCategory>;
  @Input() programOutcomes: Array<ProgramOutcome>;
  @Input() editable: boolean = false;

  @Output() proceedEditOutcome: EventEmitter<number> = new EventEmitter(); //for editing outcome
  @Output() proceedDeleteOutcome: EventEmitter<number> = new EventEmitter();
  @Output() proceedEditCategory: EventEmitter<number> = new EventEmitter(); //for editing category
  @Output() proceedDeleteCategory: EventEmitter<number> = new EventEmitter();

  categoryIds: Array<number> = [];
  programOutcomesByCategory: Array<any> = [];
  programOutcomesWithoutCategory: Array<ProgramOutcome> = [];

  actionErrors: Array<CustomError> = [];

  //program outcome form
  programOutcomeForForm: ProgramOutcome;

  //flag
  shouldShowProgramOutcomeForm: boolean = false;

  sortableCategoriesOptions: any;
  sortableOutcomesOptions: any;

  categoriesSortingInProgress: boolean = false;
  outcomesSortingInProgress: boolean = false;

  constructor(private utilService: UtilService) {}

  ngOnInit() {
    this.reloadTable();

    this.sortableCategoriesOptions = {
      animation: 150,
      disabled: true,
      onStart: event => {
        // console.log(event);
      },
      onEnd: event => {
        // console.log(event);
      },
      onSort: event => {
        // console.log(event);
      },
      onUpdate: event => {
        // console.log(event);
        // console.log(this.programOutcomesByCategory);
      }
    };

    this.sortableOutcomesOptions = {
      animation: 150,
      disabled: true,
      onStart: event => {
        //console.log(event);
      },
      onEnd: event => {
        //console.log(event);
      },
      onSort: event => {
        //console.log(event);
      },
      onUpdate: event => {
        //console.log(event);
        //console.log(this.levels);
      }
    };
  }

  ngOnChanges(changes) {
    //console.log(changes);

    if (changes.programOutcomeCategories || changes.programOutcomes) {
      this.reloadTable();
    }
  }

  editOutcomeClicked(outcomeId) {
    this.proceedEditOutcome.emit(outcomeId);
  }

  editCategoryClicked(categoryId) {
    this.proceedEditCategory.emit(categoryId);
  }

  deleteOutcomeClicked(outcomeToDelete) {
    this.proceedDeleteOutcome.emit(outcomeToDelete);
  }

  deleteCategoryClicked(categoryId) {
    this.proceedDeleteCategory.emit(categoryId);
  }

  enabledReorderCategories() {
    // console.log("Clicked");

    this.sortableCategoriesOptions = {
      // animation: 150,
      disabled: false
      // onStart: event => {
      //   //console.log(event);
      // },
      // onEnd: event => {
      //   //console.log(event);
      // },
      // onSort: event => {
      //   //console.log(event);
      // },
      // onUpdate: event => {
      //   //console.log(event);
      //   //console.log(this.levels);
      // }
    };

    this.categoriesSortingInProgress = true;
  }

  reloadTable() {
    this.programOutcomesByCategory = [];
    this.programOutcomesWithoutCategory = [];

    this.utilService.groupPlosByCategory(
      this.programOutcomes,
      this.programOutcomeCategories,
      (programOutcomesByCategory, programOutcomesWithoutCategory) => {
        //console.log(programOutcomesByCategory);
        //console.log(programOutcomesWithoutCategory);

        this.programOutcomesByCategory = programOutcomesByCategory;
        this.programOutcomesWithoutCategory = programOutcomesWithoutCategory;
      }
    );

    //console.log(this.programOutcomeCategories);
    //console.log(this.programOutcomesWithoutCategory);

    // this.categoryIds = [];

    // let index = 1;

    // this.programOutcomeCategories.forEach(category => {
    //   //go through outcomes and select ones in this category

    //   //console.log(category);

    //   this.categoryIds.push(category.id);

    //   let outcomesInThisCategory: Array<ProgramOutcome> = [];

    //   this.programOutcomes.forEach(programOutcome => {
    //     if (programOutcome.categoryId == category.id) {
    //       programOutcome.index = index++;
    //       outcomesInThisCategory.push(programOutcome);
    //     }
    //   });

    //   this.programOutcomesByCategory.push({
    //     category: category,
    //     outcomes: outcomesInThisCategory
    //   });
    // });

    // //select outcomes not belonging to any category
    // this.programOutcomes.forEach(outcome => {
    //   //console.log(this.categoryIds.indexOf(outcome.categoryId));

    //   //if the outcome has no category ID or the category ID does not belong to any categories
    //   if (
    //     !outcome.categoryId ||
    //     this.categoryIds.indexOf(outcome.categoryId) == -1
    //   ) {
    //     outcome.index = index++;
    //     this.programOutcomesWithoutCategory.push(outcome);
    //   }
    // });
  }
}
