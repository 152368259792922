import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      // console.log("Not authenticated...");

      this.router.navigate(["/auth/login"]);
      return;
    }

    //console.log("Authenticated...");

    return true;
  }
}
