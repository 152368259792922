export class AssessmentLevelOptionSelected {
  private _selectionId: number;
  private _levelOptionId: number;
  private _otherText: string;

  constructor(selectionId: number, levelOptionId: number, otherText: string) {
    this._selectionId = selectionId;
    this._levelOptionId = levelOptionId;
    this._otherText = otherText;
  }

  /**
   * Getter selectionId
   * @return {number}
   */
  public get selectionId(): number {
    return this._selectionId;
  }

  /**
   * Setter selectionId
   * @param {number} value
   */
  public set selectionId(value: number) {
    this._selectionId = value;
  }

  /**
   * Getter levelOptionId
   * @return {number}
   */
  public get levelOptionId(): number {
    return this._levelOptionId;
  }

  /**
   * Setter levelOptionId
   * @param {number} value
   */
  public set levelOptionId(value: number) {
    this._levelOptionId = value;
  }

  /**
   * Getter otherText
   * @return {string}
   */
  public get otherText(): string {
    return this._otherText;
  }

  /**
   * Setter otherText
   * @param {string} value
   */
  public set otherText(value: string) {
    this._otherText = value;
  }
}
