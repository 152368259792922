import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-plo-list',
  templateUrl: './report-plo-list.component.html',
  styleUrls: ['./report-plo-list.component.scss']
})
export class ReportPloListComponent implements OnInit {

  @Input()
  chartData: any;


  constructor() { }

  ngOnInit() {

    // console.log(this.chartData);

  }

}
