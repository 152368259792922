import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  HostBinding,
  Inject,
  // trigger,
  // transition,
  // keyframes,
  // animate,
  // style,
  enableProdMode
} from "@angular/core";

// import { Router } from "@angular/router";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
//compnent
import { AlertComponent } from "./shared/components/alert/alert.component";
import { User } from "app/core/models/user";
import { CustomError } from "app/core/models/custom-error";
//services
import { ModalService } from "app/core/services/modal.service";
import { DimmerService } from "app/core/services/dimmer.service";
import { NavBarService } from "app/core/services/nav-bar.service";
import { CourseService } from "app/core/services/course.service";
import { AlertService } from "app/core/services/alert.service";
import { UserService } from "app/core/services/user.service";
import { AuthService } from "app/core/services/auth.service";
import { GlobalErrorService } from "app/core/services/global-error.service";
import { ActionErrorService } from "app/core/services/action-error.service";
import { CurrentUserService } from "app/core/services/current-user.service";
import { SystemService } from "app/core/services/system.service";

//model
import { UserCourse } from "app/core/models/user-course";

import { DOCUMENT } from "@angular/common";

import { environment } from "../environments/environment";
import { Subscription } from "rxjs";
import { ApplicationInfo } from "./core/models/application-info";


import { Config } from "../app/config";

declare const jQuery: any;

@Component({
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  @HostBinding("class.no-scroll")
  modalShown: boolean;
  @HostBinding("class.no-scroll")
  showDimmer: boolean;

  appInfo: ApplicationInfo;
  customStyles: {};

  numCourses: number;
  alert: any;
  shouldShowAlert: boolean;
  userCourse: Array<UserCourse> = [];
  currentUser: User;

  checkUser: boolean = false;

  globalError: CustomError = null;
  actionError: CustomError = null;

  loaded: boolean = false;
  apiUnavailable: boolean = false;

  //subscriptions
  modalSubscription: Subscription;
  dimmerSubscription: Subscription;
  alertSubscription: Subscription;
  sholdShowAlertaSubscription: Subscription;
  globalErrorSubscription: Subscription;
  actionErrorSubscription: Subscription;

  //IE
    isIE: boolean = false;

    

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private dimmerService: DimmerService,
    private navBarService: NavBarService,
    private courseService: CourseService,
    private alertService: AlertService,
    private userService: UserService,
    private globalErrorService: GlobalErrorService,
    private actionErrorService: ActionErrorService,
    private currentUserService: CurrentUserService,
    private systemService: SystemService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) document
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {

        //if HOME is not enabled, and root level is accessed, redirect to /user 
        if (!environment.IS_HOME_ENABLED && event.url == "/") {
          this.router.navigate(["/user"]);
        }
      }
    });
  }

  async ngOnInit() {
    let NUM_STEPS = 1;
    let incrementBy = 100 / NUM_STEPS;
    let currentProgress: number = 0;

    //check IE
    const ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0) {
      this.isIE = true;
    }

    //check IE11
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      this.isIE = true;
    }



    if (this.isIE) {
      return;
    }
    else {

      try {

        //get app info
        this.appInfo = await this.systemService.getAppInfo();

        this.systemService.setAppInfo(this.appInfo);

        if (window.location.pathname.includes("guest")) {
          this.checkUser = false;
        } else {
          this.checkUser = true;
        }

        //shared service to provide isScrollAllowed value
        this.modalSubscription = this.modalService.modalShown.subscribe(
          modalShown => {
            this.modalShown = modalShown;
          }
        );

        this.dimmerSubscription = this.dimmerService.showDimmer.subscribe(
          (showDimmer: boolean) => {
            this.showDimmer = showDimmer;
            // this.updateProgress(currentProgress++);
          }
        );

        this.alertSubscription = this.alertService.alert.subscribe((alert: any) => {
          this.alert = alert;
          // this.updateProgress(currentProgress++);
        });

        this.sholdShowAlertaSubscription = this.alertService.shouldShowAlert.subscribe(
          (shouldShowAlert: boolean) => {
            this.shouldShowAlert = shouldShowAlert;
          }
        );

        this.globalErrorSubscription = this.globalErrorService.globalError.subscribe(
          (globalError: CustomError) => {
            this.globalError = globalError;
          }
        );

        this.actionErrorSubscription = this.actionErrorService.actionError.subscribe(
          (actionError: CustomError) => {
            this.actionError = actionError;
          }
        );

        //if localstorage token exists, validate the token and get the user from API
        if (localStorage.getItem("access_token")) {
          //set current user
          try {
            const user: User = await this.userService.getUser();
            this.currentUserService.setCurrentUser(user);
          }
          catch (error) {
            //clear token and redirect to login page
            this.authService.logout();
            this.router.navigate(["/auth/login"]);

          }

        } else {
        }

        //TODO: load app info from API
        this.updateProgress(++currentProgress * incrementBy);

        setTimeout(() => {
          this.loaded = true;
        }, 750);



      } catch (error) {
        console.log(error);

        //something wrong with the server..
        this.apiUnavailable = true;


      }


    }


  }

  getUsers(accessToken: string) {
    this.userService.getUsers(accessToken).subscribe(
      users => {
        //console.log(users);
      },
      error => {
        //console.log(error);
      }
    );
  }

  hideActionError() {
    this.actionErrorService.hideActionError();
  }

  updateProgress(progress: number) {
    //check if view is loaded
    if (document.getElementById("progress-bar")) {
      document.getElementById("progress-bar").style.width = progress + "%";
    }
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
    this.dimmerSubscription.unsubscribe();
    this.alertSubscription.unsubscribe();
    this.sholdShowAlertaSubscription.unsubscribe();
    this.globalErrorSubscription.unsubscribe();
    this.actionErrorSubscription.unsubscribe();
  }
}
