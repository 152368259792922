import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { TlaFormat } from "app/core/models/tla-format";
import { TlaOption } from "app/core/models/tla-option";
import { TlaSelectedOption } from "app/core/models/tla-selected-option";
import { TlaExtentOption } from "app/core/models/tla-extent-option";
import { Loading } from "app/core/models/loading";
import { CustomError } from "app/core/models/custom-error";

import { TlaService } from "app/core/services/tla.service";

@Component({
  selector: "app-user-step-summary-tla",
  templateUrl: "./user-step-summary-tla.component.html",
  styleUrls: ["./user-step-summary-tla.component.scss"]
})
export class UserStepSummaryTlaComponent implements OnInit {
  @Input() courseId: number;

  @Input() tlaFormat: TlaFormat;

  @Output() sectionLoaded: EventEmitter<any> = new EventEmitter();

  selectedTlaOptions: Array<TlaSelectedOption> = [];
  tlaExtentOption: TlaExtentOption;
  tlaExtentOptions: Array<TlaExtentOption> = [];
  tlaExtentOptionsArray: Array<TlaExtentOption> = [];
  tlaOptions: Array<TlaOption> = [];
  tlaOptionsArray: Array<TlaOption> = [];

  editable: boolean = false;
  loading: Loading;

  loadError: CustomError;

  constructor(private tlaService: TlaService) { }

  async ngOnInit() {

    this.loading = new Loading(
      true,
      "Loading teaching and learning activities..."
    );

    try {

      if (this.tlaFormat.isEnabled) {
        if (this.tlaFormat.format == "text") {

          this.selectedTlaOptions = await this.tlaService.getSelectedTlaOptionsText(
            this.courseId,
            this.tlaFormat.id
          );
        } else if (this.tlaFormat.format == "list") {

          this.tlaOptions = await this.tlaService.getAvailableTlaOptionsForCourse(
            this.courseId,
            this.tlaFormat.id
          );

          //convert to key value array
          this.tlaOptions.forEach(tlaOption => {
            this.tlaOptionsArray[tlaOption.id] = tlaOption;
          });

          this.selectedTlaOptions = await this.tlaService.getSelectedTlaOptionsForCourse(
            this.courseId,
            this.tlaFormat.id
          );

        }


        //console.log(this.selectedTlaOptions);


        if (
          this.tlaFormat.isExtentRequired &&
          this.tlaFormat.extentFormat == "list"
        ) {
          this.tlaExtentOptions = await this.tlaService.getAvailableTlaExtentOptionsByCourseId(
            this.courseId,
            this.tlaFormat.id
          );

          //convert to key value array
          this.tlaExtentOptions.forEach(extentOption => {
            this.tlaExtentOptionsArray[extentOption.id] = extentOption;
          });

          for (let tlaSelectedOption of this.selectedTlaOptions) {
            let extentOption: TlaExtentOption = null;


            if (this.tlaFormat.format == "text") {

              //this should never happen


              extentOption = await this.tlaService.getSelectedTlaExtentOptionForTextTla(
                this.courseId,
                this.tlaFormat.id,
                tlaSelectedOption.selectionId
              );
            } else if (this.tlaFormat.format == "list") {
              extentOption = await this.tlaService.getSelectedTlaExtentOptionForListTla(
                this.courseId,
                this.tlaFormat.id,
                tlaSelectedOption.selectionId
              );
            }


            tlaSelectedOption.extentOption = extentOption;
          }
        }

        else if (this.tlaFormat.isExtentRequired &&
          this.tlaFormat.extentFormat == "text") {


          for (let tlaSelectedOption of this.selectedTlaOptions) {
            let extentOption: TlaExtentOption = null;


            if (this.tlaFormat.format == "list") {
              //TODO
            }

            else if (this.tlaFormat.format == "text") {

              let selectedTlaExtentText = await this.tlaService.getSelectedTlaExtentOptionForTextTla(
                this.courseId,
                this.tlaFormat.id,
                tlaSelectedOption.selectionId
              );

              tlaSelectedOption.extentOption = selectedTlaExtentText;

            }
          }



          // console.log(this.selectedTlaOptions);

        }

      }

    }
    catch (error) {
      console.log(error);
      this.loadError = error;
    }
    finally {
      this.loading.isLoading = false;
    }



    // this.sectionLoaded.emit();
  }
}
