import { Injectable } from "@angular/core";
// import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//Models
import { Config } from "app/config";
import { QuestionSelectedOption } from "../models/question-selected-option";
import { QuestionTextAnswer } from "../models/question-text-answer";
import { Question } from "app/core/models/question";
import { QuestionAnswerOption } from "app/core/models/question-answer-option";
import { AnalysisQuestion } from "app/core/models/analysis-question";

//Services
import { UtilService } from "app/core/services/util.service";

//Libraries
import { map, catchError, finalize } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  constructor(
    private utilService: UtilService,
    private httpClient: HttpClient
  ) { }


  /**
   * Get questions for course or outcome by review Id
   * @param reviewId 
   * @param relatingTo 
   */
  getQuestionsByReviewId(reviewId: number, relatingTo: string) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        relatingTo
      )
      .pipe(
        map((questionsData: any) => {
          ////console.log(questionsData);

          let questions: Array<Question> = [];

          // if (Object.keys(questionsData).length !== 0) {
          return questionsData.map(question => {
            const newQuestion = new Question(
              question.id,
              question.format,
              question.question,
              relatingTo,
              question.orderNum,
              [],
              new QuestionTextAnswer(null, null, null, null, null)
            );
            return newQuestion;
          });
          // }
          // return questions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Get questions for course or outcome by course Id
   * @param courseId 
   * @param relatingTo 
   */
  getQuestionsByCourseId(courseId: number, relatingTo: string) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/" +
        relatingTo
      )
      .pipe(
        map((questionsData: any) => {
          //console.log(questionsData);

          let questions: Array<Question> = [];

          // if (Object.keys(questionsData).length !== 0) {
          return questionsData.map(question => {
            const newQuestion = new Question(
              question.id,
              question.format,
              question.question,
              relatingTo,
              question.orderNum,
              [],
              new QuestionTextAnswer(null, null, null, null, null)
            );
            return newQuestion;
          });
          // }
          // return questions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Get a question by Id
   * @param reviewId 
   * @param questionId 
   */
  getQuestion(reviewId: number, questionId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId
      )
      .pipe(
        map((questionsData: any) => {
          ////console.log(questionsData);

          let questions: Array<Question> = [];

          // if (Object.keys(questionsData).length !== 0) {
          return questionsData.map(question => {
            const newQuestion = new Question(
              question.id,
              question.format,
              question.question,
              question.relatingTo,
              question.orderNum,
              [],
              null
            );
            return newQuestion;
          });
          // }
          // return questions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      );
  }

  /**
   * Add a question to a curriculum project
   * @param reviewId 
   * @param newQuestion 
   */
  postQuestion(reviewId: number, newQuestion: Question) {


    const body = {
      format: newQuestion.format,
      question: newQuestion.question,
      relatingTo: newQuestion.relatingTo,
      orderNum: newQuestion.orderNum
    };

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions",
        body
      )
      .pipe(
        map((result: any) => {
          return new Question(
            result.id,
            result.format,
            result.question,
            result.relatingTo,
            result.orderNum,
            [],
            null
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Update an existing question
   * @param reviewId 
   * @param questionId 
   * @param updatedQuestion 
   */
  updateQuestion(
    reviewId: number,
    questionId: number,
    updatedQuestion: Question
  ) {

    const body = {
      format: updatedQuestion.format,
      question: updatedQuestion.question,
      orderNum: updatedQuestion.orderNum
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId,
        body
      )
      .pipe(
        map((result: any) => {
          return new Question(
            result.id,
            result.format,
            result.question,
            result.relatingTo,
            result.orderNum,
            [],
            null
          );
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Delete a question from a project
   * @param reviewId 
   * @param questionId 
   */
  deleteQuestion(reviewId: number, questionId: number) {


    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Get answer options for a question using ReviewId, if it's a multiple choice question
   * @param reviewId 
   * @param questionId 
   */
  getAnswerOptions(reviewId: number, questionId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId +
        "/answer-options"
      )
      .pipe(
        map((answerOptionsData: any) => {
          let answerOptions: Array<QuestionAnswerOption> = [];

          answerOptionsData.forEach((answerOption: any) => {
            answerOptions.push(
              new QuestionAnswerOption(
                answerOption.id,
                answerOption.description,
                answerOption.otherTextRequired
              )
            );
          });

          return answerOptions;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Get answer options for a question using CourseId, if it's a multiple choice question
   * @param courseId 
   * @param questionId 
   */
  getAnswerOptionsByCourseId(courseId: number, questionId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/" +
        questionId +
        "/answer-options"
      )
      .pipe(
        map((answerOptionsData: any) => {
          let answerOptions: Array<QuestionAnswerOption> = [];

          answerOptionsData.forEach((answerOption: any) => {
            answerOptions.push(
              new QuestionAnswerOption(
                answerOption.id,
                answerOption.description,
                answerOption.otherTextRequired
              )
            );
          });

          return answerOptions;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Post an answer option to a multiple choice question
   * @param reviewId 
   * @param questionId 
   * @param answerOptions 
   */
  postAnswerOptions(
    reviewId: number,
    questionId: number,
    answerOptions: Array<QuestionAnswerOption>
  ) {


    let answerOptionsArray = [];

    //create an array
    answerOptions.forEach(answerOption => {
      answerOptionsArray.push({
        questionId: questionId,
        description: answerOption.description,
        otherTextRequired: answerOption.otherTextRequired ? true : false
      });
    });

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId +
        "/answer-options",
        answerOptionsArray
      )
      .pipe(
        map((result: any) => {
          return;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /**
   * Delete an answer option from a multiple choice question
   * @param reviewId 
   * @param questionId 
   * @param answerOptions 
   */
  deleteAnswerOptions(
    reviewId: number,
    questionId: number,
    answerOptions: Array<QuestionAnswerOption>
  ) {


    let answerOptionIds = [];

    //create an array
    answerOptions.forEach(answerOption => {
      answerOptionIds.push({
        id: answerOption.id
      });
    });

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId +
        "/answer-options/delete",
        answerOptionIds
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }


  getCourseAnalysisQuestions(reviewId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/analysis-questions"
      )
      .pipe(
        map((analysisQuestionsData: any) => {

          let analysisQuestions: Array<AnalysisQuestion> = [];

          analysisQuestionsData.forEach((analysisQuestion: any) => {
            analysisQuestions.push(
              new AnalysisQuestion(
                analysisQuestion.id,
                analysisQuestion.question
              )
            );
          });

          return analysisQuestions;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();


  }

  addCourseAnalysisQuestions(reviewId: number, analysisQuestions: Array<AnalysisQuestion>) {


    let analysisQuestionsToAdd: any = [];

    analysisQuestions.forEach(analysisQuestion => {

      analysisQuestionsToAdd.push({
        reviewId: reviewId,
        question: analysisQuestion.question
      });


    });


    return this.httpClient.post(
      Config.BASE_API_URL_V1 +
      "/curriculum-reviews/" +
      reviewId +
      "/courses/analysis-questions",
      analysisQuestionsToAdd
    )
      .pipe(
        map((result: any) => result),
        catchError(error => throwError(this.utilService.handleApiError(error)))
      )
      .toPromise();


  }


  editCourseAnalysisQuestion(reviewId: number, analysisQuestion: AnalysisQuestion) {


    const body = {
      id: analysisQuestion.id,
      question: analysisQuestion.question
    };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/analysis-questions/" +
        analysisQuestion.id,
        body
      )
      .pipe(
        map((result: any) => {

          console.log(result)

        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();

  }


  deleteCourseAnalysisQuestion(reviewId: number, analysisQuestionId: number) {

    return this.httpClient
      .delete(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/analysis-questions/" +
        analysisQuestionId
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();


  }


  getCourseAnalysisQuestionExamples(reviewId: number) {

    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/courses/analysis-questions/examples"
      )
      .pipe(
        map((questionsData: any) => {
          // console.log(questionsData);

          return questionsData.map(question => {

            const newAnalysisQuestion = new AnalysisQuestion(
              question.id,
              question.question
            );

            return newAnalysisQuestion;
          });

        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();

  }


  ////////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  ///////////////////////// Answers for Course Outcome Questions ///////////////

  getAllTextAnswersForCourse(courseId: number) {
    // /courses/{courseId}/outcome-text-answers'
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/outcome-text-answers"
      )
      .pipe(
        map((textAnswers: any) => {
          let textAnswersArray: Array<QuestionTextAnswer> = [];

          textAnswers.forEach(textAnswer => {
            textAnswersArray.push(
              new QuestionTextAnswer(
                textAnswer.id,
                textAnswer.openText,
                textAnswer.courseId,
                textAnswer.outcomeId,
                textAnswer.questionId
              )
            );
          });

          return textAnswersArray;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************
   *
   * Error Checked
   *********************************************/
  getAllListAnswersForCourse(courseId: number) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/outcome-list-answers"
      )
      .pipe(
        map((listAnswers: any) => {
          //console.log(listAnswers);

          let listAnswersArray: Array<QuestionSelectedOption> = [];

          // //{id: 319, courseId: 159, outcomeId: 281, questionId: 78, openText: "345", …}

          listAnswers.forEach(listAnswer => {
            listAnswersArray.push(
              new QuestionSelectedOption(
                listAnswer.selectedAnswerOptionId,
                listAnswer.otherText,
                listAnswer.courseId,
                listAnswer.outcomeId,
                listAnswer.questionId
              )
            );
          });

          return listAnswersArray;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************
   *
   * Error Checked
   *********************************************/
  updateAllTextAnswersForCourse(
    courseId: number,
    textAnswersArray: Array<any>
  ) {
    // const body = {
    //   textAnswetsArray: textAnswersArray
    // };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/outcome-text-answers",
        textAnswersArray
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  updateAllListAnswersForCourse(
    courseId: number,
    listAnswersArray: Array<any>
  ) {
    // const body = {
    //   textAnswetsArray: textAnswersArray
    // };

    return this.httpClient
      .put(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/outcome-list-answers",
        listAnswersArray
      )
      .pipe(
        map((result: any) => {
          ////console.log(result);
          return;
        }),
        catchError((error: any) => {
          ////console.log(error);
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  /******************************************
   * Get existing text answer for course
   *******************************************/
  getTextAnswerForCourse(courseId: number, questionId: number) {

    return (
      this.httpClient
        // .get(
        //   Config.BASE_API_URL_V1 +
        //     "/curriculum-reviews/" +
        //     reviewId +
        //     "/questions/" +
        //     questionId +
        //     "/courses/" +
        //     courseId +
        //     "/text-answer",
        //   httpOptions
        // )
        .get(
          Config.BASE_API_URL_V1 +
          "/user/courses/" +
          courseId +
          "/questions/" +
          questionId +
          "/text-answer"
        )
        .pipe(
          map((textAnswer: any) => {
            ////console.log(textAnswer);

            if (textAnswer) {
              return new QuestionTextAnswer(
                textAnswer.id,
                textAnswer.openText,
                courseId,
                null,
                questionId
              );
            } else {
              return new QuestionTextAnswer(
                null,
                null,
                courseId,
                null,
                questionId
              );
            }
          }),
          catchError((error: any) => {
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise()
    );
  }

  /******************************************
   * Get existing text answer for outcome
   *******************************************/
  // getTextAnswerForOutcome(
  //   courseId: number,
  //   outcomeId: number,
  //   questionId: number
  // ) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json"
  //       // "x-access-token": token
  //     })
  //   };

  //   //console.log("getting text answer for outcome..");

  //   return this.httpClient
  //     .get(
  //       Config.BASE_API_URL_V1 +
  //         "/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         questionId +
  //         "/outcomes/" +
  //         outcomeId +
  //         "/text-answer",
  //       httpOptions
  //     )
  //     .pipe(
  //       map((textAnswer: any) => {
  //         ////console.log(textAnswer);

  //         if (textAnswer) {
  //           return new QuestionTextAnswer(
  //             textAnswer.id,
  //             textAnswer.openText,
  //             null,
  //             outcomeId,
  //             questionId
  //           );
  //         } else {
  //           return new QuestionTextAnswer(
  //             null,
  //             null,
  //             null,
  //             outcomeId,
  //             questionId
  //           );
  //         }
  //       }),
  //       catchError((error: any) => {
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();
  // }

  /********************************************
   * Post new text answer
   *********************************************/
  addTextAnswerForCourse(
    courseId: number,
    questionId: number,
    textAnswer: QuestionTextAnswer
  ) {
    const body = {
      openText: textAnswer.textAnswer
    };

    return (
      this.httpClient
        // .post(
        //   Config.BASE_API_URL_V1 +
        //     "/curriculum-reviews/" +
        //     reviewId +
        //     "/questions/" +
        //     questionId +
        //     "/courses/" +
        //     courseId +
        //     "/text-answer",
        //   body
        // )
        .post(
          Config.BASE_API_URL_V1 +
          "/user/courses/" +
          courseId +
          "/questions/" +
          questionId +
          "/text-answer",
          body
        )
        .pipe(
          map((result: any) => {
            ////console.log(result);
            return;
          }),
          catchError((error: any) => {
            ////console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise()
    );
  }

  /********************************************
   * Update exsisting text answer
   *********************************************/
  updateTextAnswerForCourse(
    courseId: number,
    questionId: number,
    textAnswer: QuestionTextAnswer
  ) {
    const body = {
      openText: textAnswer.textAnswer
    };

    ////console.log(textAnswer);

    return (
      this.httpClient
        // .put(
        //   Config.BASE_API_URL_V1 +
        //     "/curriculum-reviews/" +
        //     reviewId +
        //     "/questions/" +
        //     questionId +
        //     "/courses/" +
        //     courseId +
        //     "/text-answer",
        //   body
        // )
        .put(
          Config.BASE_API_URL_V1 +
          "/user/courses/" +
          courseId +
          "/questions/" +
          questionId +
          "/text-answer",
          body
        )
        .pipe(
          map((result: any) => {
            ////console.log(result);
            return;
          }),
          catchError((error: any) => {
            ////console.log(error);
            return throwError(this.utilService.handleApiError(error));
          })
        )
        .toPromise()
    );
  }

  /********************************************
   * Post new text answer
   *********************************************/
  // addTextAnswerForOutcome(
  //   reviewId: number,
  //   outcomeId: number,
  //   questionId: number,
  //   textAnswer: QuestionTextAnswer
  // ) {
  //   const body = {
  //     openText: textAnswer.textAnswer
  //   };

  //   return this.httpClient
  //     .post(
  //       Config.BASE_API_URL_V1 +
  //         "/curriculum-reviews/" +
  //         reviewId +
  //         "/questions/" +
  //         questionId +
  //         "/outcomes/" +
  //         outcomeId +
  //         "/text-answer",
  //       body
  //     )
  //     .pipe(
  //       map((result: any) => {
  //         ////console.log(result);
  //         return;
  //       }),
  //       catchError((error: any) => {
  //         ////console.log(error);
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();
  // }

  /********************************************
   * Update exsisting text answer
   *********************************************/
  // updateTextAnswerForOutcome(
  //   reviewId: number,
  //   outcomeId: number,
  //   questionId: number,
  //   textAnswer: QuestionTextAnswer
  // ) {
  //   const body = {
  //     openText: textAnswer.textAnswer
  //   };

  //   ////console.log(textAnswer);

  //   return this.httpClient
  //     .put(
  //       Config.BASE_API_URL_V1 +
  //         "/curriculum-reviews/" +
  //         reviewId +
  //         "/questions/" +
  //         questionId +
  //         "/outcomes/" +
  //         outcomeId +
  //         "/text-answer/" +
  //         textAnswer.id,
  //       body
  //     )
  //     .pipe(
  //       map((result: any) => {
  //         ////console.log(result);
  //         return;
  //       }),
  //       catchError((error: any) => {
  //         ////console.log(error);
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     )
  //     .toPromise();
  // }

  /********************************************
   * Get selected answer options for course
   *********************************************/
  getSelectedAnswerOptionsForCourse(
    reviewId: number,
    courseId: number,
    questionId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/curriculum-reviews/" +
        reviewId +
        "/questions/" +
        questionId +
        "/courses/" +
        courseId +
        "/selected-options"
      )
      .pipe(
        map((selectedOptions: any) => {
          //console.log(selectedOptions);

          let selectedAnswerOptions = [];

          selectedOptions.forEach(selectedOption => {
            selectedAnswerOptions.push(
              new QuestionSelectedOption(
                selectedOption.selectedAnswerOptionId,
                selectedOption.otherText,
                courseId,
                null,
                questionId
              )
            );
          });

          //console.log(selectedAnswerOptions);
          return selectedAnswerOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************
   * Get selected answer options for course
   *********************************************/
  getSelectedAnswerOptionsForCourseByCourseId(
    // reviewId: number,
    courseId: number,
    questionId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/" +
        questionId +
        "/selected-options"
      )
      .pipe(
        map((selectedOptions: any) => {
          //console.log(selectedOptions);

          let selectedAnswerOptions = [];

          selectedOptions.forEach(selectedOption => {
            selectedAnswerOptions.push(
              new QuestionSelectedOption(
                selectedOption.selectedAnswerOptionId,
                selectedOption.otherText,
                courseId,
                null,
                questionId
              )
            );
          });

          //console.log(selectedAnswerOptions);
          return selectedAnswerOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /********************************************
   * Get selected answer options for outcome
   *********************************************/
  getSelectedAnswerOptionsForOutcome(
    courseId: number,
    outcomeId: number,
    questionId: number
  ) {
    return this.httpClient
      .get(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/" +
        questionId +
        "/outcomes/" +
        outcomeId +
        "/selected-options"
      )
      .pipe(
        map((selectedOptions: any) => {
          let selectedAnswerOptions: Array<QuestionSelectedOption> = [];

          selectedOptions.forEach(selectedOption => {
            selectedAnswerOptions.push(
              new QuestionSelectedOption(
                selectedOption.selectedAnswerOptionId,
                selectedOption.otherText,
                null,
                outcomeId,
                questionId
              )
            );
          });

          ////console.log(reviewId, outcomeId, questionId);
          ////console.log(selectedAnswerOptions);

          return selectedAnswerOptions;
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  // deleteSelectedAnswerOptionsForCourse(
  //   reviewId: number,
  //   questionId: number,
  //   courseId: number
  // ) {
  //   return this.httpClient
  //     .delete(
  //       Config.BASE_API_URL_V1 +
  //         "/curriculum-reviews/" +
  //         reviewId +
  //         "/courses/" +
  //         courseId +
  //         "/questions/" +
  //         questionId +
  //         "/selected-options"
  //     )
  //     .pipe(
  //       map(() => {
  //         return;
  //       }),
  //       catchError(error => {
  //         return throwError(this.utilService.handleApiError(error));
  //       })
  //     );
  // }

  /********************************************
   * Update selected answer options
   *********************************************/
  postSelectedAnswerOptionsForCourse(
    courseId: number,
    questionId: number,
    answerOptions: Array<QuestionAnswerOption>
  ) {
    ////console.log(answerOptions);

    //create array
    let body = { selectedOptions: [] };

    answerOptions.forEach(answerOption => {
      if (answerOption.isSelected) {
        body.selectedOptions.push({
          selectedAnswerOptionId: answerOption.id,
          otherText: answerOption.otherText
        });
      }
    });

    ////console.log(body);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/" +
        questionId +
        "/selected-options",
        body
      )
      .pipe(
        map(() => {
          //now add updated options
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  postSelectedAnswerOptionsForOutcome(
    courseId: number,
    outcomeId: number,
    questionId: number,
    answerOptions: Array<QuestionAnswerOption>
  ) {
    ////console.log(answerOptions);

    //create array
    let body = { selectedOptions: [] };

    answerOptions.forEach(answerOption => {
      if (answerOption.isSelected) {
        body.selectedOptions.push({
          selectedAnswerOptionId: answerOption.id,
          otherText: answerOption.otherText
        });
      }
    });

    ////console.log(body);

    return this.httpClient
      .post(
        Config.BASE_API_URL_V1 +
        "/user/courses/" +
        courseId +
        "/questions/" +
        questionId +
        "/outcomes/" +
        outcomeId +
        "/selected-options",
        body
      )
      .pipe(
        map(() => {
          //now add updated options
        }),
        catchError((error: any) => {
          return throwError(this.utilService.handleApiError(error));
        })
      )
      .toPromise();
  }

  /*




  deprecated





  */
  /*******************************************************************************************************************
   * Get additional questions for course outcome (without answer)
   ******************************************************************************************************************/

  // getQuestionsByReviewId(reviewId: number, relatingTo: string) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       "x-access-token": token
  //     })
  //   };

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/curriculum-reviews/" +
  //         reviewId +
  //         "/questions/" +
  //         relatingTo,
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(questionsData => {
  //       ////console.log(questionsData);

  //       let questions: Array<Question> = [];

  //       // if (Object.keys(questionsData).length !== 0) {
  //       //   questions = questionsData.map(question => {
  //       //     const newQuestion = new Question(
  //       //       question.id,
  //       //       question.format,
  //       //       question.question
  //       //     );
  //       //     newQuestion.orderNum = question.orderNum;
  //       //     return newQuestion;
  //       //   });
  //       // }
  //       return questions;
  //     })
  //     .catch((error: any) => {
  //       return throwError(
  //         this.utilService.handleError(error, "additional questions")
  //       );
  //     });
  // }

  // /*******************************************************************************************************************
  //  * Get additional questions for course outcome (without answer)
  //  ******************************************************************************************************************/

  // getQuestions(courseId: number, relatingTo: string) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         relatingTo,
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(data => {
  //       ////console.log(data);

  //       let questions: Array<Question> = [];

  //       // if (Object.keys(data).length !== 0) {
  //       //   data.forEach(question => {
  //       //     const newQuestion = new Question(
  //       //       question.id,
  //       //       question.format,
  //       //       question.question
  //       //     );
  //       //     newQuestion.orderNum = question.orderNum;

  //       //     questions.push(newQuestion);
  //       //   });
  //       // }
  //       return questions;
  //     })
  //     .catch((error: any) => {
  //       return throwError(
  //         this.utilService.handleError(error, "additional questions")
  //       );
  //     });
  // }

  // /*******************************************************************************************************************
  //  * Get answer options for a question (checkbox and radio) without answer
  //  * @param courseId
  //  * @param questionId
  //  ******************************************************************************************************************/

  // getAnswerOptions(courseId: number, questionId: number) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         questionId +
  //         "/answer-options",
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(answerOptionsData => {
  //       let answerOptions: Array<QuestionAnswerOption> = [];

  //       answerOptionsData.forEach(answerOption => {
  //         answerOptions.push(
  //           new QuestionAnswerOption(
  //             answerOption.id,
  //             answerOption.description,
  //             answerOption.textRequired
  //           )
  //         );
  //       });

  //       return answerOptions;
  //     })
  //     .catch((error: any) => {
  //       return throwError(error.json());
  //     });
  // }

  // /********************************************************************************************************************
  //  * Get text answer for a question. If the question is for Course, courseOutcomeId is null.
  //  * @param courseId
  //  * @param courseOutcomeId
  //  * @param questionId
  //  *******************************************************************************************************************/

  // getTextAnswer(courseId: number, courseOutcomeId: number, questionId: number) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         questionId +
  //         "/outcomes/" +
  //         courseOutcomeId +
  //         "/text-answer",
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(textAnswerData => {
  //       let textAnswer: string = textAnswerData.textAnswer;
  //       return textAnswerData.textAnswer;
  //     })
  //     .catch((error: any) => {
  //       return throwError(error.json());
  //     });
  // }

  // /***************************************************************
  //  * Get selected answer option IDs for selected question
  //  ***************************************************************/

  // getSelectedAnswerOptionsOnly(
  //   courseId: number,
  //   courseOutcomeId: number,
  //   questionId: number
  // ) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         questionId +
  //         "/outcomes/" +
  //         courseOutcomeId +
  //         "/selected-answer-options",
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(
  //       data => {
  //         let selectedAnswerOptionIDs: Array<QuestionAnswerOption> = [];

  //         data.forEach(selectedAnswerOption => {
  //           selectedAnswerOptionIDs.push(
  //             new QuestionAnswerOption(
  //               selectedAnswerOption.selectedAnswerOptionId,
  //               null,
  //               selectedAnswerOption.otherText
  //             )
  //           );
  //         });

  //         return selectedAnswerOptionIDs;
  //       },
  //       error => {}
  //     );
  // }

  // /***********************************************************************
  //  * Get selected answer option IDs (numbers only) for selected question
  //  ***********************************************************************/

  // getSelectedAnswerOptionIds(
  //   courseId: number,
  //   courseOutcomeId: number,
  //   questionId: number
  // ) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   return this.http
  //     .get(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         questionId +
  //         "/outcomes/" +
  //         courseOutcomeId +
  //         "/selected-answer-options",
  //       options
  //     )
  //     .map((res: Response) => res.json())
  //     .map(data => {
  //       let selectedAnswerOptionIDs: Array<number> = [];

  //       data.forEach(selectedAnswerOption => {
  //         selectedAnswerOptionIDs.push(
  //           selectedAnswerOption.selectedAnswerOptionId
  //         );
  //       });

  //       return selectedAnswerOptionIDs;
  //     })
  //     .catch((error: any) => {
  //       return throwError(error.json());
  //     });
  // }

  // /********************************************************************************************************************
  //  * Add answer to the question. If the question is for Course, courseOutcomeId is null. If opentext exists, it will be updated.
  //  * @param courseId
  //  * @param courseOutcomeId
  //  * @param question
  //  *******************************************************************************************************************/

  // addQuestionAnswerTextAnswer(
  //   courseId: number,
  //   courseOutcomeId: number,
  //   question: Question
  // ) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   // const body = { openTextAnswer: question.openTextAnswer };
  //   const body = {};

  //   ////console.log(courseId, courseOutcomeId);

  //   return this.http
  //     .post(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         question.id +
  //         "/outcomes/" +
  //         courseOutcomeId +
  //         "/text-answer",
  //       body,
  //       options
  //     )
  //     .map(res => res.json())
  //     .map(data => {
  //       return data;
  //     })
  //     .catch((error: any) => {
  //       return throwError(error.json());
  //     });
  // }

  // /**************************************************************************************************
  //  * Add or update selected answer options.
  //  *************************************************************************************************/

  // addQuestionAnswerCheckbox(
  //   courseId: number,
  //   courseOutcomeId: number,
  //   question: Question
  // ) {
  //   const token = localStorage.getItem("token");
  //   const headers = new Headers({ "x-access-token": token });
  //   const options = new RequestOptions({ headers: headers });

  //   let selectedAnswerOptions = [];

  //   // ////console.log(question.answerOptions);

  //   // if (question && question.answerOptions) {
  //   //   question.answerOptions.forEach((answerOption: QuestionAnswerOption) => {
  //   //     if (answerOption.isSelected) {
  //   //       selectedAnswerOptions.push({
  //   //         selectedId: answerOption.id,
  //   //         otherText: answerOption.otherText
  //   //       });
  //   //     }
  //   //   });
  //   // }

  //   const body = { selectedAnswerOptions: selectedAnswerOptions };

  //   return this.http
  //     .post(
  //       Config.BASE_API_URL +
  //         "/api/user/courses/" +
  //         courseId +
  //         "/questions/" +
  //         question.id +
  //         "/outcomes/" +
  //         courseOutcomeId +
  //         "/answer-options",
  //       body,
  //       options
  //     )
  //     .map(res => res.json())
  //     .map(data => {
  //       return true;
  //     });
  // }
}
